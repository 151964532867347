import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import MainCard from "../../ui-component/cards/MainCard";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  StepButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSearchParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import AnimateButton from "../../ui-component/extended/AnimateButton";
import axios from "axios";

export default function Integration() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [cnfgBy, setCnfgBy] = React.useState("Case");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const steps = [
    {
      label: "Select Object",
      description: (
        <FormControl fullWidth sx={{ width: 250 }}>
          <InputLabel id="configBy-label">Object</InputLabel>
          <Select
            size="medium"
            labelId="configBy-label"
            id="configBy"
            value={cnfgBy}
            label="Object"
            onChange={(e) => {
              setCnfgBy(e.target.value);
            }}
          >
            <MenuItem value="Case">Case</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      label: "Select Date Range",
      description: (
        <Grid container justifyContent="space-between" sx={{ width: 500 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={5.75}>
              <DesktopDatePicker
                label="From"
                value={from}
                onChange={(newFrom) => {
                  setFrom(newFrom);
                }}
                renderInput={(params) => (
                  <TextField size="medium" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={5.75}>
              <DesktopDatePicker
                label=" To"
                value={to}
                minDate={from}
                onChange={(newTo) => {
                  setTo(newTo);
                }}
                renderInput={(params) => (
                  <TextField size="medium" {...params} />
                )}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
      ),
    },
    {
      label: "Finish",
      description: `All Steps Completed...`,
    },
  ];

  const connectionName = searchParams?.get("connectionName");
  const accessToken = searchParams?.get("accessToken");
  const Status = "Failed";

  const getData = () => {
    console.log("Called");
    const dateAdded = `SELECT Id,Subject,Status,CreatedDate FROM Case WHERE CreatedDate> ${from.toISOString()} AND CreatedDate<${to.toISOString()}`;
    axios
      .get(
        `https://chikpeaone--qb.sandbox.my.salesforce.com/services/data/v42.0/query/?${new URLSearchParams(
          { q: dateAdded }
        ).toString()}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((apiData) => {
        console.log(apiData.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MainCard>
      <Grid container direction="column">
        <Grid item>
          <Grid container justifyContent="space-between" sx={{ py: 1.5 }}>
            <Grid item>
              <Typography
                sx={{
                  fontFamily: "system-ui",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                Connection Name : {connectionName}
              </Typography>
            </Grid>

            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  {Status === "OK" ? (
                    <Typography
                      sx={{
                        fontFamily: "system-ui",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        pr: 1,
                      }}
                    >
                      Connected
                    </Typography>
                  ) : (
                    <AnimateButton sx={{ pr: 1 }}>
                      <LoadingButton
                        loading={loading}
                        onClick={() => {
                          setLoading(true);
                        }}
                        sx={{ color: "#34495E" }}
                      >
                        Reconnect
                      </LoadingButton>
                    </AnimateButton>
                  )}
                </Grid>

                <Grid item>
                  {Status === "OK" ? (
                    <Tooltip title="Establishment Successfull.." followCursor>
                      <Icon
                        icon="pajamas:status-active"
                        height="25.5"
                        width="25.5"
                        color="#138D75"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Establishment Interrupted..." followCursor>
                      <Icon
                        icon="grommet-icons:status-warning"
                        height="25.5"
                        width="25.5"
                        color="#EDBB99"
                        onClick={() => {
                          setLoading(false);
                        }}
                      />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step
                key={step.label}
                sx={{
                  "& .MuiStepIcon-root.Mui-active": {
                    color: "#673ab7",
                  },
                  "& .MuiStepIcon-root.Mui-completed": {
                    color: "#34495E",
                  },
                }}
              >
                <StepButton
                  optional={
                    index === 2 ? (
                      <Typography variant="caption">Last Step</Typography>
                    ) : null
                  }
                  onClick={handleStep(index)}
                >
                  {step.label}
                </StepButton>

                <StepContent>
                  {step.description}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (index === steps.length - 1) {
                            getData();
                            handleNext();
                          } else {
                            handleNext();
                          }
                        }}
                        sx={{
                          mt: 1,
                          mr: 1,
                          fontFamily: "system-ui",
                          color: "#34495E",
                        }}
                      >
                        {index === steps.length - 1 ? "Finish" : "Continue"}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{
                          mt: 1,
                          mr: 1,
                          fontFamily: "system-ui",
                          color: "#34495E",
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>

          {/* Finish Message */}
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 2.5 }}>
              <Typography sx={{ fontFamily: "system-ui" }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button
                onClick={handleReset}
                sx={{ fontFamily: "system-ui", color: "#34495E" }}
              >
                Reset
              </Button>
            </Paper>
          )}
        </Grid>
      </Grid>
    </MainCard>
  );
}
