import { Icon } from "@iconify/react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Rows, createData } from "../../Components/MockDatas";
import MainCard from "../../ui-component/cards/MainCard";

function countDefinedValues(jsonObject) {
  let count = 0;

  for (let key in jsonObject) {
    if (jsonObject[key]) {
      count++;
    }
  }

  return count;
}

const SalesforceQueryConnections = () => {
  const salessforceObjs = [
    "Account",
    "AccountBrand",
    "AccountContactRole",
    "Activity",
    "ApiAnomalyEventStore",
    "Asset",
    "AssetRelationship",
    "AssistantProgress",
    "AuthorizationForm",
    "AuthorizationFormConsent",
    "AuthorizationFormDataUse",
    "AuthorizationFormText",
    "BusinessBrand",
    "Campaign",
    "CampaignMember",
    "Case",
    "CaseContactRole",
    "Case_Stage__c",
    "ChatterActivity",
    "CollaborationGroup",
    "CollaborationGroupMember",
    "CommSubscription",
    "CommSubscriptionChannelType",
    "ConsumptionRate",
    "ConsumptionSchedule",
    "Contact",
    "ContactPointTypeConsent",
    "ContactRequest",
    "ContentVersion",
    "Contract",
    "ContractContactRole",
    "CredentialStuffingEventStore",
    "Customer",
    "DataUseLegalBasis",
    "DataUsePurpose",
    "DuplicateRecordItem",
    "DuplicateRecordSet",
    "EmailMessage",
    "EngagementChannelType",
    "Error_Log__c",
    "Event",
    "ExchangeUserMapping",
    "ExpressionFilter",
    "ExpressionFilterCriteria",
    "ExternalEventMapping",
    "FeedItem",
    "FlowOrchestrationInstance",
    "FlowOrchestrationWorkItem",
    "GroupRide_Message__c",
    "Group_Ride__c",
    "Idea",
    "Image",
    "Individual",
    "Knowledge__kav",
    "Lead",
    "LearningAssignment",
    "LearningAssignmentProgress",
    "LearningLinkProgress",
    "LinkedArticle",
    "Macro",
    "MacroAction",
    "MacroInstruction",
    "MacroUsage",
    "Opportunity",
    "OpportunityCompetitor",
    "OpportunityContactRole",
    "OpportunityLineItem",
    "OpportunityLineItemSchedule",
    "OrgDeleteRequest",
    "OrgMetricScanResult",
    "OrgMetricScanSummary",
    "Outgoing_Message__c",
    "PartnerRole",
    "PartyConsent",
    "Pricebook2",
    "PricebookEntry",
    "Product2",
    "ProductConsumptionSchedule",
    "PromptAction",
    "PromptError",
    "QuickText",
    "QuickTextUsage",
    "Recommendation",
    "RecordAction",
    "RecordMergeHistory",
    "Refund__c",
    "ReportAnomalyEventStore",
    "SchedulerAccountCounter__c",
    "Scorecard",
    "ScorecardAssociation",
    "ScorecardMetric",
    "Seller",
    "SessionHijackingEventStore",
    "Site",
    "Solution",
    "StreamingChannel",
    "Task",
    "Topic",
    "TopicAssignment",
    "User",
    "UserExternalCredential",
    "UserProvisioningRequest",
    "Vehicle_Rate__c",
    "Vehicle__c",
    "Vendor__c",
  ];
  const [checkStatus, setCheckStatus] = useState({});
  const maxValue = 4;
  const rows = salessforceObjs.map((val, i) => createData(i + 1, val, false));
  const handleCheck = (rowId) => {
    if (checkStatus[rowId.target.id]) {
      setCheckStatus((prev) => ({ ...prev, [rowId.target.id]: undefined }));
    } else {
      setCheckStatus((prev) => ({ ...prev, [rowId.target.id]: true }));
    }
    console.log("rowid from Rows", { rowId, checkStatus });
  };
  return (
    <MainCard>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {/* Page Heading */}
          <Typography
            sx={{
              fontSize: "1.35rem",
              fontWeight: "bold",
              color: "#34495E",
              my: 2,
              textTransform: "capitalize",
            }}
          >
            Salesforce Schema
          </Typography>
        </Grid>

        {/*  Logo */}
        <Grid item sx={{ py: 2.5 }}>
          <Icon
            icon="uil:game-structure"
            height="50"
            width="50"
            style={{ cursor: "default", color: "skyblue" }}
          />
        </Grid>
        <Grid item xs={12} align="center">
          <TableContainer
            component={Paper}
            sx={{ width: "70%", border: "1px solid #b9cee2" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.25rem",
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    Salesforce Objects
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.25rem",
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    align="right"
                  >
                    Activation Status&nbsp;
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <Rows
                    key={row.name}
                    row={row}
                    checkStatus={!!checkStatus[row.name]}
                    handleCheck={handleCheck}
                    isDisabled={
                      checkStatus[row.name]
                        ? false
                        : countDefinedValues(checkStatus) > maxValue
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default SalesforceQueryConnections;
