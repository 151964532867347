import axios from "axios";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import StepContent from "@mui/material/StepContent";
import MainCard from "../../ui-component/cards/MainCard";
import {
  Alert,
  Backdrop,
  Grid,
  Modal,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import copy from "copy-to-clipboard";
import { Checkbox } from "@mui/material";
import { useNavigate } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton, TreeItem, TreeView } from "@mui/lab";
import CircularProgress from "@mui/material/CircularProgress";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Icon } from "@iconify/react";
import { getCookie } from "../../Components/GetCookie";

const config = require("../../Api/api.json");

const ConnectionsList = config.ConnectionsList;
const ConnectionInitiate = config.ConnectionInitiate;
const JiraProjectSendToMongo = config.JiraProjectSendToMongo;
const DropboxFolderList = config.DropboxFolderList;
const DropboxFolderListContinue = config.DropboxFolderListContinue;
const DropboxSearch = config.DropboxSearch;
const DropboxSearchContinue = config.DropboxSearchContinue;
const DropboxDataSendToMongo = config.DropboxDataSendToMongo;

let schema;
let variant;
let message;
let payload;
let disabled;
let extraField;

const generateUpdatedJson = (jsonData) => {
  const folders = jsonData.filter((entry) => entry[".tag"] === "folder");
  const files = jsonData.filter((entry) => entry[".tag"] === "file");
  const updatedFolders = folders.map((folder) => {
    const children = files.filter((file) =>
      file.path_lower.startsWith(folder.path_lower)
    );
    return { ...folder, children };
  });

  // Get the IDs of entries that have been moved to children
  const movedEntryIds = updatedFolders.flatMap((folder) =>
    folder.children.map((child) => child.id)
  );

  // Remove the entries that have been moved to children from the main array
  const filteredEntries = jsonData.filter(
    (entry) => !movedEntryIds.includes(entry.id) && entry[".tag"] !== "folder"
  );

  const updatedJson = [...updatedFolders, ...filteredEntries];

  return updatedJson;
};

export default function ConnectionFrame({
  icon,
  connectionType,
  connectionTitle,
}) {
  const navigate = useNavigate();
  const accessToken = getCookie("token");
  const callbackLink = "https://apis.backend.b4b.ai/callback";

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [copied, setCopied] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [objectID, setObjectID] = useState("");
  const [connnectionOpen, setConnectionOpen] = useState(false);
  const [dbModalOpen, setDBModalOpen] = useState(false);
  const [dbTreeData, setDBTreeData] = useState({});
  const [dbSearchQuery, setDBSearchQuery] = useState("");
  const [dbSearchedData, setDBSearchedData] = useState({});
  const [dbSearchActive, setDBSearchActive] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [pageState, setPageState] = useState({
    data: [],
    total: 0,
    page: 1,
    pageSize: 5,
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleCloseSB = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const modalReset = () => {
    setCheckedItems([]);
    setDBModalOpen(false);
    setDBSearchQuery("");
    setDBSearchedData({});
    setDBSearchActive(false);
  };

  const handleCheckboxChangeForDBTreeData = (node) => {
    const isChecked = checkedItems.some((item) => item.id === node.id);

    if (isChecked) {
      // Remove the item if it's already checked
      setCheckedItems((prevItems) =>
        prevItems.filter((item) => item.id !== node.id)
      );
    } else {
      // Add the item to the checkedItems array
      setCheckedItems((prevItems) => [...prevItems, node]);
    }
  };
  const handleCheckboxChangeForDBSearchedData = (node) => {
    const isChecked = isNodeCheckedForDBSearchedData(node);

    if (isChecked) {
      // Remove the item if it's already checked
      setCheckedItems((prevItems) =>
        prevItems.filter(
          (item) => item.metadata?.metadata?.id !== node.metadata?.metadata?.id
        )
      );
    } else {
      // Add the item to the checkedItems array
      setCheckedItems((prevItems) => [...prevItems, node]);
    }
  };
  const isNodeCheckedForDBTreeData = (node) => {
    return checkedItems.some((item) => item.id === node.id);
  };
  const isNodeCheckedForDBSearchedData = (node) => {
    return checkedItems.some(
      (item) => item.metadata?.metadata?.id === node.metadata?.metadata?.id
    );
  };

  const authSchema = Yup.object().shape(schema);

  const formik = useFormik({
    initialValues: {
      connectionName: "",
      clientId: "",
      clientSecret: "",
      orgDomain: "",
    },
    validationSchema: authSchema,
    onSubmit: async (values) => {},
  });

  const { values, errors, touched, handleSubmit, getFieldProps } = formik;

  if (connectionType === "SALESFORCE") {
    schema = {
      connectionName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Connection Name  required"),
      clientId: Yup.string().required("Client Id is required"),
      clientSecret: Yup.string().required("Client Secret is required"),
      orgDomain: Yup.string().required("Org Domain is required"),
    };

    extraField = (
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Org Domain"
          {...getFieldProps("orgDomain")}
          error={Boolean(touched.orgDomain && errors.orgDomain)}
          helperText={touched.orgDomain && errors.orgDomain}
          sx={{ mb: 1.25 }}
        />
      </Grid>
    );

    payload = {
      client_id: values.clientId,
      client_secret: values.clientSecret,
      org_domain: values.orgDomain,
      connection_name: values.connectionName,
      connection_type: connectionType,
    };

    disabled =
      !values.clientId?.length > 0 ||
      !values.clientSecret?.length > 0 ||
      !values.orgDomain?.length > 0;
  }

  if (connectionType === "DROPBOX") {
    schema = {
      connectionName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Connection Name  required"),
      clientId: Yup.string().required("Client Id is required"),
      clientSecret: Yup.string().required("Client Secret is required"),
    };

    extraField = <></>;

    payload = {
      client_id: values.clientId,
      client_secret: values.clientSecret,
      org_domain: "https://api.dropboxapi.com",
      connection_name: values.connectionName,
      connection_type: "DROPBOX",
    };

    disabled = !values.clientId?.length > 0 || !values.clientSecret?.length > 0;
  }

  if (connectionType === "JIRA") {
    schema = {
      connectionName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Connection Name  required"),
      clientId: Yup.string().required("Client Id is required"),
      clientSecret: Yup.string().required("Client Secret is required"),
    };

    extraField = <></>;

    payload = {
      client_id: values.clientId,
      client_secret: values.clientSecret,
      connection_name: values.connectionName,
      connection_type: "JIRA",
    };

    disabled = !values.clientId?.length > 0 || !values.clientSecret?.length > 0;
  }

  const Steps = [
    {
      label: "Callback Link",
      description: (
        <Grid item md={4} xs={8}>
          <FormikProvider value={formik}>
            <Form autoComplete="on" onSubmit={handleSubmit}>
              <Grid container direction="column">
                {/* Connnection Name */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    placeholder="Enter Connection Name..."
                    label="Connection Name"
                    {...getFieldProps("connectionName")}
                    error={Boolean(
                      touched.connectionName && errors.connectionName
                    )}
                    helperText={touched.connectionName && errors.connectionName}
                    sx={{ mb: 1.5 }}
                  />
                </Grid>

                {/* Callback Link */}
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={11}>
                      <TextField
                        fullWidth
                        disabled
                        variant="outlined"
                        value={callbackLink}
                      />
                    </Grid>

                    <Grid item sx={{ px: 1 }} xs={0.25}>
                      {copied ? (
                        <Tooltip title="Link Copied...">
                          <Icon
                            icon="ant-design:copy-filled"
                            height="30"
                            width="30"
                            color="#2E4053"
                            onClick={() => {
                              copy(callbackLink);
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Copy To Clipboard...">
                          <Icon
                            icon="ant-design:copy-outlined"
                            height="30"
                            width="30"
                            onClick={() => {
                              copy(callbackLink);
                              setCopied(true);
                            }}
                          />
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Grid>
      ),
    },
    {
      label: "Credentials",
      description: (
        <Grid item md={4} xs={8}>
          <FormikProvider value={formik}>
            <Form autoComplete="on" onSubmit={handleSubmit}>
              <Grid container direction="column">
                {/* Client Id */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Client Id"
                    {...getFieldProps("clientId")}
                    error={Boolean(touched.clientId && errors.clientId)}
                    helperText={touched.clientId && errors.clientId}
                    sx={{ mb: 1.25 }}
                  />
                </Grid>

                {/* Client Secret */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Client Secret"
                    {...getFieldProps("clientSecret")}
                    error={Boolean(touched.clientSecret && errors.clientSecret)}
                    helperText={touched.clientSecret && errors.clientSecret}
                    sx={{ mb: 1.25 }}
                  />
                </Grid>

                {/* Org Domain */}
                {extraField}
              </Grid>
            </Form>
          </FormikProvider>
        </Grid>
      ),
    },
  ];

  const columns = [
    {
      field: "slNo",
      headerName: "Serial No",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "connectionName",
      headerName: "Connection Name",
      width: 200,
      renderCell: (e) => {
        return (
          <>
            {connectionType === "JIRA" ? (
              <Tooltip title="Go through Fetched Projects">
                <span>
                  <Button
                    onClick={() => {
                      navigate(
                        `/jira/projects?${new URLSearchParams({
                          id: e.row.objId,
                        })}`
                      );
                    }}
                    sx={{
                      color: "#34495E",
                      fontSize: ".9rem",
                      fontFamily: "system-ui",
                      justifyContent: "flex-start",
                      textTransform: "inherit",
                      display: "block",
                      width: 275,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {e.row.connectionName}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Typography>{e.row.connectionName}</Typography>
            )}
          </>
        );
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "clientId",
      headerName: "Client ID",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "orgDomain",
      headerName: "Org Domain",
      width: 225,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "expiresIn",
      headerName: "Expires In",
      width: 125,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "refreshedAt",
      headerName: "Refreshed At",
      width: 125,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "sendToDatabase",
      headerName:
        connectionType === "JIRA" ? "Jira Projects" : "Load To Server",
      width: 150,
      renderCell: (e) => {
        return (
          <Tooltip title="Load to Server">
            <span>
              <Button
                onClick={async () => {
                  if (connectionType === "SALESFORCE") {
                    navigate(
                      `/salesforce/details?${new URLSearchParams({
                        connectionName: e.row.connectionName,
                        objId: e.row.objId,
                      })}`
                    );
                  }
                  if (connectionType === "JIRA") {
                    setObjectID(e.row.objId);
                    jiraProjectSendToMongo(e.row.objId);
                  }
                  if (connectionType === "DROPBOX") {
                    setObjectID(e.row.objId);
                    await dropboxFolderData(
                      DropboxFolderList,
                      e.row.objId,
                      "",
                      false
                    );
                    setDBModalOpen(true);
                  }
                }}
              >
                <Icon
                  icon="zondicons:servers"
                  color="#45B39D"
                  width="25"
                  height="25"
                />
              </Button>
            </span>
          </Tooltip>
        );
      },
      align: "center",
      headerAlign: "center",
    },
  ];
  if (connectionType === "JIRA") {
    columns.push({
      field: "confluenceData",
      headerName: "Confluence Pages",
      width: 150,
      renderCell: (e) => {
        return (
          <Tooltip title="Confluence Spaces">
            <span>
              <Button
                onClick={() => {
                  navigate(
                    `/jira/confluence?${new URLSearchParams({
                      id: e.row.objId,
                    })}`
                  );
                }}
              >
                <Icon icon="logos:confluence" width="25" height="25" />
              </Button>
            </span>
          </Tooltip>
        );
      },
      align: "center",
      headerAlign: "center",
    });
  }

  const connectionInitiate = (API, payload) => {
    setLoading(true);

    if (connectionType === "SALESFORCE")
      if (!/^(https:)(.*)$/gim.test(values.orgDomain)) {
        setLoading(false);
        message = "Org Domain Requires https://";
        variant = "warning";
        setError(true);
        return;
      }

    axios
      .post(API, payload, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((apiData) => {
        setLoading(false);

        if (apiData?.data?.status === 200) {
          if (apiData?.data?.result?.url) {
            message = "Redirecting to New Url...";
            variant = "success";
            setError(true);
          }
          setTimeout(() => {
            window.open(apiData?.data?.result?.url, "_self", "noreferrer");
          }, 3000);
        } else {
          message = "Something went wrong!";
          error = "error";
          setError(true);
        }
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          message = "Internal Server Error!";
          variant = "error";
          setError(true);
        }
      });
  };

  const getConnectionList = () => {
    setLoading(true);

    axios
      .post(
        ConnectionsList,
        {
          connection_server: connectionType,
          pageNo: pageState.page,
          page_size: pageState.pageSize,
          total_result_count: pageState.total,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      )
      .then((apiData) => {
        if (apiData.data.status === 200) {
          setPageState((prev) => ({
            ...prev,
            data: apiData.data.result?.response?.map((e, index) => ({
              id: index,
              slNo: (pageState.page - 1) * pageState.pageSize + (index + 1),
              connectionName: e.connection_name,
              clientId: e.client_id,
              orgDomain: e.org_domain,
              state: "",
              expiresIn: e.expires_in ? e.expires_in : "...",
              refreshedAt: "...",
              objId: e._id,
            })),
            total: apiData?.data?.result?.total_result_count,
          }));
        } else if (apiData.data.status === 500) {
          message = apiData.data.result?.message;
          variant = "error";
          setError(true);
        } else {
          message = "Internal Server Error!";
          variant = "error";
          setError(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          message = "Internal Server Error!";
          variant = "error";
          setError(true);
        }
      });
  };

  const jiraProjectSendToMongo = (id) => {
    setLoading(true);
    axios
      .post(
        JiraProjectSendToMongo,
        {
          id: id,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      )
      .then((apiData) => {
        setLoading(false);

        if (apiData.data?.status === 200) {
          message = apiData.data?.result?.response;
          variant = "success";
        } else if (apiData.data?.status === 500) {
          message = apiData.data?.result?.response;
          variant = "warning";
        } else {
          message = apiData.data?.result?.message;
          variant = "error";
        }
        setError(true);
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          message = "Internal Server Error!";
          variant = "error";
          setError(true);
        }
      });
  };

  const dropboxFolderData = (API, objectId, cursor, loadMore) => {
    let payload;
    if (!loadMore) {
      payload = {
        object_id: objectId,
      };
    } else {
      payload = {
        object_id: objectId,
        cursor: cursor,
      };
    }
    return new Promise((resolve, reject) => {
      setLoading(true);
      axios
        .post(API, payload, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((apiData) => {
          setLoading(false);

          if (apiData.data?.status === 200) {
            const dropboxDataConvertedIntoTree = generateUpdatedJson(
              apiData.data.result.entries
            );

            {
              loadMore
                ? setDBTreeData({
                    treeData: [
                      ...dropboxData.treeData,
                      ...dropboxDataConvertedIntoTree,
                    ],
                    cursor: apiData.data.result.cursor,
                    hasMore: apiData.data.result.has_more,
                  })
                : setDBTreeData({
                    treeData: dropboxDataConvertedIntoTree,
                    cursor: apiData.data.result.cursor,
                    hasMore: apiData.data.result.has_more,
                  });
            }
            resolve();
          } else {
            message = "Something went wrong!";
            variant = "error";
            setError(true);
          }
        })
        .catch((error) => {
          if (error) {
            setLoading(false);

            message = "Internal Server Error!";
            variant = "error";
            setError(true);

            reject();
          }
        });
    });
  };

  const dropboxSearchData = (API, userQuery, objectId, cursor, loadMore) => {
    setLoading(true);
    setCheckedItems([]);

    let payload;
    if (!loadMore) {
      payload = {
        object_id: objectId,
        user_query: userQuery,
      };
    } else {
      payload = {
        object_id: objectId,
        cursor: cursor,
      };
    }
    axios
      .post(API, payload, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((apiData) => {
        setLoading(false);

        if (apiData.data?.status === 200) {
          setDBSearchActive(true);
          {
            loadMore
              ? setDBSearchedData({
                  matches: [
                    ...dbSearchedData.matches,
                    ...apiData.data.result.matches,
                  ],
                  cursor: apiData.data.result.cursor,
                  hasMore: apiData.data.result.has_more,
                })
              : setDBSearchedData({
                  matches: apiData.data.result.matches,
                  hasMore: apiData.data.result.has_more,
                  cursor: apiData.data.result.cursor || false,
                });
          }
        } else {
          message = "Something went wrong!";
          variant = "error";
          setError(true);
        }
      })
      .catch((error) => {
        if (error) {
          setLoading(false);

          message = "Internal Server Error!";
          variant = "error";
          setError(true);

          reject();
        }
      });
  };

  const dropboxDataSendToMongo = (payload, type, objId) => {
    setLoading(true);
    axios
      .post(
        DropboxDataSendToMongo,
        { payload_type: type, export_list: payload, object_id: objId },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      )
      .then((apiData) => {
        setLoading(false);
        setCheckedItems([]);

        if (apiData.data?.status === 200) {
          message = "Data Inserted Successfully!";
          variant = "success";

          setTimeout(() => {
            navigate("/document-upload");
          }, 2000);
        } else {
          message = "Something Went Wrong!";
          variant = "error";
        }
        setError(true);
      })
      .catch((error) => {
        if (error) {
          setLoading(false);

          message = "Internal Server Error!";
          variant = "error";
          setError(true);
        }
      });
  };

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <Typography sx={{ fontSize: "1.15rem", p: 1 }}>{nodes.name}</Typography>
      }
      endIcon={
        <Checkbox
          checked={isNodeCheckedForDBTreeData(nodes)}
          onChange={() => handleCheckboxChangeForDBTreeData(nodes)}
        />
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  useEffect(() => {
    getConnectionList();
  }, [pageState.page, pageState.pageSize]);

  // In Mobile Mode
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <MainCard>
      {/* Snackbar */}
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSB}>
        <Alert
          variant="filled"
          onClose={handleCloseSB}
          severity={variant}
          sx={{ width: "100%", zIndex: 999 }}
        >
          {message}
        </Alert>
      </Snackbar>

      {/* Loader */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <Typography sx={{ fontFamily: "system-ui", fontSize: "1rem", ml: 1 }}>
          Loading...
        </Typography>
      </Backdrop>

      {/* Top Bar   */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {/* Page Heading */}
          <Typography
            sx={{
              fontSize: "1.35rem",
              fontWeight: "bold",
              color: "#34495E",
              mb: 2.5,
              textTransform: "capitalize",
            }}
          >
            {connectionTitle} Connections
          </Typography>
        </Grid>

        {/*  Logo */}
        <Grid item sx={{ py: 2.5 }}>
          {icon}
        </Grid>
      </Grid>

      {/* New Connection Btn */}
      <Grid item>
        {connnectionOpen ? (
          <Tooltip title="Back to Connections" followCursor>
            <Icon
              icon="ion:arrow-back"
              height="27.5"
              width="27.5"
              onClick={() => {
                setConnectionOpen(false);
              }}
              color="#34495E"
            />
          </Tooltip>
        ) : (
          <Grid container alignItems="center">
            {/* <Icon
              icon="carbon:ibm-cloud-pak-integration"
              height="30"
              width="30"
              color="#34495E"
              onClick={() => {
                setConnectionOpen(true);
              }}
            /> */}
            <Button
              // sx={{
              //   fontFamily: "system-ui",
              //   fontSize: "1.05rem",
              //   color: "#34495E",
              // }}
              sx={{
                color: "white",
                textAlign: "center",
                borderzRadius: 3,
                boxShadow: 10,
                borderRadius: "15px",
                backgroundColor: "#1A5276",
                "&:hover": {
                  background: "#4FB4C4",
                },
                py: 1.5,
                px: 2.5,
              }}
              onClick={() => {
                setConnectionOpen(true);
              }}
            >
              New Connection
            </Button>
          </Grid>
        )}
      </Grid>

      {/* Authentication Steps */}
      {connnectionOpen && (
        <Stepper activeStep={activeStep} orientation="vertical">
          {Steps.map((step, index) => (
            <Step
              key={step.label}
              sx={{
                "& .MuiStepIcon-root.Mui-active": {
                  color: "#34495E",
                },
                "& .MuiStepIcon-root.Mui-completed": {
                  color: "#34495E",
                },
              }}
            >
              <StepLabel
                optional={
                  index === 2 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>

              <StepContent>
                <Grid container>{step.description}</Grid>
                <Box sx={{ p: 1.25 }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      index === Steps.length - 1
                        ? connectionInitiate(ConnectionInitiate, payload)
                        : handleNext();
                    }}
                    sx={{
                      mr: 1,
                      background: "#2E4053",
                      fontFamily: "system-ui",
                      "&:hover": {
                        background: "#0E6655",
                      },
                    }}
                    disabled={
                      step.label === "Callback Link"
                        ? !values.connectionName?.length > 0
                        : disabled
                    }
                  >
                    {index === Steps.length - 1 ? "Authenticate" : "Continue"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{
                      mr: 1,
                      fontFamily: "system-ui",
                      color: "#2E4053",
                    }}
                  >
                    Back
                  </Button>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      )}

      {/* Table */}
      {!connnectionOpen && (
        <Grid container sx={{ my: 5 }}>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              rows={pageState.data ? pageState.data : []}
              columns={columns}
              rowCount={pageState.total ? pageState.total : 5}
              pagination
              paginationMode="server"
              page={pageState.page - 1}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              pageSize={pageState.pageSize}
              onPageChange={(newPage) => {
                setPageState((old) => ({ ...old, page: newPage + 1 }));
              }}
              onPageSizeChange={(newPageSize) =>
                setPageState((old) => ({
                  ...old,
                  page: 1,
                  pageSize: newPageSize,
                }))
              }
            />
          </Grid>
        </Grid>
      )}

      {/* Dropbox Modal */}
      <Modal open={dbModalOpen} onClose={modalReset}>
        <Box
          sx={{
            position: "absolute",
            top: "0%",
            right: "0%",
            height: "98vh",
            width: isMobile ? "95%" : "45%",
            bgcolor: "background.paper",
            border: "1px solid gray",
            boxShadow: 25,
            p: 2.5,
            m: 1,
          }}
        >
          <Grid container direction="column">
            {/* Close Btn */}
            <Grid item xs={12} alignSelf="flex-end">
              <Icon
                icon="line-md:menu-to-close-alt-transition"
                height="22.5"
                width="22.5"
                onClick={modalReset}
              />
            </Grid>

            {/* Search Section */}
            <Grid item xs={12} sx={{ mb: 1.5 }}>
              <Grid container alignItems="center">
                {/* Search Textfield */}
                <Grid item xs={11}>
                  <Paper
                    component="form"
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                    onSubmit={(e) => {
                      e.preventDefault();
                      dropboxSearchData(
                        DropboxSearch,
                        dbSearchQuery,
                        objectID,
                        "",
                        false
                      );
                    }}
                  >
                    <TextField
                      id="dropboxSearch"
                      fullWidth
                      size="medium"
                      sx={{ flex: 1, borderRadius: "25px" }}
                      placeholder="Search Dropbox data..."
                      value={dbSearchQuery}
                      onChange={(event) => {
                        setDBSearchQuery(event.target.value);
                      }}
                    />
                  </Paper>
                </Grid>

                {/* Search Btn */}
                <Grid item xs={1}>
                  <LoadingButton
                    id="dropboxSearchBtn"
                    size="small"
                    sx={{ color: "#5D6D7E" }}
                    loading={loading}
                    disabled={!dbSearchQuery.length > 0}
                    onClick={() => {
                      dropboxSearchData(
                        DropboxSearch,
                        dbSearchQuery,
                        objectID,
                        "",
                        false
                      );
                    }}
                  >
                    <Icon
                      icon="carbon:search-locate-mirror"
                      height="25"
                      width="25"
                    />
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>

            {/* After Search Section */}
            <Grid
              xs={12}
              item
              sx={{
                "&::-webkit-scrollbar": {
                  width: "0.125em",
                },
                "&::-webkit-scrollbar-track": {
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#154360",
                },
              }}
            >
              {!dbSearchActive ? (
                // Not Searched View
                <>
                  {dbTreeData?.treeData &&
                    (dbTreeData.treeData.length > 0 ? (
                      // Tree View
                      <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                      >
                        {dbTreeData.treeData.map((node) => renderTree(node))}
                      </TreeView>
                    ) : (
                      // No Data Found
                      <Typography
                        color="text.secondary"
                        sx={{
                          p: 1.5,
                          fontSize: "1.25rem",
                          textAlign: "center",
                        }}
                      >
                        Sorry! No Related Data Found ...
                      </Typography>
                    ))}

                  {/* Load More */}
                  {dbTreeData.hasMore && dbTreeData.hasMore === true && (
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          id="dropboxLoadMore"
                          sx={{
                            textAlign: "center",
                            mx: "auto",
                            width: "100%",
                            color: "#1A5276",
                            fontSize: ".95rem",
                            mt: 2.5,
                            border: "1px solid #5D6D7E",
                            borderRadius: "15px",
                            px: 2,
                            py: 0.5,
                          }}
                          onClick={() => {
                            dropboxFolderData(
                              DropboxFolderListContinue,
                              objectID,
                              dropboxData.cursor,
                              true
                            );
                          }}
                        >
                          Load More ...
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                  {/* Send To Server */}
                  {checkedItems.length > 0 && (
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          id="sendToServerDBTreeData"
                          variant="contained"
                          sx={{
                            textAlign: "center",
                            borderzRadius: 3,
                            boxShadow: 20,
                            backgroundColor: "#1A5276",
                            "&:hover": {
                              background: "#4FB4C4",
                            },
                            my: 2.5,
                          }}
                          onClick={() => {
                            console.log(checkedItems);
                            dropboxDataSendToMongo(
                              checkedItems,
                              "list_folder",
                              objectID
                            );
                          }}
                        >
                          Save to Server
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                // Searched View
                <>
                  {dbSearchedData?.matches &&
                    (dbSearchedData.matches.length > 0 ? (
                      <Grid container>
                        {dbSearchedData.matches.map((para, index) => {
                          // Searched Line Data
                          return (
                            <Grid container key={index}>
                              {/* Checkbox */}
                              <Checkbox
                                checked={isNodeCheckedForDBSearchedData(para)}
                                onChange={() =>
                                  handleCheckboxChangeForDBSearchedData(para)
                                }
                              />
                              {/* Matched Item Name */}
                              <Typography sx={{ p: 1.25, fontSize: "1rem" }}>
                                {para.metadata.metadata.name}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    ) : (
                      // No Data Found!
                      <Typography
                        color="text.secondary"
                        sx={{
                          p: 1.5,
                          fontSize: "1.25rem",
                          textAlign: "center",
                        }}
                      >
                        Sorry! No Related Datas Found ...
                      </Typography>
                    ))}

                  {/* Load More */}
                  {dbSearchedData.hasMore && dbSearchedData.hasMore === true && (
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          id="dropboxSearchLoadMore"
                          sx={{
                            textAlign: "center",
                            mx: "auto",
                            width: "100%",
                            color: "#1A5276",
                            fontSize: ".95rem",
                            mt: 2.5,
                            border: "1px solid #5D6D7E",
                            borderRadius: "15px",
                            px: 2,
                            py: 0.5,
                          }}
                          onClick={() => {
                            dropboxSearchData(
                              DropboxSearchContinue,
                              "",
                              objectID,
                              dbSearchedData.cursor,
                              true
                            );
                          }}
                        >
                          Load More ...
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                  {/* Send To Server */}
                  {checkedItems.length > 0 && (
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          id="sendToServerDBTreeData"
                          variant="contained"
                          sx={{
                            textAlign: "center",
                            borderzRadius: 3,
                            boxShadow: 20,
                            backgroundColor: "#1A5276",
                            "&:hover": {
                              background: "#4FB4C4",
                            },
                            my: 2.5,
                          }}
                          onClick={() => {
                            dropboxDataSendToMongo(
                              checkedItems,
                              "search",
                              objectID
                            );
                          }}
                        >
                          Save to Server
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </MainCard>
  );
}
