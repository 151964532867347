import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainCard from "../../../../ui-component/cards/MainCard";
import Transitions from "../../../../ui-component/extended/Transitions";
// import User1 from "../../../../assets/images/users/user-round.svg";

import { IconLogout } from "@tabler/icons";
import { projectSliceActions } from "../../../../store/project-slice";
import { LightTooltip } from "../../../../Components/Tooltip";

function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires}`;
}

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => {
        return `%${"00".concat(c.charCodeAt(0).toString(16)).slice(-2)}`;
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

let res;

const ProfileSection = () => {
  const token = getCookie("token");
  if (token) {
    res = parseJwt(token);
  }

  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { storedProjName, storedProjDetails, storedAllProjDetails } =
    useSelector(({ project }) => project);

  let projIdVal = "";
  let projNameVal = "";
  let projDescVal = "";
  if (storedProjDetails.length === 0) {
    projIdVal = "";
    projNameVal = "";
    projDescVal = "";
  }
  if (Array.isArray(storedProjDetails) && storedProjDetails.length > 0) {
    projIdVal = storedProjDetails[0].id;
    projNameVal = storedProjDetails[0].name;
    projDescVal = storedProjDetails[0].description;
  }
  const newProjectId = projIdVal;
  const [newProjectName, setNewProjectName] = useState(projNameVal);
  const [newProjectDesc, setNewProjectDesc] = useState(projDescVal);
  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState("");
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const profileType = "Project Admin";

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */

  useEffect(() => {
    console.log("account popover rendered", {
      storedProjName,
      storedProjDetails,
      storedAllProjDetails,
    });
  }, []);
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    console.log("Before Logout", getCookie("token"));
    setCookie("token", "", 0);
    dispatch(projectSliceActions.storedProjNameHandler(null));
    dispatch(projectSliceActions.storedProjDetailsHandler([]));
    setTimeout(() => {
      console.log("After Logout", getCookie("token"));
      navigate("/login");
    }, 1000);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleSelectProjects = () => {
    console.log("handleSelectProjects");
    navigate("/select_project");
    dispatch(projectSliceActions.storedProjNameHandler(null));
    setOpen(false);
  };

  const handleSubmitNewDatas = () => {
    const editedProjDetails = {
      id: newProjectId,
      name: newProjectName,
      description: newProjectDesc,
      sidebar_id: [
        { p_id: "knowledge_graph", name: "Knowledge Graph" },
        { p_id: "document-upload", name: "Document Upload" },
        { p_id: "configuration", name: "Configuration" },
      ],
    };
    console.log("submit for edited datas===>", editedProjDetails);
    dispatch(projectSliceActions.storedProjDetailsHandler([editedProjDetails]));
    dispatch(projectSliceActions.storedProjNameHandler(newProjectName));
    const upDatedProject = storedAllProjDetails;

    const newAllProjects = upDatedProject.map(
      (val) =>
        [editedProjDetails].find(
          (singleProject) => singleProject.id === val.id
        ) || val
    );
    dispatch(projectSliceActions.storedAllProjDetailsHandler(newAllProjects));
    console.log("newAllProjects===>", newAllProjects);
    setEditMode(false);
  };

  const handleEnableEdit = () => {
    console.log("handle edit clicked");
    setEditMode(true);
  };

  return (
    <>
      <Chip
        sx={{
          height: "45px",
          alignItems: "center",
          borderRadius: "10px 15px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.secondary.light,
          backgroundColor: theme.palette.secondary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            "& svg": {
              // stroke: theme.palette.secondary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        label={
          <Icon
            icon="fluent:person-circle-20-regular"
            height="35"
            width="35"
            color="#34495E"
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      {editMode ? (
        <Popper
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 14],
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ p: 3 }}>
                      <LightTooltip title="back to project">
                        <Icon
                          icon="ion:arrow-back"
                          height="17.5"
                          width="17.5"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setEditMode(false);
                          }}
                          color="#34495E"
                        />
                      </LightTooltip>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography
                          component="span"
                          variant="h4"
                          sx={{ fontWeight: 400, overflowWrap: "break-word" }}
                        >
                          {res?.name}
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2" color="blue">
                        {profileType}
                      </Typography>
                      <Typography sx={{ mb: 2 }} variant="subtitle2">
                        {res?.email}
                      </Typography>
                      {storedProjName !== null && (
                        <Stack
                          direction="column"
                          spacing={2}
                          alignItems="center"
                        >
                          <TextField
                            label="Project Id"
                            placeholder="Project Id"
                            value={newProjectId}
                            disabled
                          />
                          <TextField
                            fullWidth
                            label="Project Name"
                            placeholder="Enter New Project Name"
                            value={newProjectName}
                            onChange={(e) => setNewProjectName(e.target.value)}
                          />
                          <TextField
                            fullWidth
                            multiline
                            rows="4"
                            label="Project Description"
                            placeholder="Enter New Project Description"
                            value={newProjectDesc}
                            onChange={(e) => setNewProjectDesc(e.target.value)}
                          />
                          <Button onClick={handleSubmitNewDatas}>Submit</Button>
                        </Stack>
                      )}
                    </Box>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            </Transitions>
          )}
        </Popper>
      ) : (
        <Popper
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 14],
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box sx={{ px: 2, pt: 2, pb: 0 }}>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Welcome,</Typography>
                        <Typography
                          component="span"
                          variant="h4"
                          sx={{ fontWeight: 400, overflowWrap: "break-word" }}
                        >
                          {res?.name}
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2" color="blue">
                        {profileType}
                      </Typography>
                      <Typography variant="subtitle2">{res?.email}</Typography>
                      {storedProjName !== "" && (
                        <>
                          <Grid container alignItems="center">
                            <Grid item xs={3}>
                              <Typography variant="h5">
                                Project:&nbsp;
                              </Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <Grid container alignItems="center">
                                <Grid item xs={8}>
                                  <Typography
                                    variant="subtitle2"
                                    color="primary"
                                  >
                                    {res?.name}
                                  </Typography>
                                </Grid>
                                {/* <Grid item xs={4}>
                                  <LightTooltip title="Edit Project">
                                    <Icon
                                      icon="line-md:edit-twotone"
                                      height="22"
                                      width="22"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={handleEnableEdit}
                                    />
                                  </LightTooltip>
                                </Grid> */}
                              </Grid>
                            </Grid>
                            {/* <Typography
                              variant="button"
                              color="blue"
                              sx={{
                                cursor: "pointer",
                                fontSize: "0.85rem",
                                fontWeight: "bold",
                              }}
                              onClick={handleSelectProjects}
                            >
                              Switch Project
                            </Typography> */}
                          </Grid>
                        </>
                      )}
                    </Box>

                    <Box sx={{ p: 1 }}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.25,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">Logout</Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            </Transitions>
          )}
        </Popper>
      )}
    </>
  );
};

export default ProfileSection;
