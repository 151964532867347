import { Navigate, useRoutes } from "react-router-dom";
import { TrendingUpRounded } from "@mui/icons-material";
import Mindmap from "../views/Pages/Mindmap";
import DocumentUplaod from "../views/Pages/DocumentUplaod";
import Configuration from "../views/Pages/Configuration";
import Details from "../views/Pages/Details";
import Transcript from "../views/Pages/Transcript";
import CreateChatbot from "../views/Pages/CreateChatbot";
import Salesforce from "../views/Pages/Salesforce";
import SalesforceDetails from "../views/Pages/SalesforceDetails";
import Jira from "../views/Pages/Jira";
import JiraProjects from "../views/Pages/JiraProjects";
import JiraIssues from "../views/Pages/JiraIssues";
import Dropbox from "../views/Pages/Dropbox";
import Integration from "../views/Pages/Integration";
// import Dashboard from "../views/dashboard/Default";
import Login from "../views/Pages/Login";
import Register from "../views/Pages/Register";
import Test from "../views/Pages/Test";
import MainLayout from "../layout/MainLayout";
import ConfluenceSpace from "../views/Pages/ConfluenceSpace";
import SelectProject from "../views/Pages/SelectProject";
import { useSelector } from "react-redux";
import { getCookie } from "../Components/GetCookie";
import SalesforceQueryConnections from "../views/Pages/SalesforceQueryConnections";
import AccessControl from "../views/Pages/AccessControl";

export default function ThemeRoutes() {
  const idToken = getCookie("token");
  const { storedProjName } = useSelector(({ project }) => project);
  console.log("storedProjName", storedProjName);
  return useRoutes([
    idToken
      ? {
          path: "/",
          element: <MainLayout />,
          // element: storedProjName === null ? <SelectProject /> : <MainLayout />,
          children: [
            // {
            //   path: "dashboard",
            //   element: <Dashboard />,
            // },
            {
              path: "select_project",
              element: <SelectProject />,
            },
            {
              path: "knowledge_graph",
              element: <Mindmap />,
            },
            {
              path: "document-upload",
              element: <DocumentUplaod />,
            },
            {
              path: "configuration",
              element: <Configuration />,
            },
            {
              path: "details",
              element: <Details />,
            },
            {
              path: "transcript",
              element: <Transcript />,
            },
            {
              path: "/create_chatbot",
              element: <CreateChatbot />,
            },
            {
              path: "salesforce/connection",
              element: <Salesforce />,
            },
            {
              path: "salesforce/details",
              element: <SalesforceDetails />,
            },
            {
              path: "/salesforce/salesforce_query_connections",
              element: <SalesforceQueryConnections />,
            },
            {
              path: "jira",
              element: <Jira />,
            },
            {
              path: "jira/projects",
              element: <JiraProjects />,
            },
            {
              path: "jira/issues",
              element: <JiraIssues />,
            },
            {
              path: "jira/confluence",
              element: <ConfluenceSpace />,
            },
            {
              path: "dropbox",
              element: <Dropbox />,
            },
            {
              path: "/Integration",
              element: <Integration />,
            },
            {
              path: "test",
              element: <Test />,
            },
            {
              path: "user_access",
              element: <AccessControl />,
            },

            {
              path: "/",
              element: <Navigate to="/knowledge_graph" />,

              // element:
              //   storedProjName === null ? (
              //     <Navigate to="/select_project" />
              //   ) : (
              //     <Navigate to="/knowledge_graph" />
              //   ),
            },
          ],
        }
      : {
          path: "*",
          element: <Navigate to="login" />,
        },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
  ]);
}
