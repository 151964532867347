import React from "react";
import { Icon } from "@iconify/react";
import ConnectionFrame from "./ConnectionFrame";

const Dropbox = () => {
  return (
    <ConnectionFrame
      icon={
        <Icon
          icon="logos:dropbox"
          height="40"
          width="40"
          style={{ cursor: "default" }}
        />
      }
      connectionType="DROPBOX"
      connectionTitle="Dropbox"
    />
  );
};

export default Dropbox;
