import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Alert,
  Backdrop,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import MainCard from "../../ui-component/cards/MainCard";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { getCookie } from "../../Components/GetCookie";

const config = require("../../Api/api.json");

const ConfluenceSpaceList = config.ConfluenceSpaceList;
const ConfluenceDataSendToMongo = config.ConfluenceDataSendToMongo;

let message;
let variant;

const ConfluenceSpace = () => {
  const navigate = useNavigate();
  const [urlParams, setUrlParams] = useSearchParams();
  const id = urlParams.get("id");
  const accessToken = getCookie("token");

  const [pageState, setPageState] = useState({
    data: [],
    total: 0,
    page: 1,
    pageSize: 5,
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const columns = [
    {
      field: "checkbox",
      headerName: "",
      width: 125,
      renderHeader: SelectAllHeader,
      renderCell: renderCheckboxCell,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "space_id",
      headerName: "Space ID",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "space_key",
      headerName: "Space Key",
      width: 350,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "space_name",
      headerName: "Space Name",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
  ];

  const handleCloseSB = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  // Custom header renderer for select all checkbox
  function SelectAllHeader(params) {
    return (
      <Checkbox
        color="primary"
        checked={selectAll}
        indeterminate={
          selectedRows?.length > 0 &&
          selectedRows?.length < pageState?.data?.length
        }
        onChange={handleSelectAll}
      />
    );
  }

  // Custom cell renderer for checkbox column
  function renderCheckboxCell(params) {
    return (
      <Checkbox
        color="primary"
        checked={selectedRows.includes(params.row.space_id)}
        onChange={() => handleRowSelection(params.row.space_id)}
      />
    );
  }

  // Custom getRowId implementation
  const getRowId = (row) => row.space_id;

  // Handle select all checkbox change
  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setSelectedRows(checked ? pageState.data.map((row) => row.space_id) : []);
  };

  // Handle row selection
  const handleRowSelection = (spaceId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(spaceId)) {
        return prevSelectedRows.filter((id) => id !== spaceId);
      }
      return [...prevSelectedRows, spaceId];
    });
  };

  // Handle saving selected rows
  const handleSaveSelectedRows = () => {
    const selectedData = pageState.data.filter((row) =>
      selectedRows.includes(row.space_id)
    );

    const spaceKeys = selectedData.map((para) => para.space_key);
    return spaceKeys;
  };

  const getConfluenceSpaces = () => {
    setLoading(true);
    axios
      .post(
        ConfluenceSpaceList,
        {
          obj_id: id,
          //   pageNo: pageState.page,
          //   page_size: pageState.pageSize,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      )
      .then((apiData) => {
        if (apiData.data.status === 200) {
          setPageState((prev) => ({
            ...prev,
            data: apiData.data.result?.response?.map((e, index) => ({
              // slNo: (pageState.page - 1) * pageState.pageSize + (index + 1),
              id: index,
              space_id: e.space_id,
              space_key: e.space_key,
              space_name: e.space_name,
            })),
            //   total: apiData?.data?.result?.total_result_count,
          }));
        } else {
          message = "Something went wrong!";
          variant = "error";
          setError(true);
        }

        setLoading(false);
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          setPageState({});

          message = "Internal Server Error!";
          variant = "error";
          setError(true);
        }
      });
  };

  const sendConfluenceSpacesIntoServer = () => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      axios
        .post(
          ConfluenceDataSendToMongo,
          {
            obj_id: id,
            space_key: handleSaveSelectedRows(),
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((apiData) => {
          if (apiData.data.status === 200) {
            message = "Data Inserted Successfully";
            variant = "success";
            resolve();
          } else {
            message = "Something went wrong!";
            variant = "error";
          }
          setError(true);
          setLoading(false);
        })
        .catch((error) => {
          if (error) {
            setLoading(false);

            reject();
            message = "Internal Server Error!";
            variant = "error";
            setError(true);
          }
        });
    });
  };

  useEffect(() => {
    getConfluenceSpaces();
  }, [
    id,
    // pageState.page,
    // pageState.pageSize
  ]);

  return (
    <MainCard>
      {/* Snackbar */}
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSB}>
        <Alert
          variant="filled"
          onClose={handleCloseSB}
          severity={variant}
          sx={{ width: "100%", zIndex: 999 }}
        >
          {message}
        </Alert>
      </Snackbar>

      {/* Loadeer */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <Typography sx={{ fontFamily: "system-ui", fontSize: "1rem", ml: 1 }}>
          Loading...
        </Typography>
      </Backdrop>

      {/* Page Heading */}
      <Typography
        sx={{
          fontSize: "1.35rem",
          fontWeight: "bold",
          color: "#34495E",
          mb: 2.5,
        }}
      >
        Confluence Spaces
      </Typography>

      {/* Back Btn + Breadcrumbs */}
      <Grid container alignItems="center">
        <Icon
          icon="ic:round-arrow-back"
          height="30"
          width="30"
          color="#34495E"
          onClick={() => {
            navigate("/jira");
          }}
        />
        <Breadcrumbs sx={{ px: 1 }}>
          <Button
            sx={{ fontFamily: "system-ui", color: "gray" }}
            onClick={() => {
              navigate("/jira");
            }}
          >
            Jira Connections
          </Button>
        </Breadcrumbs>
      </Grid>

      {/* Table */}
      <Grid container sx={{ my: 5 }} direction="column">
        <Grid item alignSelf="flex-end">
          {selectedRows?.length > 0 && (
            <Button
              sx={{ color: "#34495E" }}
              onClick={async () => {
                await sendConfluenceSpacesIntoServer();
                setTimeout(() => {
                  navigate("/document-upload");
                }, 2000);
              }}
            >
              Save to Server
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            rows={pageState.data}
            columns={columns}
            checkboxSelection={false}
            getRowId={getRowId}
            hideFooterPagination
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default ConfluenceSpace;
