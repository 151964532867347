import React from "react";
import { useState, useRef } from "react";
import axios from "axios";
import MainCard from "../../ui-component/cards/MainCard";
import {
  Alert,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AnimateButton from "../../ui-component/extended/AnimateButton";
import copy from "copy-to-clipboard";
import { Box } from "@mui/system";
import { Icon } from "@iconify/react";
import { useEffect } from "react";

const config = require("../../Api/api.json");

const YoutubeTranscript = config.YoutubeTranscript;

let message;
let variant;

const Transcript = () => {
  const inputRef = useRef();
  const [uplLink, setUplLink] = useState("");
  const [text, setText] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleCloseSB = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const YoutubeTranscriptApiCall = () => {
    setLoading(true);
    setCopied(false);
    axios
      .post(YoutubeTranscript, {
        link: uplLink,
      })
      .then((apiData) => {
        setText(apiData.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          message = "Internal Server Errror!";
          variant = "error";
          setError(true);
        }
      });
  };

  useEffect(() => {
    inputRef.current.focus();
  });

  return (
    <MainCard>
      {/* Snackbar */}
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSB}>
        <Alert
          variant="filled"
          id="RegisterFormMsg"
          onClose={handleCloseSB}
          severity={variant}
          sx={{ width: "100%", zIndex: 999 }}
        >
          {message}
        </Alert>
      </Snackbar>

      {/* Page Heading */}
      <Typography
        sx={{
          fontSize: "1.35rem",
          fontWeight: "bold",
          color: "#34495E",
          mb: 2.5,
        }}
      >
        Transcript Youtube Videos
      </Typography>

      {/* Upload Link */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ my: 2.5, px: 2 }}
      >
        <Grid item xs={8}>
          <FormControl fullWidth>
            <InputLabel htmlFor="upload-link">Link</InputLabel>
            <OutlinedInput
              inputRef={inputRef}
              id="upload-link"
              placeholder="https://"
              label="Amount"
              value={uplLink}
              onChange={(e) => {
                setUplLink(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key == "Enter") {
                  YoutubeTranscriptApiCall();
                }
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={1}>
          <AnimateButton>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              loading={loading}
              disabled={!uplLink.length > 0}
              sx={{
                fontFamily: "system-ui",
                fontSize: "1rem",
                color: "#34495E",
              }}
              onClick={() => {
                YoutubeTranscriptApiCall();
              }}
            >
              <Icon icon="arcticons:telegram" width="35" height="35" />
            </LoadingButton>
          </AnimateButton>
        </Grid>
      </Grid>

      {text.length > 0 && (
        <Grid container sx={{ my: 2.5, px: 2 }} justifyContent="center">
          <Card
            sx={{
              boxShadow:
                "0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              p: 2.5,
              minWidth: 475,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    maxHeight: 250,
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "0.1em",
                    },
                    "&::-webkit-scrollbar-track": {
                      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#633974",
                      outline: "1px solid slategrey",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.05rem",
                      fontFamily: "system-ui",
                      whiteSpace: "pre-line",
                      textAlign: "justify",
                    }}
                  >
                    {text}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  {copied ? (
                    <Tooltip title="Text Copied">
                      <Icon
                        icon="uil:copy-alt"
                        height="25"
                        width="25"
                        color="#34495E"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Copy To Clipboard">
                      <Icon
                        icon="uil:copy-alt"
                        height="25"
                        width="25"
                        onClick={() => {
                          copy(text);
                          setCopied(true);
                        }}
                      />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
    </MainCard>
  );
};

export default Transcript;
