import * as Yup from "yup";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Stack,
  IconButton,
  InputAdornment,
  FormHelperText,
  FormControl,
  Snackbar,
  InputLabel,
  Alert,
  OutlinedInput,
  Button,
  Grid,
  useMediaQuery,
  Divider,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import AnimateButton from "../../../ui-component/extended/AnimateButton";
import { Box } from "@mui/system";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Icon } from "@iconify/react";

const config = require("../../../Api/api.json");

const Login = config.Login;
const ForgotPassword = config.ForgotPassword;
const ResetPassword = config.ResetPassword;

let message;
let variant;
let schema;
let button;
let condition;
let goBackBtn;

function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const AuthLogin = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [otpSended, setOtpSended] = useState(false);

  // const matchDownSM = useMediaQuery(theme.breakpoints.down("lg"));
  const customization = useSelector(({ customization }) => customization);

  const LoginSchema = Yup.object().shape(schema);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      newPassword: "",
      otp: "",
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      setLoading(true);

      // Login
      if (forgot === false && otpSended === false) {
        axios
          .post(Login, {
            email: values.email,
            password: values.password,
          })
          .then((apiData) => {
            if (
              apiData.data.status === 201 &&
              apiData.data.result.RefreshToken &&
              apiData.data.result.token
            ) {
              message = "Login Successful";
              variant = "success";

              document.cookie = `token=${apiData.data.result.token};`;
              setLoading(false);
              setTimeout(() => {
                // console.log('Login :  ', getCookie('token'));
                navigate("/");
              }, 500);
            } else if (apiData.data.status === 203) {
              message = "Email or Password is Incorrect!";
              variant = "error";
              setLoading(false);
            } else if (apiData.data.status === 208) {
              message = "User Not Registered, Please Register First!";
              variant = "warning";

              setTimeout(() => {
                navigate("/register");
              }, 500);
            }
            setMsg(true);
          })
          .catch((error) => {
            if (error.response) {
              message = "Something Went Wrong!";
              variant = "error";
            }
            setMsg(true);
            setLoading(false);
          });
      }
      // Forgot Password : Otp Send To Mail
      if (forgot === true && otpSended === false) {
        setOtpSended(true);
        axios
          .post(ForgotPassword, {
            email: values.email,
          })
          .then((apiData) => {
            if (apiData.data.status === 208) {
              message = "Email Not Registered! Please Register First...";
              variant = "error";

              setTimeout(() => {
                navigate("/register");
              }, 1000);
            } else if (apiData.data.status === 209) {
              message = "OTP Successfully Sent to the Registered Email";
              variant = "success";
            }

            setMsg(true);
            setLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              message = "Something Went Wrong!";
              variant = "error";
            }
            setMsg(true);
            setLoading(false);
          });
      }
      // Reset Password : OTP & New Password
      if (forgot === true && otpSended === true) {
        axios
          .post(ResetPassword, {
            email: values.email,
            otp: values.otp,
            newPassword: values.newPassword,
          })
          .then((apiData) => {
            if (apiData.data.status === 210) {
              message = "Password Reset Successfully...";
              variant = "success";

              setTimeout(() => {
                setForgot(false);
                setOtpSended(false);
              }, 1000);
            }
            if (apiData.data.status === 208) {
              message = "OTP Invalid, Password Can not be Changed...";
              variant = "error";
            }
            if (apiData.data.status === 500) {
              message = "Something Went Wrong...";
              variant = "error";
            }
            setMsg(true);
            setLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              message = "Something Went Wrong!";
              variant = "error";
            }
            setMsg(true);
            setLoading(false);
          });
      }
    },
  });

  const { errors, touched, values, handleSubmit, handleBlur, handleChange } =
    formik;

  if (forgot === false && otpSended === false) {
    schema = {
      email: Yup.string()
        .email("Email must be a valid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    };
    button = "Login";
    condition = !values.email.length > 0 || !(values.password?.length >= 6);
    goBackBtn = "Forgot password!";
  }
  if (forgot === true && otpSended === false) {
    schema = {
      email: Yup.string()
        .email("Email must be a valid email address")
        .required("Email is required"),
    };
    button = "Send OTP For Reset";
    condition = !values.email.length > 0;
    goBackBtn = "Remember Password!";
  }
  if (forgot === true && otpSended === true) {
    schema = {
      email: Yup.string()
        .email("Email must be a valid email address")
        .required("Email is required"),
      otp: Yup.string().required("OTP is required"),
      newPassword: Yup.string().required("New Password is required"),
    };
    button = "Verify and Reset Password";
    condition =
      !values.email.length > 0 ||
      values.otp.length !== 6 ||
      !(values.password?.length >= 6);
    goBackBtn = "Remember Password!";
  }

  const handleCloseSB = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMsg(false);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {/* Top Default Section */}
      <Grid container direction="column" justifyContent="center" spacing={2}>
        {/* Sign in with Google */}
        {/* <Grid item xs={12}>
          <AnimateButton>
            <Button
              disableElevation
              fullWidth
              size="large"
              variant="outlined"
              sx={{
                color: "grey.700",
                backgroundColor: theme.palette.grey[50],
                borderColor: theme.palette.grey[100],
                fontFamily: "system-ui",
              }}
            >
              <Box sx={{ mr: { xs: 1, sm: 2, width: 20 } }}>
                {/* <img
                  src={Google}
                  alt="google"
                  width={16}
                  height={16}
                  style={{ marginRight: matchDownSM ? 8 : 16 }}
                /> 
                <Icon icon="logos:google-icon" height="27" width="27" />
              </Box>
              Sign in with Google
            </Button>
          </AnimateButton>
        </Grid> */}

        {/* OR */}
        {/* <Grid item xs={12}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              fontFamily: "system-ui",
            }}
          >
            <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />

            <Button
              variant="outlined"
              sx={{
                cursor: "unset",
                m: 2,
                py: 0.5,
                px: 7,
                borderColor: `${theme.palette.grey[100]} !important`,
                color: `${theme.palette.grey[900]}!important`,
                fontWeight: 500,
                borderRadius: `${customization.borderRadius}px`,
              }}
              disableRipple
              disabled
            >
              OR
            </Button>

            <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
          </Box>
        </Grid> */}

        {/* Sign in with Email address */}
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontFamily: "system-ui" }}>
              Sign in with Email address
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <FormikProvider value={formik}>
        <Form autoComplete="on" noValidate onSubmit={handleSubmit}>
          {/* Massage Snackbar */}
          <Snackbar open={msg} autoHideDuration={5000} onClose={handleCloseSB}>
            <Alert
              variant="filled"
              id="LoginMsg"
              onClose={handleCloseSB}
              severity={variant}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>

          <Stack spacing={3} sx={{ mb: 2.5 }}>
            {/* Email */}
            <FormControl
              fullWidth
              error={Boolean(touched.email && errors.email)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel
                htmlFor="outlined-adornment-email-register"
                sx={{ fontFamily: "system-ui" }}
              >
                Email Address
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-register"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{}}
              />
              {touched.email && errors.email && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>

            {/* OTP */}
            {otpSended && (
              <FormControl
                fullWidth
                error={Boolean(touched.otp && errors.otp)}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-otp-register"
                  sx={{ fontFamily: "system-ui" }}
                >
                  OTP
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-otp-register"
                  type="text"
                  value={values.otp}
                  name="otp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{}}
                />
                {touched.otp && errors.otp && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text--register"
                  >
                    {errors.otp}
                  </FormHelperText>
                )}
              </FormControl>
            )}

            {/* New Password */}
            {otpSended && (
              <FormControl
                fullWidth
                error={Boolean(touched.newPassword && errors.newPassword)}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-new-password-register"
                  sx={{ fontFamily: "system-ui" }}
                >
                  New Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-new-password-register"
                  type={showPassword ? "text" : "password"}
                  value={values.newPassword}
                  name="newPassword"
                  label="New Password"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{}}
                />
                {touched.newPassword && errors.newPassword && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-password-register"
                  >
                    {errors.newPassword}
                  </FormHelperText>
                )}
              </FormControl>
            )}

            {/* Actual Password  */}
            {!forgot && (
              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-password-register"
                  sx={{ fontFamily: "system-ui" }}
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password-register"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  name="password"
                  label="Password"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{}}
                />
                {touched.password && errors.password && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-password-register"
                  >
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Stack>

          {/* Forgot & Remember Password */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ my: 2 }}
          >
            <Button
              onClick={() => {
                if (forgot === false && otpSended === false) {
                  setForgot(!forgot);
                }
                if (forgot === true && otpSended === false) {
                  setForgot(!forgot);
                }
                if (forgot === true && otpSended === true) {
                  setForgot(false);
                  setOtpSended(false);
                }
              }}
            >
              {goBackBtn}
            </Button>
          </Stack>

          <Box sx={{ mt: 2 }}>
            <AnimateButton>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                color="secondary"
                variant="contained"
                loading={loading}
                disabled={condition}
                sx={{
                  fontFamily: "system-ui",
                  fontSize: "1rem",
                }}
              >
                {button}
              </LoadingButton>
            </AnimateButton>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};

export default AuthLogin;
