import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Icon } from "@iconify/react";
import MainCard from "../../ui-component/cards/MainCard";
// import { configActions } from '../../store/index';

const Configuration = () => {
  const dispatch = useDispatch();

  const [showCnfg, setShowCnfg] = useState(false);

  const [cnfgName, setCnfgName] = useState("");

  // Serch Query
  const [serQry, setSerQry] = useState([
    {
      value: "",
    },
  ]);
  const handleSerQry = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setSerQry((para) => {
      const filSerQry = para.slice();
      filSerQry[index].value = e.target.value;
      return filSerQry;
    });
  };
  const addSerQry = () => {
    setSerQry((para) => {
      return [
        ...para,
        {
          value: "",
        },
      ];
    });
  };
  const remvSerQry = (index) => {
    const filSerQry = serQry.slice();
    filSerQry.splice(index, 1);
    setSerQry(filSerQry);
  };

  // Form
  const [form, setForm] = useState([
    {
      name: "",
      type: "",
      value: [""],
    },
  ]);
  const handleName = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setForm((para) => {
      const newArr = para.slice();
      newArr[index].name = e.target.value;
      return newArr;
    });
  };
  const handleInpType = (e, index) => {
    e.preventDefault();
    setForm((para) => {
      const newArr = para.slice();
      newArr[index].type = e.target.value;
      return newArr;
    });
  };
  const handleValue = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setForm((para) => {
      const newArr = para.slice();
      newArr[index].value = e.target.value;
      return newArr;
    });
  };
  const addForm = () => {
    setForm((para) => {
      return [
        ...para,
        {
          name: "",
          type: "",
          value: [],
        },
      ];
    });
  };
  const removeForm = (index) => {
    const filForm = form.slice();
    filForm.splice(index, 1);
    setForm(filForm);
  };
  const addRadio = (index) => {
    setForm((para) => {
      const newArr = para.slice();
      newArr[index].value.push("");
      para = newArr;
      return para;
    });
  };
  const removeRadio = (i, index) => {
    const filForm = form.slice();
    filForm[index].value.splice(i, 1);
    setForm(filForm);
  };
  const handleRadio = (e, i, index) => {
    setForm((para) => {
      const newArr = para.slice();
      newArr[index].value[i] = e.target.value;
      para = newArr;
      return para;
    });
  };

  // const configData = useSelector((state) => state.config.configData);
  // console.log('Config Data===>', configData);

  return (
    <MainCard>
      {/* Page Heading */}
      <Typography
        sx={{
          fontSize: "1.35rem",
          fontWeight: "bold",
          color: "#34495E",
          mb: 2.5,
          px: 0.5,
        }}
      >
        Configuration
      </Typography>

      {/* Page Content Part */}
      <Grid container>
        {/* Add / Undo Config Btn */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{
              textAlign: "center",
              borderRadius: 10,
              boxShadow: 10,
              backgroundColor: "#1A5276",
              "&:hover": {
                background: "#4FB4C4",
              },
              px: 3.5,
              py: 1.5,
            }}
            onClick={() => {
              {
                showCnfg
                  ? (setCnfgName(""),
                    setShowCnfg(false),
                    setSerQry([
                      {
                        value: "",
                      },
                    ]),
                    setForm([
                      {
                        name: "",
                        type: "",
                        value: [""],
                      },
                    ]))
                  : setShowCnfg(true);
              }
            }}
          >
            {showCnfg ? "Undo Configuration" : "Add New Configuration"}
          </Button>
        </Grid>

        {/* Configuration Section */}
        {showCnfg && (
          <Grid container sx={{ mt: 1.5 }}>
            {/* Configuration Name */}
            <TextField
              label="Configuration Name"
              value={cnfgName}
              onChange={(e) => {
                setCnfgName(e.target.value);
              }}
            />

            {/* Expected Query Example + Config Form */}
            <Grid container justifyContent="space-between" sx={{ mt: 2.5 }}>
              {/* Expected Query Section */}
              <Grid item xs={3}>
                <Grid container>
                  {/* Add Expected Query Btn */}
                  <Grid>
                    <Tooltip title="Add More Search Query" followCursor>
                      <Button
                        onClick={addSerQry}
                        sx={{ color: "#34495E", fontSize: "1rem" }}
                        endIcon={
                          <Icon
                            icon="iconoir:add-keyframes"
                            height="25"
                            width="25"
                          />
                        }
                      >
                        Expected Search Query
                      </Button>
                    </Tooltip>
                  </Grid>

                  {/* Expected Query TextField */}
                  <Grid>
                    {serQry.map((item, index) => {
                      return (
                        <Grid container key={index} alignItems="center">
                          {/* Textfield */}
                          <Grid item xs={11}>
                            <TextField
                              label="Search Query"
                              variant="outlined"
                              id={index.toString()}
                              key={index}
                              value={item.value}
                              onChange={handleSerQry}
                              sx={{ m: 0.5 }}
                            />
                          </Grid>
                          {/* Delete Btn */}
                          <Grid item xs={1}>
                            <Tooltip title="Delete This Query" followCursor>
                              <Icon
                                icon="mdi:delete-alert-outline"
                                height="25"
                                width="25"
                                onClick={() => {
                                  remvSerQry(index);
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>

              {/* Form Field Section */}
              <Grid item xs={7.5}>
                {/* Add Form Field Btn */}
                <Grid sx={{ mb: 0.5 }}>
                  <Tooltip title="Add More Field" followCursor>
                    <Button
                      onClick={addForm}
                      sx={{ color: "#34495E", fontSize: "1rem" }}
                      endIcon={
                        <Icon
                          icon="fluent:form-28-regular"
                          height="25"
                          width="25"
                        />
                      }
                    >
                      Add Field
                    </Button>
                  </Tooltip>
                </Grid>
                {/* Form Section */}
                {form.map((item, index) => {
                  return (
                    <Grid key={index} sx={{ mb: 1.5 }}>
                      <Grid container justifyContent="space-evenly">
                        {/* Delete Btn */}
                        <Grid item xs={0.75} sx={{ mt: 1.35 }}>
                          <Tooltip title="Delete This Field" followCursor>
                            <Icon
                              icon="mdi:delete-alert-outline"
                              height="25"
                              width="25"
                              onClick={() => {
                                removeForm(index);
                              }}
                            />
                          </Tooltip>
                        </Grid>

                        {/* Field Name */}
                        <Grid item xs={3.25}>
                          <TextField
                            id={index.toString()}
                            label="Field Name"
                            variant="outlined"
                            value={item.name}
                            onChange={handleName}
                          />
                        </Grid>

                        {/* Field Type */}
                        <Grid item xs={2.5}>
                          <Box sx={{ fontFamily: "system-ui" }}>
                            <FormControl fullWidth>
                              <InputLabel id="inpType">Input Type</InputLabel>
                              <Select
                                label="Config For"
                                labelId="inpType"
                                value={item.type}
                                onChange={(e) => {
                                  handleInpType(e, index);
                                }}
                              >
                                <MenuItem value="number">Number</MenuItem>
                                <MenuItem value="string">String</MenuItem>
                                <MenuItem value="boolean">Boolean</MenuItem>
                                <MenuItem value="radio">Radio</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>

                        {/* Field Value */}
                        <Grid item xs={5}>
                          <Grid container>
                            {item.type === "radio" ? (
                              <>
                                {/* Add Option Btn */}
                                <Tooltip title="Add More Options" followCursor>
                                  <Button
                                    onClick={() => {
                                      addRadio(index);
                                    }}
                                    sx={{
                                      color: "#34495E",
                                      fontSize: "1rem",
                                      my: 0.75,
                                      fontFamily: "system-ui",
                                    }}
                                    startIcon={
                                      <Icon
                                        icon="iconoir:add-keyframes"
                                        height="25"
                                        width="25"
                                      />
                                    }
                                  >
                                    Add Options
                                  </Button>
                                </Tooltip>

                                {/* Option TextField */}
                                {item?.value?.map((para, i) => {
                                  return (
                                    <Grid
                                      container
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      key={i}
                                    >
                                      {/* Textfield */}
                                      <Grid item xs={10}>
                                        <TextField
                                          key={i}
                                          id={i.toString()}
                                          label="Option"
                                          variant="outlined"
                                          value={para}
                                          onChange={(e) => {
                                            handleRadio(e, i, index);
                                          }}
                                          sx={{ m: 0.5 }}
                                        />
                                      </Grid>
                                      {/* Delete Btn */}
                                      <Grid item xs={2}>
                                        <Tooltip
                                          title="Delete This Option"
                                          followCursor
                                        >
                                          <Icon
                                            icon="mdi:delete-alert-outline"
                                            height="25"
                                            width="25"
                                            onClick={() => {
                                              removeRadio(i, index);
                                            }}
                                          />
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </>
                            ) : (
                              // Normal Input for Other Types
                              <TextField
                                id={index.toString()}
                                label="Expected Value"
                                variant="outlined"
                                value={item.value}
                                onChange={handleValue}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}

                {/* Save Configuration Btn */}
                <Button
                  sx={{
                    color: "#34495E",
                    fontSize: "1rem",
                    fontFamily: "system-ui",
                    mt: 5,
                  }}
                  onClick={() => {
                    const Config = {
                      name: cnfgName,
                      expectedQuery: serQry,
                      formConfiguration: form,
                    };
                    // console.log(Config);
                    // dispatch(configActions.addConfiguration(Config));
                  }}
                  disabled={
                    !cnfgName.length > 0 ||
                    !serQry[0].value.length > 0 ||
                    !form[0].name.length > 0
                  }
                >
                  Save Configuration
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </MainCard>
  );
};

export default Configuration;
