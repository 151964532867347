// material-ui
import { Typography } from "@mui/material";

// project imports
import { useSelector } from "react-redux";
import menuItem from "../../../../menu-items";
import NavGroup from "./NavGroup";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { storedProjName, storedProjDetails, storedAllProjDetails } =
    useSelector(({ project }) => project);
  console.log("storedProjName", storedProjName);
  const navItems = menuItem.items.map((item, i) => {
    // const sidebarDetails = storedProjDetails.map(({ sidebar_id: sId }) => sId);
    // const particularSDetails = sidebarDetails[0];
    // const sIds = particularSDetails.map(({ p_id: pId }) => pId);
    // const itemChildren = item.children;
    // const avaialableSidebarItems = itemChildren.filter((val) =>
    //   particularSDetails.some((val2) => val2.p_id === val.id)
    // );

    // console.log("menu items in sidebar", {
    //   particularSDetails,
    //   sIds,
    //   itemChildren,
    //   avaialableSidebarItems,
    // });
    switch (item.type) {
      case "group":
        return (
          <div key={i}>
            {/* {storedAllProjDetails.length > 0 && storedProjName !== null && ( */}
            <NavGroup
              key={item.id}
              item={item}
              // avaialableSidebarItems={avaialableSidebarItems}
            />
            {/* )} */}
          </div>
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
