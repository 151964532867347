import React, { useState } from "react";
import {
  Box,
  Card,
  Grid,
  Menu,
  Button,
  Dialog,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { LightTooltip } from "../../Components/Tooltip";
import { LoadingButton } from "@mui/lab";

const projets = [
  {
    id: 1,
    name: "Anish Das",
    description: "Software Engineer",
  },
  {
    id: 2,
    name: "Subhronil Chatterjee",
    description: "Sr. Software Engineer",
  },
  {
    id: 3,
    name: "Antar Saha",
    description: "Tech Lead",
  },
  {
    id: 4,
    name: "Brishti Sarkar",
    description: "Software Developer",
  },
  {
    id: 5,
    name: "Dipayan Das",
    description: "Software Engineer",
  },
];

const getRandomColor = (id) => {
  const colors = [
    "F1948A",
    "73C6B6",
    "85C1E9",
    "F7DC6F",
    "AC59A6",
    "2C937F",
    "E59866",
    "0B6C87",
    "808B96",
    "DE3163",
    "CCCCFF",
    "FF7F50",
    "2C3E50",
  ];
  const colorIndex = id % colors.length;
  return colors[colorIndex];
};

const Test = () => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [newProjName, setNewProjName] = useState("");
  const [newProjDesc, setNewProjDesc] = useState("");
  const [editedProjets, setEditedProjets] = useState(projets);

  const addNewProfile = () => {
    const newProjectInfo = {
      id: editedProjets.length + 1,
      name: newProjName,
      description: newProjDesc,
    };
    setEditedProjets((prev) => [...prev, newProjectInfo]);
    projectDialogClose();
  };

  const projectDialogFormReset = () => {
    setNewProjName("");
    setNewProjDesc("");
  };

  const projectDialogClose = () => {
    setIsDialogVisible(false);
    projectDialogFormReset();
  };

  const NewProjectDialog = (
    <Dialog open={isDialogVisible} onClose={projectDialogClose}>
      {/* Title */}
      <DialogTitle sx={{ textAlign: "center", fontSize: "1rem", p: 3 }}>
        Add New Project Details
      </DialogTitle>

      <DialogContent
        sx={{
          width: { xs: "100%", md: "50vh", lg: "50vh" },
        }}
      >
        <Box sx={{ minWidth: 325, p: 1.5 }}>
          <Grid container>
            {/* Project Name */}
            <Grid item xs={12} sx={{ mb: 1.25 }}>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                id="newProjectName"
                label="Project Name"
                placeholder="Enter your Project Name"
                value={newProjName}
                onChange={(e) => setNewProjName(e.target.value)}
              />
            </Grid>

            {/* Project Description */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows="4"
                type="text"
                variant="outlined"
                label="Project Description"
                placeholder="Enter your Project Description"
                value={newProjDesc}
                onChange={(e) => setNewProjDesc(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      {/* Dialog Btns */}
      <DialogActions>
        {/* Close */}
        <Button onClick={projectDialogClose} color="error">
          Close
        </Button>

        {/* Save */}
        <LoadingButton
          autoFocus
          type="submit"
          // loading={loading}
          onClick={addNewProfile}
          disabled={newProjName === "" && newProjDesc === ""}
        >
          Save Project
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
  return (
    <Card>
      <Grid container>
        <Grid item xs={12} align="end">
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Icon icon="solar:home-add-broken" />}
            sx={{
              textAlign: "center",
              borderRadius: 5,
              boxShadow: 10,
              bgcolor: "rgba(26, 82, 118, 0.75)",
              mx: 2,
              mt: 1,
              py: 1,
              px: 2,
            }}
            onClick={() => {
              setIsDialogVisible(true);
            }}
          >
            New Project
          </Button>
        </Grid>
      </Grid>
      <Grid
        sx={{
          width: "100%",
          overflowX: "auto",
          whiteSpace: "nowrap",
          display: "flex",
        }}
      >
        <ProfileCard
          editedProjets={editedProjets}
          setEditedProjets={setEditedProjets}
        />
      </Grid>
      {NewProjectDialog}
    </Card>
  );
};

const ProfileCard = ({ editedProjets, setEditedProjets }) => {
  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState("");

  const handleEditSave = (pId) => {
    const updatedProjets = editedProjets.map((proj) => {
      if (proj.id === pId) {
        return {
          ...proj,
          name: editName,
        };
      }
      return proj;
    });
    setEditedProjets(updatedProjets);
    setEditId(null);
  };

  const handleDelete = (id) => {
    const updatedProjets = editedProjets.filter((proj) => proj.id !== id);
    setEditedProjets(updatedProjets);
  };

  const handleNameChange = (e) => {
    setEditName(e.target.value);
  };

  const handleNameKeyDown = (e, paraId) => {
    if (e.key === "Enter") {
      handleEditSave(paraId);
    }
  };

  const Dropdown = ({ id }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleEdit = () => {
      setEditId(id);
      const project = editedProjets.find((proj) => proj.id === id);
      setEditName(project.name);
      handleClose();
    };

    const handleConfirmDelete = () => {
      handleDelete(id);
      handleClose();
    };

    return (
      <>
        <IconButton onClick={handleClick} aria-label="more options">
          <Icon icon="ic:round-more-vert" height="20" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={(e) => e.stopPropagation()}
        >
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleConfirmDelete}>Delete</MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <>
      {editedProjets.map((para, index) => {
        const initials = para.name
          .split(" ")
          .map((word) => word[0])
          .join("")
          .toUpperCase();

        const imageUrl = `https://dummyimage.com/75x75/${getRandomColor(
          para.id
        )}/fff&text=${initials}`;
        return (
          <Card
            key={index}
            sx={{
              boxShadow: 15,
              borderRadius: 2.5,
              p: 2.5,
              m: 2.5,
              width: 200,
              "&:hover": {
                background: "#EBF5FB",
              },
              flex: "0 0 auto",
            }}
          >
            <Grid container direction="column" justifyContent="center">
              <Grid item sx={{ mb: 2 }}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    {editId === para.id ? (
                      <TextField
                        variant="standard"
                        value={editName}
                        onChange={handleNameChange}
                        onBlur={() => handleEditSave(para.id)}
                        onKeyDown={(e) => handleNameKeyDown(e, para.id)}
                        autoFocus
                      />
                    ) : (
                      <LightTooltip followCursor title={para.name}>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            maxWidth: 100,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                        >
                          {para.name}
                        </Typography>
                      </LightTooltip>
                    )}
                  </Grid>
                  {editId === null && (
                    <Grid item alignSelf="flex-end">
                      <Dropdown id={para.id} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item alignSelf="center">
                <img
                  src={imageUrl}
                  alt="Name Initials"
                  style={{ borderRadius: "50%" }}
                />
              </Grid>
              <Grid item alignSelf="center">
                <LightTooltip followCursor title={para.description}>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      maxWidth: 100,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {para.description}
                  </Typography>
                </LightTooltip>
              </Grid>
            </Grid>
          </Card>
        );
      })}
    </>
  );
};

export default Test;
