import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import { drawerSliceReducers } from "./drawer-slice";
import { projectSliceReducers } from "./project-slice";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  drawer: drawerSliceReducers,
  project: projectSliceReducers,
});

export default reducer;
