import {
  Alert,
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Konva from "konva";
import * as React from "react";
import { useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { drawerSliceActions } from "../../store/drawer-slice";
import MainCard from "../../ui-component/cards/MainCard";
import { LightTooltip } from "../../Components/Tooltip";
import { useNavigate } from "react-router";
import { useState } from "react";
import axios from "axios";
import { getCookie } from "../../Components/GetCookie";

// import { Stage, Layer, Line, Circle } from 'react-konva';

const regex = /^(\*+)(.*)$/;
let variant = "";
let message = "";
const config = require("../../Api/api.json");
const SearchCaseByProductName = config.SearchCaseByProductName;
const UMLtoTree = (plantUMLString) => {
  // Remove the PlantUML tags and split the string into lines
  const lines = plantUMLString
    .replace(/@startmindmap|@endmindmap/g, "")
    .trim()
    .split("\n");
  console.log("lines", lines);
  // Convert the lines into nodes and edges
  const nodes = [];
  const edges = [];
  const q = [];

  lines.forEach((line, index) => {
    const node = extractNode(line);
    // if(node.level>3 ) return
    nodes.push(node.text);

    while (q.length > 0 && node.level <= q[q.length - 1].level) {
      q.pop();
    }
    if (q.length > 0) {
      edges.push([q[q.length - 1].index, index]);
    }
    q.push({ index, level: node.level });
  });

  console.log("Nodes:", nodes);
  console.log("Edges:", edges);
  return { nodes, edges };
};
// Function to extract the node level and text from a line
function extractNode(line) {
  try {
    // const level = line.indexOf('*');
    // console.log(line.match(regex));
    const level = line.match(regex)[1].length;
    const text = line
      .trim()
      .substring(level + 1)
      .trim();
    return { level, text };
  } catch (e) {
    console.log(e);
    console.log(line);
  }
}

const data = `* Product
** API Messaging
** Additional Users
** Business API Messaging
** Business API Messaging EUR
** Business API Messaging NZD
** Business API Messaging SGD
** Business API Messaging USD
** Call
** Contacts
** Customisation & Integration
** DDMessagingAUD
** DDMessagingSGD
** DDMessagingUSD
** Editions
** Enterprise API Messaging EUR
** Enterprise API Messaging NZD
** Enterprise API Messaging SGD
** Enterprise API Messaging USD
** EnterpriseSupport
** EnterpriseSupportNZD
** EnterpriseSupportSGD
** EnterpriseSupportUSD
** EssentialSupport
** General Support
** Implementation
** Light_api_messaging AUD
** MMS Large
** MMS Small
** MessageSHRCM
** Messaging
** MessagingEUR
** MessagingNZD
** MessagingSGD
** MessagingUSD
** Module
** Optional Modules
** PlatinumSupport
** PlatinumSupportNZD
** PlatinumSupportSGD
** PlatinumSupportUSD
** Professional Services
** ProfessionalSupport
** ProfessionalSupportNZD
** ProfessionalSupportSGD
** ProfessionalSupportUSD
** Software
** Startup API Messaging
** Startup API Messaging EUR
** Startup API Messaging NZD
** Startup API Messaging SGD
** Startup API Messaging USD
** Sub Usage
** Support
** Training
** Trial
** Usage
** User
** Users
** Business Edition
** Wiz AI`;
const res = UMLtoTree(data);
function getWidth(_text) {
  const canvas = document.getElementById("myCanvas");
  const ctx = canvas.getContext("2d");
  const sentence = _text;

  const fontSize = 25;
  const fontFamily = "Consolas";

  // let radius = Math.min(canvas.width, canvas.height) / 2; // Initial radius
  let textWidth,
    textHeight,
    iterations = 0;
  ctx.font = `${fontSize}px ${fontFamily}`;
  const textMeasurements = ctx.measureText(sentence);
  const initialHeight =
    textMeasurements.fontBoundingBoxAscent +
    textMeasurements.fontBoundingBoxDescent +
    1.2;
  // Adjust for line spacing
  const initiaWdth = textMeasurements.width;
  if (sentence.split(" ").length <= 3) return initiaWdth;
  do {
    iterations++;
    textWidth = initiaWdth / iterations;
    textHeight = initialHeight * iterations;
    console.log(_text, textWidth);
    // textHeight = fontSize * 1.2; // Adjust for line spacing

    // if (textWidth > radius || textHeight > radius) {
    //   radius += 1; // Increment radius if the text doesn't fit
    // }
  } while (textWidth > textHeight);

  // radius -= 10; // Fine-tuning the radius

  // Render the circle
  // ctx.beginPath();
  // ctx.arc(canvas.width / 2, canvas.height / 2, radius, 0, 2 * Math.PI);
  // ctx.stroke();

  // // Render the wrapped text
  // ctx.textAlign = 'center';
  // ctx.textBaseline = 'middle';
  // ctx.fillText(sentence, canvas.width / 2, canvas.height / 2);
  return initiaWdth / (iterations - 1);

  // Use the optimalRadius value to draw the text within the circle on the canvas
  // ...
}

const createGraph = require("ngraph.graph");
const createLayout = require("ngraph.forcelayout");

const g = createGraph();
res.edges.forEach((e) => g.addLink(res.nodes[e[0]], res.nodes[e[1]]));

const factor = 60;
function getEdgeIndex(param) {
  // console.log(param);
  return param;
  // if (Number.isInteger(param)) return param;
  // else return param.index;
}

function getCordsForTextInsideCircle(circle, text) {
  const { width, height } = text.getClientRect();
  const { x, y, width: cw, height: ch } = circle.getClientRect();
  const center_x = x + cw / 2;
  const center_y = y + ch / 2;

  const halfWidth = width / 2;
  const halfHeight = height / 2;

  // # Calculate coordinates of top left corner
  const rectangleX = center_x / factor - halfWidth;
  const rectangleY = center_y / factor - halfHeight;

  console.log({
    width,
    height,
    center_x,
    center_y,
    halfWidth,
    halfHeight,
    rectangleX,
    rectangleY,
  });
  return { newX: rectangleX, newY: rectangleY };
}
const getPerimeterCoordinates = (startX, startY, distance) => {
  // const startX = 1;
  // const startY = 2;

  // Distance in meters
  // const distance = 5;

  // Slope in degrees
  const rBYsqrt2 = distance / Math.sqrt(2);

  const newX = Math.min(startX + rBYsqrt2, startX - rBYsqrt2);
  const newY = Math.min(startY + rBYsqrt2, startY - rBYsqrt2);

  return {
    newX,
    newY,
  };

  // const apb = startX+startY;

  // const slope = 45;

  // // Convert slope from degrees to radians
  // const slopeRad = slope * (Math.PI / 180);

  // // Calculate the change in x and y coordinates
  // const deltaX = distance * Math.cos(slopeRad);
  // const deltaY = distance * Math.sin(slopeRad);

  // // Calculate the new coordinates
  // const newX = startX + deltaX;
  // const newY = startY + deltaY;

  // return {
  //   newX,
  //   newY,
  // };
};

function calculateRadiusFromText(text) {
  const { width, height } = text.getClientRect();
  const korno = Math.sqrt(width * width + height * height);
  return korno / 2;
}
function lower_bound(a, low, high, element) {
  while (low < high) {
    let middle = low + Math.floor((high - low) / 2);

    if (element > a[middle]) low = middle + 1;
    else high = middle;
  }
  return low;
}

// Function to find the maximum number
// of intersections one segment has with
// all the other given segments
function maximumIntersections(arr, N) {
  // Stores the resultant maximum count
  let count = 0;

  // Stores the starting and the
  // ending points
  let L = Array.from({ length: N }, (_, i) => 0);
  let R = Array.from({ length: N }, (_, i) => 0);
  for (let i = 0; i < N; i++) {
    L[i] = arr[i][0];
    R[i] = arr[i][1];
  }

  // Sort arrays points in the
  // ascending order
  L.sort();
  R.sort();

  // Traverse the array arr[]
  for (let i = 0; i < N; i++) {
    let l = arr[i][0];
    let r = arr[i][1];

    // Find the count of segments
    // on left of ith segment
    let x = lower_bound(L, 0, N, l);

    // Find the count of segments
    // on right of ith segment
    let y = N - lower_bound(R, 0, N, r + 1);

    // Find the total segments not
    // intersecting with the current
    // segment
    let cnt = x + y;

    // Store the count of segments
    // that intersect with the
    // ith segment
    cnt = N - cnt - 1;

    // Update the value of count
    count = Math.max(count, cnt);
  }

  // Return the resultant count
  return count;
}

const indexCache = {};
const counter = { value: 0 };
const Mindmap = () => {
  // const [selectedText, setSelectedText] = useState(null);
  const { rightNodeStatus, rightNodeText } = useSelector(
    ({ drawer }) => drawer
  );
  const [caseSidebarDatas, setCaseSidebarDatas] = useState([]);
  // const [mindmapStatus,setMindmapStatus] = useState('')
  const [loading, setLoading] = useState(false);
  const stageRef = useRef(null);
  const accessToken = getCookie("token");
  const handleResize = (typeVal) => {
    let scaleValue = null;
    console.log("handleResize clicked", typeVal, rightNodeStatus);
    // get layers
    const nodesLayer = stageRef.current.findOne("#layer-1");
    const layerSize = nodesLayer.getClientRect({
      relativeTo: stageRef.current,
    });
    // calculate sizes
    const stageSize = stageRef.current.getSize();
    const scaleX = stageSize.width / layerSize.width;
    const scaleY = stageSize.height / layerSize.height;

    if (typeVal === "open") {
      scaleValue = Math.min(scaleX, scaleY) * 1.25;
      moveStage(
        stageRef.current,
        { x: 400, y: 300 },
        { x: scaleValue, y: scaleValue }
      );
      dispatch(drawerSliceActions.rightNodeStatusHandler(true));
    } else {
      scaleValue = Math.min(scaleX, scaleY) * 3;
      moveStage(
        stageRef.current,
        { x: 500, y: 200 },
        { x: scaleValue, y: scaleValue }
      );
      dispatch(drawerSliceActions.rightNodeStatusHandler(false));
      dispatch(drawerSliceActions.rightNodeTextHandler(null));
    }

    // move canvas
  };

  const handleSearchCaseByName = (value) => {
    if (value === undefined || value === null) {
      console.log("handle search===>", value);
      setOpen(true);
      variant = "warning";
      message = "Please Click on the text of Node!";
    } else {
      const bodyData = { product_name: value };
      console.log("bodyData", bodyData, { SearchCaseByProductName });
      return new Promise((resolve, reject) => {
        setLoading(true);
        axios
          .post(SearchCaseByProductName, bodyData, {
            headers: { Authorization: accessToken },
          })
          .then((response) => {
            console.log("response", response);
            const { status, result } = response.data;
            if (status === 200) {
              console.log("case datas===>", result.cases);
              setCaseSidebarDatas(result.cases);
              resolve();
              setLoading(false);
            }
            if (status === 400) {
              console.log("error part===>", status, result);
              setOpen(true);
              variant = "error";
              message = result;
              resolve();
              setLoading(false);
            }
          })

          .catch((error) => {
            console.log("error from axios", error);
            setOpen(true);
            variant = "error";
            message = "Internal Server Error";
            reject(error);
            setLoading(false);
          });
      });
    }
  };

  const moveStage = (stage, location, scale) => {
    const { x, y } = location;
    console.log("location", x, y);
    const tween = new Konva.Tween({
      duration: 0.35,
      easing: Konva.Easings.EaseInOut,
      node: stage,
      onFinish: () => {
        tween.destroy();
      },
      scaleX: (scale && scale.x) || 1,
      scaleY: (scale && scale.y) || 1,
      x,
      y,
    });

    tween.play();
    stage.batchDraw();
  };

  const CaseSidebarContents = ({ caseDatas }) => {
    console.log("case sidebar contents", caseDatas);
    return (
      <Grid container>
        <Card sx={{ my: 1 }}>
          <CardContent
            sx={{
              p: 3,
              bgcolor: "whitesmoke",
              boxShadow: 20,
              borderRadius: 3,
            }}
          >
            <Grid item xs={12} sx={{ py: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "1.05rem", lineHeight: "3px" }}
              >
                Case ID
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ py: 1 }}>
              <Typography
                sx={{
                  fontSize: "1.05rem",
                  color: "lightblue",
                  fontWeight: "bold",
                }}
              >
                {caseDatas.case_id}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ py: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "1.05rem", lineHeight: "3px" }}
              >
                Case Subject
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ py: 1 }}>
              {caseDatas.subject === null ? (
                <Grid item xs={12} sx={{ py: 1 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "1.05rem", lineHeight: "3px" }}
                  >
                    N/A
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  {caseDatas.subject && (
                    <Typography
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        color: "#34495e",
                        textOverflow: "ellipsis",
                        fontWeight: "bold",
                      }}
                    >
                      {caseDatas.subject}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sx={{ py: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "1.05rem", lineHeight: "3px" }}
              >
                Case Description
              </Typography>
            </Grid>
            {caseDatas.description === null ? (
              <Grid item xs={12} sx={{ py: 1 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "1.05rem", lineHeight: "3px" }}
                >
                  N/A
                </Typography>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sx={{
                  py: 1,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "system-ui",
                    textTransform: "inherit",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    color: "#34495e",
                    fontWeight: "bold",
                    height: "auto",
                  }}
                >
                  {caseDatas.description[0]}
                </Typography>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const handleClose = (mindmapStatus) => {
    console.log("close clicked");
    dispatch(drawerSliceActions.rightNodeStatusHandler(false));
    dispatch(drawerSliceActions.rightNodeTextHandler(null));
    handleResize(mindmapStatus);
  };
  console.log("rightNodeStatus close===>", rightNodeStatus, rightNodeText);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { storedProjName, storedProjDetails, storedAllProjDetails } =
    useSelector(({ project }) => project);
  // const handleIsReloaded = () => {
  //   console.log("is reloaded called");
  //   if (
  //     storedProjName === null ||
  //     storedProjDetails.length === 0 ||
  //     storedAllProjDetails.length === 0
  //   ) {
  //     setOpen(true);
  //     variant = "warning";
  //     message = "Please Wait...";
  //     setTimeout(() => {
  //       navigate("/select_project");
  //     }, 2000);
  //   }
  // };

  console.log("rightNodeStatus===>", { rightNodeStatus, rightNodeText });
  useEffect(() => {
    // handleIsReloaded();
    dispatch(drawerSliceActions.rightNodeStatusHandler(false));
    dispatch(drawerSliceActions.rightNodeTextHandler(null));
    function getCenter(rect) {
      const { x, y, width, height } = rect.getClientRect({
        relativeTo: stage,
      });
      // const { x, y, width, height } = rect.absolutePosition();

      // return { x, y };
      return { x: width / 2 + x, y: height / 2 + y };
    }
    const layout = createLayout(g);
    console.log(layout);
    // g.forEachNode(n=>{
    //   layout.setNodePosition(n.id, (window.innerWidth - 50)/2, (window.innerHeight - 180)/2)
    // })
    const nodes = [];
    const edges = [];

    setTimeout(() => {
      for (let i = 0; i < 1000; ++i) {
        layout.step();
        // g.forEachLink(function (link) {
        //   const id = indexCache[link.id];
        //   const pos = layout.getLinkPosition(link.id);
        //   const line = layer.findOne(`#edge-${id}`)
        //   line.points([
        //     factor * pos.from.x,
        //     factor * pos.from.y,
        //     factor * pos.to.x,
        //     factor * pos.to.y,
        //   ]);
        // });
        // console.log(layout.step())
      }
      g.forEachNode(function (node) {
        const id = indexCache[node.id];
        const pos = layout.getNodePosition(node.id);
        const grp = layer.findOne(`#group-${id}`);
        const prev = grp.getClientRect();
        grp.move({
          x: pos.x * factor,
          y: pos.y * factor,
        });
        grp.fire("dragmove");
        //     // grp.x(pos.x * factor);
        //     // grp.y(pos.y * factor);
      });
    }, 1000);

    // await (new Promise(done => setTimeout(done, 5000)))
    const stage = new Konva.Stage({
      container: "container",
      // width: window.innerWidth - 50,
      // height: window.innerHeight - 180,
      // width: 1000,
      // height: 1000,
      width: rightNodeStatus
        ? (window.innerWidth * 75) / 100
        : (window.innerWidth * 100) / 100,
      height: window.innerHeight - 180,
      draggable: true,
    });
    stageRef.current = stage;

    const layer = new Konva.Layer({
      id: `layer-1`,
    });
    stage.add(layer);
    const edgeCache = {};

    g.forEachLink(function (link) {
      // console.log(layout.getLinkPosition(link.id));
      indexCache[link.id] = indexCache[link.id] || ++counter.value;
      const id = indexCache[link.id];
      const pos = layout.getLinkPosition(link.id);

      const line = new Konva.Line({
        points: [],
        stroke: "black",
        fill: "black",
        id: "edge-" + id,
        // zIndex: 1 // Set a lower zIndex for the lines
      });

      layer.add(line);

      line.points([
        factor * pos.from.x,
        factor * pos.from.y,
        factor * pos.to.x,
        factor * pos.to.y,
      ]);
      // edgeCache[getEdgeIndex(pos.from)] =
      //   edgeCache[getEdgeIndex(pos.from)] || [];
      // edgeCache[getEdgeIndex(pos.from)].push(i);
      // edgeCache[getEdgeIndex(pos.to)] = edgeCache[getEdgeIndex(pos.to)] || [];
      // edgeCache[getEdgeIndex(pos.to)].push(i);
    });
    console.log(layer);
    g.forEachNode(function (node) {
      // console.log(node);
      const aaa = getWidth(node.id);
      // console.log(aaa, node.id);
      indexCache[node.id] = indexCache[node.id] || ++counter.value;
      const id = indexCache[node.id];
      const pos = layout.getNodePosition(node.id);
      console.log("node.id", node.id, pos, layout);
      const grp = new Konva.Group({
        id: "group-" + id,
        draggable: true,
      });
      console.log("grp", grp);
      const text = new Konva.Text({
        id: "text-" + id,
        text: node.id,
        fontSize: 25,
        fontFamily: "Calibri",
        fill: "#000",
        // padding: 20,
        verticalAlign: "top",
        align: "center",
        width: aaa,
        sceneFunc: (context, shape) => {
          context.fillStyle = "rgb(255,255,204)";
          context.fillRect(0, 0, shape.width(), shape.height());
          shape._sceneFunc(context);
        },
        // zIndex: 10 // Set a lower zIndex for the lines
      });

      const radius = calculateRadiusFromText(text); // Adjust the radius based on the text size

      const circle = new Konva.Circle({
        id: "circle-" + id,
        fill: "white",
        shadowBlur: 10,
        radius: radius + 20, //padding
      });

      const groupRect = grp.getClientRect();
      const groupX = groupRect.x + groupRect.width / 2;
      const groupY = groupRect.y + groupRect.height / 2;

      grp.add(circle);
      grp.add(text);
      grp.x(pos.x * factor);
      grp.y(pos.y * factor);

      stage.position({
        x: -groupX + stage.width() / 2,
        y: -groupY + stage.height() / 2,
      });
      stage.batchDraw();

      layer.add(grp);
      layer.draw();

      // const textCord = getPerimeterCoordinates(pos.x, pos.y, radius);
      const textCord = getCordsForTextInsideCircle(circle, text);
      text.x(textCord.newX);
      text.y(textCord.newY);
      console.log(JSON.stringify(textCord), node.id, pos.x, pos.y);

      layer.add(grp);

      grp.on("dragmove", (a) => {
        // console.log(node)
        node.links.forEach((link) => {
          // console.log(link, `#group-${indexCache[link.fromId]}`);

          const fromNode = layer.findOne(`#group-${indexCache[link.fromId]}`);
          const toNode = layer.findOne(`#group-${indexCache[link.toId]}`);
          // console.log(fromNode);

          const fromCenter = getCenter(fromNode);
          const toCenter = getCenter(toNode);
          const lineRef = layer.findOne(`#edge-${indexCache[link.id]}`);

          lineRef.points([fromCenter.x, fromCenter.y, toCenter.x, toCenter.y]);
        });
      });

      grp.on("click", (evt) => {
        console.log(evt);
        console.log("clicked", evt.target.attrs.text);
        dispatch(drawerSliceActions.rightNodeStatusHandler(true));
        dispatch(
          drawerSliceActions.rightNodeTextHandler(evt.target.attrs.text)
        );
        handleResize("open");
        handleSearchCaseByName(evt.target.attrs.text);
      });
      // Node position is pair of x,y coordinates:
      // {x: ... , y: ... }
    });

    // nodes.forEach((n, i) => {});

    // edges.forEach((e, i) => { });
    // createEdges();
    //     createNodes();

    const scaleBy = 1.01;
    stage.on("resize", (e) => {
      console.log("resize event fired", e);
    });
    stage.on("wheel", (e) => {
      // stop default scrolling
      console.log("wheel event fired", e, stage);
      e.evt.preventDefault();

      const oldScale = stage.scaleX();
      const pointer = stage.getPointerPosition();
      console.log("old scale and pointer position", oldScale, pointer);
      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };

      // how to scale? Zoom in? Or zoom out?
      let direction = e.evt.deltaY > 0 ? 1 : -1;

      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (e.evt.ctrlKey) {
        direction = -direction;
      }

      const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      stage.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stage.position(newPos);

      // window.addEventListener("resize", fitStageIntoParentContainer);
      return () => {
        dispatch(drawerSliceActions.rightNodeStatusHandler(false));
      };
    });
  }, []);
  const [open, setOpen] = useState(false);
  // console.log(nodes);
  const handleCloseAlert = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <MainCard>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleCloseAlert}>
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseAlert}
          severity={variant}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Grid container direction="row">
        <Grid
          item
          md={rightNodeStatus ? 9 : 12}
          sm={rightNodeStatus ? 9 : 12}
          xs={rightNodeStatus ? 9 : 12}
        >
          {/* <div id="stage-parent" style={{ width: "100%" }}> */}
          <Button
            variant="contained"
            sx={{
              textAlign: "center",
              borderzRadius: 3,
              boxShadow: 20,
              bgcolor: "#1A5276",
              "&:hover": {
                bgcolor: "#4FB4C4",
              },
              mx: 2,
              p: 1,
            }}
            onClick={() =>
              handleResize(rightNodeStatus === true ? "closed" : "open")
            }
          >
            {rightNodeStatus === true ? "Maximize" : "Center View"}
          </Button>
          <div id="container"></div>
          <canvas id="myCanvas"></canvas>
          {/* </div> */}
        </Grid>
        {loading ? (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
            <Typography
              sx={{
                fontFamily: "system-ui",
                fontSize: "1rem",
                ml: 1,
              }}
            >
              Loading...
            </Typography>
          </Backdrop>
        ) : (
          <Grid
            item
            md={rightNodeStatus ? 3 : 0}
            sm={rightNodeStatus ? 3 : 0}
            xs={rightNodeStatus ? 3 : 0}
          >
            {rightNodeStatus &&
              rightNodeText !== null &&
              rightNodeText !== undefined && (
                <Grid container>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        bgcolor: "#ede7f6",
                        p: 2.5,
                        height:
                          caseSidebarDatas?.length === 0
                            ? "50vh"
                            : window.innerHeight - 150,
                        width: "100%",
                        maxWidth: 345,
                        overflow: "hidden",
                        overflowY: "auto",
                        zIndex: 9999,
                      }}
                    >
                      <Grid item xs={12} align="end">
                        <LightTooltip title="close">
                          <IconButton
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleClose("closed")}
                          >
                            <CloseIcon />
                          </IconButton>
                        </LightTooltip>
                      </Grid>
                      <Typography
                        variant="h5"
                        sx={{
                          fontSize: "1.25rem",
                          pb: 2,
                          color: "#5D6D7E",
                          overflowWrap: "break-word",
                          textAlign: "center",
                        }}
                      >
                        {rightNodeText}
                      </Typography>
                      {caseSidebarDatas?.length > 0 && (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontSize: "1.05rem",
                            pb: 2,
                            color: "text.secondary",
                            overflowWrap: "break-word",
                            textAlign: "center",
                          }}
                        >
                          Total {caseSidebarDatas?.length} Result(s) Found!
                        </Typography>
                      )}

                      {caseSidebarDatas?.length > 0 ? (
                        <>
                          {caseSidebarDatas.map((caseDatas, i) => (
                            <CaseSidebarContents
                              key={i}
                              caseDatas={caseDatas}
                            />
                          ))}
                        </>
                      ) : (
                        <CardContent sx={{ px: 1 }}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "1.05rem",
                              color: "#05476C",
                              overflowWrap: "break-word",
                            }}
                          >
                            Sorry,No Data Found For the Selected Case!
                          </Typography>
                        </CardContent>
                      )}
                    </Card>
                  </Grid>
                </Grid>
              )}
          </Grid>
        )}

        {/* {showWindow && <div>hi</div>} */}
        {/* <Stage draggable width={window.innerWidth} height={window.innerHeight}>
        <Layer>

          {edges.map((e) => (
            <Line
              points={[
                e.from.x * 20,
                e.from.y * 20,
                e.to.x * 20,
                e.to.y * 20,
              ]}
              // stroke="black"
              stroke="#df4b26"
              strokeWidth={5}
            />
          ))}
          {nodes.map((n) => (
            <Circle
              draggable
              x={n.x * 20}
              y={n.y * 20}
              stroke="black"
              radius={50}
            />
          ))}
        </Layer>
      </Stage> */}
      </Grid>
    </MainCard>
  );
};

export default Mindmap;
