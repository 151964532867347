import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Alert,
  Backdrop,
  Breadcrumbs,
  Button,
  Card,
  Grid,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import MainCard from "../../ui-component/cards/MainCard";
import CircularProgress from "@mui/material/CircularProgress";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { parseISO } from "date-fns";

const config = require("../../Api/api.json");

const SalesforceBulkData = config.SalesforceBulkData;

let message;
let variant;

function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const SalesforceDetails = () => {
  const navigate = useNavigate();

  const accessToken = getCookie("token");

  const [urlParams, setUrlParams] = useSearchParams();
  const connectionName = urlParams.get("connectionName");
  const objId = urlParams.get("objId");

  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [fetchName, setFtechName] = React.useState("");
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleCloseSB = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const getBulkData = (from, to, fetchName) => {
    setLoading(true);

    axios
      .post(
        SalesforceBulkData,
        {
          operation: "query",
          object_id: objId,
          save_name: fetchName,
          from_date: from.toISOString(),
          to_date: to.toISOString(),
          type: "salesforce",
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      )
      .then((apiData) => {
        setLoading(false);
        if (apiData.data.message === "Success!") {
          message = "Data Fetch Initiated Successfully";
          variant = "success";
          setError(true);

          setTimeout(() => {
            navigate("/document-upload");
          }, 3000);
        }
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          message = "Internal Server Error!";
          variant = "error";
          setError(true);
        }
      });
  };

  return (
    <MainCard>
      {/* Snackbar */}
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSB}>
        <Alert
          variant="filled"
          onClose={handleCloseSB}
          severity={variant}
          sx={{ width: "100%", zIndex: 999 }}
        >
          {message}
        </Alert>
      </Snackbar>

      {/* Loadeer */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <Typography sx={{ fontFamily: "system-ui", fontSize: "1rem", ml: 1 }}>
          Loading...
        </Typography>
      </Backdrop>

      {/* Page Heading */}
      <Typography
        sx={{
          fontSize: "1.35rem",
          fontWeight: "bold",
          mb: 2.5,
          color: "#34495E",
        }}
      >
        Salesforce Data Fetch
      </Typography>

      {/* Back Btn + Breadcrumbs */}
      <Grid container alignItems="center" sx={{ py: 2.5 }}>
        <Tooltip title="Back to Salesforce Connections" followCursor>
          <Icon
            icon="ic:round-arrow-back"
            height="30"
            width="30"
            color="#34495E"
            onClick={() => {
              navigate("/salesforce");
            }}
          />
        </Tooltip>
        <Breadcrumbs sx={{ px: 1 }}>
          <Button
            sx={{ fontFamily: "system-ui", color: "gray" }}
            onClick={() => {
              navigate("/salesforce");
            }}
          >
            Salesforce Connections
          </Button>
          <Typography sx={{ fontFamily: "system-ui", color: "gray" }}>
            Salesforce Data Fetch
          </Typography>
        </Breadcrumbs>
      </Grid>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container direction="column">
          {/* Fetch Name */}
          <Grid item sx={{ mb: 1, width: 242.5 }}>
            <TextField
              fullWidth
              id="fetch_name"
              label="Fetch Name"
              variant="outlined"
              value={fetchName}
              onChange={(event) => {
                setFtechName(event.target.value);
              }}
            />
          </Grid>

          {/* Date Range */}
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start">
              {/* Date From */}
              <Grid item sx={{ mr: 1, mb: 0.5 }}>
                <DesktopDatePicker
                  label="From"
                  value={from}
                  onChange={(newFrom) => {
                    setFrom(newFrom);
                  }}
                  textField={(params) => (
                    <TextField size="medium" {...params} />
                  )}
                />
              </Grid>

              {/* Date To */}
              <Grid item>
                <DesktopDatePicker
                  label=" To"
                  value={to}
                  minDate={from}
                  onChange={(newTo) => {
                    setTo(newTo);
                  }}
                  textField={(params) => (
                    <TextField size="medium" {...params} />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Fetch Btn */}
          <Grid item xs={12} sx={{ py: 2 }}>
            <Button
              variant="outlined"
              sx={{
                color: "#34495E",
                fontFamily: "system-ui",
                fontSize: "1rem",
                borderColor: "#34495E",
              }}
              onClick={() => {
                getBulkData(from, to, fetchName);
              }}
              disabled={from === null || to === null || fetchName.length === 0}
            >
              Fetch Data
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </MainCard>
  );
};

export default SalesforceDetails;
