import { createSlice } from "@reduxjs/toolkit";

const drawerSlice = createSlice({
  name: "drawer",
  initialState: {
    rightNodeStatus: false,
    rightNodeText: null,
  },
  reducers: {
    rightNodeStatusHandler: (state, action) => {
      state.rightNodeStatus = action.payload;
    },
    rightNodeTextHandler: (state, action) => {
      state.rightNodeText = action.payload;
    },
  },
});

export const drawerSliceActions = drawerSlice.actions;
export const drawerSliceReducers = drawerSlice.reducer;
