import { Button, Grid, Tooltip } from "@mui/material";
import React, { Component } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Icon } from "@iconify/react";

const ImageZoom = ({ src }) => {
  return (
    <TransformWrapper initialScale={1}>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <Grid container direction="column">
          <Grid item alignSelf="flex-end">
            <Tooltip title="Zoom in" followCursor>
              <Button
                onClick={() => zoomIn()}
                sx={{ color: "#34495E", borderColor: "#34495E" }}
              >
                <Icon
                  icon="solar:rounded-magnifer-zoom-in-linear"
                  height="25"
                  width="25"
                />
              </Button>
            </Tooltip>
            <Tooltip title="Zoom Out" followCursor>
              <Button
                onClick={() => zoomOut()}
                sx={{ color: "#34495E", borderColor: "#34495E" }}
              >
                <Icon
                  icon="solar:rounded-magnifer-zoom-out-linear"
                  height="25"
                  width="25"
                />
              </Button>
            </Tooltip>
            <Tooltip title="Reset Zoom" followCursor>
              <Button
                onClick={() => resetTransform()}
                sx={{ color: "#34495E", borderColor: "#34495E" }}
              >
                <Icon icon="solar:close-square-linear" height="25" width="25" />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <TransformComponent>
              <img
                src={src}
                alt="test"
                style={{ height: "75vh", width: "90vw", objectFit: "contain" }}
              />
            </TransformComponent>
          </Grid>
        </Grid>
      )}
    </TransformWrapper>
  );
};

export default ImageZoom;
