import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import useScriptRef from "../../../hooks/useScriptRef";
import AnimateButton from "../../../ui-component/extended/AnimateButton";
import {
  strengthColor,
  strengthIndicator,
} from "../../../utils/password-strength";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";

const config = require("../../../Api/api.json");

const Register = config.Register;
const ConfirmEmail = config.ConfirmEmail;

let message;
let variant;

const AuthRegister = ({ ...others }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const scriptedRef = useScriptRef();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("lg"));
  const customization = useSelector((state) => state.customization);
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(true);

  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);

  const [strength, setStrength] = useState(0);
  const [level, setLevel] = useState();

  const handleCloseSB = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMsg(false);
  };

  const googleHandler = async () => {
    console.error("Register");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setStrength(temp);
    setLevel(strengthColor(temp));
  };

  useEffect(() => {
    changePassword("123456");
  }, []);

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <AnimateButton>
            <Button
              variant="outlined"
              fullWidth
              onClick={googleHandler}
              size="large"
              sx={{
                color: "grey.700",
                backgroundColor: theme.palette.grey[50],
                borderColor: theme.palette.grey[100],
                fontFamily: "system-ui",
              }}
            >
              <Box sx={{ mr: { xs: 1, sm: 2, width: 20 } }}>
                {/* <img src={Google} alt="google" width={16} height={16} style={{ marginRight: matchDownSM ? 8 : 16 }} /> */}
                <Icon icon="logos:google-icon" height="27" width="27" />
              </Box>
              Sign up with Google
            </Button>
          </AnimateButton>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ alignItems: "center", display: "flex" }}>
            <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
            <Button
              variant="outlined"
              sx={{
                cursor: "unset",
                m: 2,
                py: 0.5,
                px: 7,
                borderColor: `${theme.palette.grey[100]} !important`,
                color: `${theme.palette.grey[900]}!important`,
                fontWeight: 500,
                borderRadius: `${customization.borderRadius}px`,
                fontFamily: "system-ui",
              }}
              disableRipple
              disabled
            >
              OR
            </Button>
            <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontFamily: "system-ui" }}>
              Sign up with Email address
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Formik
        initialValues={{
          fname: "",
          lname: "",
          email: "",
          password: "",
          otp: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape(
          !verifyOtp
            ? {
                fname: Yup.string()
                  .min(2, "Too Short!")
                  .max(50, "Too Long!")
                  .required("First name required"),
                lname: Yup.string()
                  .min(2, "Too Short!")
                  .max(50, "Too Long!")
                  .required("Last name required"),
                email: Yup.string()
                  .email("Email must be a valid email address")
                  .required("Email is required"),
                password: Yup.string().required("Password is required"),
              }
            : {
                email: Yup.string()
                  .email("Email must be a valid email address")
                  .required("Email is required"),
                otp: Yup.string().required("OTP is required"),
              }
        )}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          // try {
          //     if (scriptedRef.current) {
          //         setStatus({ success: true });
          //         setSubmitting(false);
          //     }
          // } catch (err) {
          //     console.error(err);
          //     if (scriptedRef.current) {
          //         setStatus({ success: false });
          //         setErrors({ submit: err.message });
          //         setSubmitting(false);
          //     }
          // }
          return (
            setLoading(true),
            !verifyOtp
              ? axios
                  .post(Register, {
                    fullName: `${values.fname} ${values.lname}`,
                    email: values.email,
                    password: values.password,
                  })
                  .then((apiData) => {
                    if (apiData.data.status === 202) {
                      message = "OTP Successfully Sended To The Email";
                      variant = "success";
                      setVerifyOtp(true);
                    } else if (apiData.data.status === 207) {
                      message = "Email Aready Registered!";
                      variant = "warning";
                    } else if (apiData.data.status === 205) {
                      message =
                        "Password Must Contain Upper & Lower Case, Special Character,Number !";
                      variant = "warning";
                    }
                    setMsg(true);
                    setLoading(false);
                  })
                  .catch((error) => {
                    if (error.response) {
                      message = "Something Went Wrong!";
                      variant = "error";
                    }
                    setLoading(false);
                    setMsg(true);
                  })
              : axios
                  .post(ConfirmEmail, {
                    email: values.email,
                    otp: values.otp,
                  })
                  .then(async (apiData) => {
                    if (apiData.data.status === 206) {
                      message = "User Created Successfully!";
                      variant = "success";
                      setTimeout(() => {
                        navigate("/login");
                      }, 1000);
                    } else if (apiData.data.status === 208) {
                      message = "Invalid OTP!";
                      variant = "error";
                    } else if (apiData.data.status === 500) {
                      message = "Something Went Wrong!";
                      variant = "error";
                    }
                    setMsg(true);
                    setLoading(false);
                  })
                  .catch((error) => {
                    if (error.response) {
                      message = "Something Went Wrong!";
                      variant = "error";
                    }
                    setLoading(false);
                    setMsg(true);
                  })
          );
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            {/* Snackbar */}
            <Snackbar
              open={msg}
              autoHideDuration={5000}
              onClose={handleCloseSB}
            >
              <Alert
                variant="filled"
                id="RegisterFormMsg"
                onClose={handleCloseSB}
                severity={variant}
                sx={{ width: "100%", zIndex: 999 }}
              >
                {message}
              </Alert>
            </Snackbar>

            {/* User Name */}
            {!verifyOtp && (
              <Grid container spacing={matchDownSM ? 0 : 2}>
                {/* First Name  */}
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.fname && errors.fname)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-fname-register"
                      sx={{ fontFamily: "system-ui" }}
                    >
                      First Name
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-fname-register"
                      type="text"
                      value={values.fname}
                      name="fname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{}}
                    />
                    {touched.fname && errors.fname && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text--register"
                      >
                        {errors.fname}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Last Name */}
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.lname && errors.lname)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-lname-register"
                      sx={{ fontFamily: "system-ui" }}
                    >
                      Last Name
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-lname-register"
                      type="text"
                      value={values.lname}
                      name="lname"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{}}
                    />
                    {touched.lname && errors.lname && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text--register"
                      >
                        {errors.lname}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}

            {/* EMail */}
            <FormControl
              fullWidth
              error={Boolean(touched.email && errors.email)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel
                htmlFor="outlined-adornment-email-register"
                sx={{ fontFamily: "system-ui" }}
              >
                Email Address / Username
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-register"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{}}
              />
              {touched.email && errors.email && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>

            {/* Password */}
            {!verifyOtp ? (
              // Password & Strength
              <>
                <FormControl
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password-register"
                    sx={{ fontFamily: "system-ui" }}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password-register"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    name="password"
                    label="Password"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      changePassword(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{}}
                  />
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-password-register"
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>

                {/* Password Strength Checker */}
                {strength !== 0 && (
                  <FormControl fullWidth>
                    <Box sx={{ mb: 2 }}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Box
                            style={{ backgroundColor: level?.color }}
                            sx={{ width: 85, height: 8, borderRadius: "7px" }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            fontSize="0.95rem"
                            sx={{ fontFamily: "system-ui" }}
                          >
                            {level?.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </FormControl>
                )}
              </>
            ) : (
              // OTP
              <FormControl
                fullWidth
                error={Boolean(touched.otp && errors.otp)}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-otp-register"
                  sx={{ fontFamily: "system-ui" }}
                >
                  OTP
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-otp-register"
                  type="text"
                  value={values.otp}
                  name="otp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{}}
                />
                {touched.otp && errors.otp && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text--register"
                  >
                    {errors.otp}
                  </FormHelperText>
                )}
              </FormControl>
            )}

            {/* <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={(event) => setChecked(event.target.checked)}
                                            name="checked"
                                            color="primary"
                                        />
                                    }
                                    label={<Typography variant="subtitle1">Agree with &nbsp;Terms & Condition</Typography>}
                                />
                            </Grid>
                        </Grid> */}

            {/* {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )} */}

            {/* Button */}
            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={loading}
                  disabled={
                    verifyOtp
                      ? !values.email?.length > 0 ||
                        !(values.password?.length >= 6)
                      : !values.fname?.length > 0 ||
                        !values.lname?.length > 0 ||
                        !values.email?.length > 0 ||
                        !(values.password?.length >= 6)
                  }
                  sx={{
                    fontFamily: "system-ui",
                    fontSize: "1rem",
                  }}
                >
                  {verifyOtp ? "Create User" : "Register"}
                </LoadingButton>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AuthRegister;
