import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Alert,
  Backdrop,
  Breadcrumbs,
  Button,
  Card,
  Grid,
  Modal,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import MainCard from "../../ui-component/cards/MainCard";
import CircularProgress from "@mui/material/CircularProgress";
import { Icon } from "@iconify/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box } from "@mui/system";

const config = require("../../Api/api.json");

const JiraProjectsShow = config.JiraProjectsShow;
const JiraIssueSendToMongo = config.JiraIssueSendToMongo;

let message;
let variant;

function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const JiraProjects = () => {
  const [urlParams, setUrlParams] = useSearchParams();

  const navigate = useNavigate();
  const accessToken = getCookie("token");
  const id = urlParams.get("id");

  const [pageState, setPageState] = React.useState({
    data: [],
    total: 0,
    page: 1,
    pageSize: 5,
  });
  const [projKey, setProjKey] = React.useState();
  const [fetchName, setFtechName] = React.useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const columns = [
    {
      field: "slNo",
      headerName: "Serial No",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "accountId",
      headerName: "Account ID",
      width: 225,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 225,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "projectName",
      headerName: "Project Name",
      width: 225,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "projectKey",
      headerName: "Project Key",
      width: 175,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "projectId",
      headerName: "Project ID",
      width: 175,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "sendToMongo",
      headerName: "Load to Server",
      width: 150,
      renderCell: (e) => {
        return (
          <Tooltip title="Load Issues to Server" followCursor>
            <span>
              <Button
                onClick={() => {
                  setProjKey(e.row.projectKey);
                  setModalOpen(true);
                }}
              >
                <Icon
                  icon="zondicons:servers"
                  color="#45B39D"
                  width="27.5"
                  height="27.5"
                />
              </Button>
            </span>
          </Tooltip>
        );
      },
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "reload",
    //   headerName: "Reload",
    //   width: 95,
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: (e) => {
    //     return (
    //       <Button
    //         onClick={() => {
    //           getProjectsList();
    //         }}
    //       >
    //         <Icon
    //           icon="tabler:loader-3"
    //           height="25"
    //           width="25"
    //           color="#34495E"
    //         />
    //       </Button>
    //     );
    //   },
    // },
  ];

  const handleCloseSB = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const getProjectsList = () => {
    setLoading(true);
    axios
      .post(
        JiraProjectsShow,
        {
          id: id,
          pageNo: pageState.page,
          page_size: pageState.pageSize,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      )
      .then((apiData) => {
        if (apiData.data?.status === 200) {
          setPageState((prev) => ({
            ...prev,
            data: apiData?.data?.result?.response?.map((e, index) => ({
              id: index,
              slNo: (pageState.page - 1) * pageState.pageSize + (index + 1),
              accountId: e.account_id,
              email: e.email_address,
              projectName: e.project_name,
              projectKey: e.project_key,
              projectId: e.project_id,
              state: e.state,
              objId: e._id,
            })),
            total: apiData?.data?.result?.total_result_count,
          }));
        } else {
          message = apiData.data?.result?.message;
          variant = "warning";
          setError(true);
        }

        setLoading(false);
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          message = "Internal Server Error!";
          variant = "error";
          setError(true);
        }
      });
  };

  const jiraIssueSendToMongo = (projKey, fetchName) => {
    setLoading(true);

    return new Promise((resolve, reject) => {
      axios
        .post(
          JiraIssueSendToMongo,
          {
            id: id,
            project_key: projKey,
            save_name: fetchName,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((apiData) => {
          setLoading(false);

          if (
            apiData.data?.status === 200 &&
            apiData.data.result?.message === "issue fetching in progress!"
          ) {
            message = "Data send to Server Initiated Successfully";
            variant = "success";
          } else if (apiData.data?.status === 500) {
            message = apiData.data?.result?.message;
            variant = "warning";
          } else {
            message = apiData.data?.result?.message;
            variant = "error";
          }
          setError(true);
          resolve();
        })
        .catch((error) => {
          if (error) {
            setLoading(false);
            message = "Internal Server Error!";
            variant = "error";
            setError(true);
          }
        });
    });
  };

  useEffect(() => {
    getProjectsList();
  }, [id, pageState.page, pageState.pageSize]);

  return (
    <MainCard>
      {/* Snackbar */}
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSB}>
        <Alert
          variant="filled"
          onClose={handleCloseSB}
          severity={variant}
          sx={{ width: "100%", zIndex: 999 }}
        >
          {message}
        </Alert>
      </Snackbar>

      {/* Loadeer */}
      <Backdrop sx={{ color: "#fff", zIndex: 99999 }} open={loading}>
        <CircularProgress color="inherit" />
        <Typography sx={{ fontFamily: "system-ui", fontSize: "1rem", ml: 1 }}>
          Loading...
        </Typography>
      </Backdrop>

      {/* Page Heading */}
      <Typography
        sx={{
          fontSize: "1.35rem",
          fontWeight: "bold",
          color: "#34495E",
          mb: 2.5,
        }}
      >
        Jira Projects
      </Typography>

      {/* Back Btn + Breadcrumbs */}
      <Grid container alignItems="center">
        <Icon
          icon="ic:round-arrow-back"
          height="30"
          width="30"
          color="#34495E"
          onClick={() => {
            navigate("/jira");
          }}
        />
        <Breadcrumbs sx={{ px: 1 }}>
          <Button
            sx={{ fontFamily: "system-ui", color: "gray" }}
            onClick={() => {
              navigate("/jira");
            }}
          >
            Jira Connections
          </Button>
          <Button
            sx={{ fontFamily: "system-ui", color: "gray" }}
            onClick={() => {
              navigate(
                `/jira/projects?${new URLSearchParams({
                  id: id,
                })}`
              );
            }}
          >
            Jira Projects
          </Button>
        </Breadcrumbs>
      </Grid>

      {/* Table */}
      <Grid container sx={{ my: 5 }} direction="column">
        {/* Reload */}
        {/* <Grid item alignSelf="flex-end">
          <Button
            sx={{ color: "#34495E" }}
            onClick={() => {
              getProjectsList();
            }}
          >
            <Icon
              icon="pepicons-pop:reload"
              height="22.5"
              width="22.5"
              style={{ marginRight: "5px" }}
            />
            Reload the Table
          </Button>
        </Grid> */}

        {/* Table */}
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            rows={pageState.data ? pageState.data : []}
            columns={columns}
            rowCount={pageState.total ? pageState.total : 5}
            pagination
            paginationMode="server"
            page={pageState.page - 1}
            rowsPerPageOptions={[5, 10, 25, 50]}
            pageSize={pageState.pageSize}
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            onPageSizeChange={(newPageSize) =>
              setPageState((old) => ({
                ...old,
                page: 1,
                pageSize: newPageSize,
              }))
            }
          />
        </Grid>
      </Grid>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setProjKey();
          setFtechName("");
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            width: "35vw",
            p: 4.5,
          }}
        >
          <Grid container direction="column">
            {/* Top Bar */}
            <Grid item sx={{ mb: 1.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                {/* Heading */}
                <Grid item xs={11}>
                  <Typography sx={{ fontSize: "1.15rem" }}>
                    Provide a Issue Name
                  </Typography>
                </Grid>
                {/* Close Btn */}
                <Grid item xs={1}>
                  <Icon
                    icon="line-md:menu-to-close-transition"
                    color="#34495E"
                    height="25.5"
                    width="25.5"
                    onClick={() => {
                      setModalOpen(false);
                      setProjKey();
                      setFtechName("");
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Issue Name */}
            <Grid item sx={{ mb: 1 }}>
              <TextField
                fullWidth
                id="jira_issue_fetch_name"
                label="Issue Name"
                variant="outlined"
                value={fetchName}
                onChange={(event) => {
                  setFtechName(event.target.value);
                }}
              />
            </Grid>

            {/* Fetch Btn */}
            <Grid item alignSelf="center" sx={{ py: 1.5 }}>
              <Button
                variant="outlined"
                sx={{
                  color: "#34495E",
                  fontFamily: "system-ui",
                  fontSize: "1rem",
                  textAlign: "center",
                  borderColor: "#34495E",
                }}
                onClick={async () => {
                  await jiraIssueSendToMongo(projKey, fetchName);
                  setTimeout(() => {
                    navigate("/document-upload");
                  }, 3000);
                }}
                disabled={fetchName.length === 0}
              >
                Fetch Issues
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </MainCard>
  );
};

export default JiraProjects;
