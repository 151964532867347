import React from "react";
import { Icon } from "@iconify/react";
import ConnectionFrame from "./ConnectionFrame";

const Salesforce = () => {
  return (
    <ConnectionFrame
      icon={
        <Icon
          icon="logos:salesforce"
          height="50"
          width="50"
          style={{ cursor: "default" }}
        />
      }
      connectionType="SALESFORCE"
      connectionTitle="Salesforce"
    />
  );
};

export default Salesforce;
