import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Alert,
  Backdrop,
  Button,
  Card,
  Grid,
  Modal,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import * as Yup from "yup";
import MainCard from "../../ui-component/cards/MainCard";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik, Form, FormikProvider } from "formik";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import copy from "copy-to-clipboard";
import { getCookie } from "../../Components/GetCookie";

const config = require("../../Api/api.json");

const Knowledge = config.DocumentsList;
const BotCreate = config.BotCreate;
const BotList = config.BotList;
const BotDelete = config.BotDelete;
const BotUpdate = config.BotUpdate;

let message;
let variant;

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 2.5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.25 + ITEM_PADDING_TOP,
      width: 225,
    },
  },
};

const CreateChatbot = () => {
  const accessToken = getCookie("token");

  const [pageState, setPageState] = useState({
    data: [],
    total: 0,
    page: 1,
    pageSize: 100,
  });
  const [copied, setCopied] = useState(false);

  const formik = useFormik({
    initialValues: {
      chatbotName: "",
      instructions: "",
      knowledge: [],
    },
    validationSchema: Yup.object().shape({
      chatbotName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Chatbot Name  required"),
      instructions: Yup.string().required("Instructions are required"),
      knowledge: Yup.string().required("Knowledge is required"),
      botId: null,
    }),
    onSubmit: async (values) => {},
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    resetForm,
    onChange,
    setFieldValue,
  } = formik;

  const [chatbotCreateOpen, setChatbotCreateOpen] = useState(false);
  const [knowledge, setKnowledge] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const handleKnowledgeChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected(typeof value === "string" ? value.split(",") : value);
  };

  const handleCloseSB = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const columns = [
    {
      field: "slNo",
      headerName: "Serial No",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "chatbotName",
      headerName: "Chatbot Name",
      width: 250,
      // renderCell: (e) => {
      //   return (
      //     <span>
      //       <Button
      //         onClick={() => {}}
      //         sx={{
      //           color: "#34495E",
      //           fontSize: ".9rem",
      //           fontFamily: "system-ui",
      //           justifyContent: "flex-start",
      //           textTransform: "inherit",
      //           display: "block",
      //           width: 250,
      //           overflow: "hidden",
      //           whiteSpace: "nowrap",
      //           textOverflow: "ellipsis",
      //         }}
      //       >
      //         {e.row.chatbotName}
      //       </Button>
      //     </span>
      //   );
      // },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "chatbotLink",
      headerName: "Chatbot Link",
      width: 500,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => {
        return (
          <Tooltip title="Preview the Trained Chatbot">
            <span>
              <Button
                onClick={() => {
                  if (e.row.chatbotLink.includes("https://")) {
                    window.open(e.row.chatbotLink, "_blank", "noreferrer");
                  }
                }}
                sx={{
                  color: "#34495E",
                  textTransform: "lowercase",
                  fontSize: ".9rem",
                  fontFamily: "system-ui",
                  justifyContent: "flex-start",
                  display: "block",
                  width: 500,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {e.row.chatbotLink}
              </Button>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "instructions",
      headerName: "Instructions",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "knowledge",
      headerName: "Knowledge",
      width: 250,
      headerAlign: "center",
      renderCell: (e) => {
        return (
          <Typography
            sx={{
              fontSize: ".9rem",
              fontFamily: "system-ui",
              justifyContent: "flex-start",
              textTransform: "inherit",
              display: "block",
              width: 250,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {e.row.knowledge?.map((para) => para.name).join(", ")}
          </Typography>
        );
      },
    },
    {
      field: "state",
      headerName: "State",
      width: 125,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => {
        return (
          <Card
            sx={{
              px: 1,
              py: 0.75,
              color: e.row.state === "Completed" ? "#16A085" : "#DC7633",
              fontSize: "0.75rem",
              backgroundColor:
                e.row.state === "Completed"
                  ? "rgba(84, 214, 44, 0.16)"
                  : "rgba(255, 72, 66, 0.16)",
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
              fontFamily: "system-ui",
            }}
          >
            {e.row.state !== "Completed" && (
              <Icon
                icon="eos-icons:three-dots-loading"
                height="22.5"
                width="22.5"
              />
            )}
            {e.row.state}
          </Card>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 95,
      renderCell: (e) => {
        return (
          <Button
            fullWidth
            size="medium"
            sx={{
              fontFamily: "system-ui",
              fontSize: "1rem",
              color: "#34495E",
            }}
            onClick={async () => {
              await getKnowledges("edit");
              setFieldValue("chatbotName", e.row.chatbotName);
              setFieldValue("instructions", e.row.instructions);
              setFieldValue("botId", e.row.botId);
              setSelected(e.row.knowledge.map((para) => para.id));
              setModalOpen(true);
            }}
          >
            <Icon
              icon="line-md:edit-twotone"
              color="#34495E"
              width="25"
              height="25"
            />
          </Button>
        );
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "remove",
      headerName: "Remove",
      width: 95,
      renderCell: (e) => {
        return (
          <Button
            onClick={async () => {
              await deleteBot(e.row.botId);
              getChatbotList();
            }}
          >
            <Icon
              icon="openmoji:delete"
              color="#633974"
              width="25"
              height="25"
            />
          </Button>
        );
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "script",
      headerName: "Script",
      width: 95,
      align: "center",
      headerAlign: "center",
      renderCell: () => {
        return (
          <Grid item sx={{ px: 1 }} xs={0.25}>
            {copied ? (
              <Tooltip title="Script Copied...">
                <Icon
                  icon="ant-design:copy-filled"
                  height="30"
                  width="30"
                  color="#2E4053"
                  onClick={() => {
                    copy(`
<div id="b4b-chatbot"></div>

<script
  type="text/javascript"
  id="ChatBotScript"
  src="https://anish-dev.d1ekynwrz2o7n0.amplifyapp.com/assets/index-cbd6ffe1.js"
  onload="createChatbot((botId = '642e9d581b417092b8d2d259'))"
></script>

<link
  rel="stylesheet"
  href="https://anish-dev.d1ekynwrz2o7n0.amplifyapp.com/assets/index-ac1273f2.css"
/>
`);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Copy To Clipboard...">
                <Icon
                  icon="ant-design:copy-outlined"
                  height="30"
                  width="30"
                  onClick={() => {
                    copy(`
<div id="b4b-chatbot"></div>

<script
  type="text/javascript"
  id="ChatBotScript"
  src="https://anish-dev.d1ekynwrz2o7n0.amplifyapp.com/assets/index-cbd6ffe1.js"
  onload="createChatbot((botId = '642e9d581b417092b8d2d259'))"
></script>

<link
  rel="stylesheet"
  href="https://anish-dev.d1ekynwrz2o7n0.amplifyapp.com/assets/index-ac1273f2.css"
/>
                    `);
                    setCopied(true);
                  }}
                />
              </Tooltip>
            )}
          </Grid>
        );
      },
    },
  ];

  const getChatbotList = () => {
    setLoading(true);
    axios
      .post(
        BotList,
        {
          pageNo: pageState.page,
          page_size: pageState.pageSize,
          total_result_count: pageState.total,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      )
      .then((apiData) => {
        if (apiData.data.status === 200) {
          setPageState((prev) => ({
            ...prev,
            data: apiData.data.result?.response?.map((e, index) => ({
              id: index,
              botId: e.id,
              slNo: (pageState.page - 1) * pageState.pageSize + (index + 1),
              chatbotName: e.bot_name,
              chatbotLink: e.chatbot_link ? e.chatbot_link : "...",
              instructions: e.instruction ? e.instruction : "...",
              knowledge: e.document_refs ? e.document_refs : "...",
              state: e.status ? e.status : "...",
            })),
            total: apiData?.data?.result?.total_result_count,
          }));
        } else {
          message = apiData?.data?.result;
          variant = "warning";
          setError(true);
        }

        setLoading(false);
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          message = "Internal Server Error!";
          variant = "error";
          setError(true);
        }
      });
  };

  const getKnowledges = (mode) => {
    mode === "create" && setBtnLoading(true);
    mode === "edit" && setLoading(true);

    return new Promise((resolve, reject) => {
      axios
        .post(
          Knowledge,
          {
            pageNo: pageState.page,
            page_size: pageState.pageSize,
            total_result_count: null,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((apiData) => {
          setKnowledge(apiData?.data?.response);

          mode === "create" && setBtnLoading(false);
          mode === "edit" && setLoading(false);

          resolve();
        })
        .catch((error) => {
          if (error) {
            message = error?.response?.data?.message
              ? error?.response?.data?.message
              : "Internal Server Error!";
            variant = "error";
            setError(true);

            mode === "create" && setBtnLoading(false);
            mode === "edit" && setEditLoading(false);
          }
        });
    });
  };

  const createBot = () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          BotCreate,
          {
            bot_name: values.chatbotName,
            instruction: values.instructions,
            document_refs: selected,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((apiData) => {
          setLoading(false);
          if (
            apiData.data.status === 200 &&
            apiData.data.result === "Created!"
          ) {
            message = "Chatbot Creation Successfull";
            variant = "success";
            setError(true);
          }
          resolve();
        })
        .catch((error) => {
          if (error) {
            setLoading(false);
            message = "Internal Server Error!";
            variant = "error";
            setError(true);
          }
        });
    });
  };

  const deleteBot = (botId) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          BotDelete,
          {
            bot_id: botId,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((apiData) => {
          setLoading(false);
          if (
            apiData.data.status === 200 &&
            apiData.data.result === "Deleted!"
          ) {
            message = "Chatbot Deletion Successfull";
            variant = "success";
            setError(true);
          }
          resolve();
        })
        .catch((error) => {
          if (error) {
            setLoading(false);
            message = "Internal Server Error!";
            variant = "error";
            setError(true);
          }
        });
    });
  };

  const updateBot = (botId) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          BotUpdate,
          {
            id: botId,
            bot_name: values.chatbotName,
            instruction: values.instructions,
            document_refs: selected,
          },
          { headers: { Authorization: accessToken } }
        )
        .then((apiData) => {
          setLoading(false);
          if (
            apiData.data.status === 200 &&
            apiData.data.result === "Updated!"
          ) {
            message = "Chatbot Updated Successfully";
            variant = "success";
            setError(true);
          }
          resolve();
        })
        .catch((error) => {
          if (error) {
            setLoading(false);
            message = "Internal Server Error!";
            variant = "error";
            setError(true);
          }
        });
    });
  };

  useEffect(() => {
    getChatbotList();
  }, [pageState.page, pageState.pageSize]);

  return (
    <MainCard>
      {/* Snackbar */}
      <Snackbar open={error} autoHideDuration={4000} onClose={handleCloseSB}>
        <Alert
          variant="filled"
          onClose={handleCloseSB}
          severity={variant}
          sx={{ width: "100%", zIndex: 999 }}
        >
          {message}
        </Alert>
      </Snackbar>

      {/* Loadeer */}
      <Backdrop sx={{ color: "#fff", zIndex: 99999 }} open={loading}>
        <CircularProgress color="inherit" />
        <Typography sx={{ fontFamily: "system-ui", fontSize: "1rem", ml: 1 }}>
          Loading...
        </Typography>
      </Backdrop>

      {/* Page Heading */}
      <Typography
        sx={{
          fontSize: "1.35rem",
          fontWeight: "bold",
          color: "#34495E",
          mb: 2.5,
        }}
      >
        Create Chatbot
      </Typography>

      {/* New Chatbot  Btn */}
      <Grid item>
        {chatbotCreateOpen ? (
          // Back Btn
          <Icon
            icon="ion:arrow-back"
            height="27.5"
            width="27.5"
            onClick={() => {
              resetForm();
              setSelected([]);
              setChatbotCreateOpen(false);
            }}
            color="#34495E"
          />
        ) : (
          <Grid container alignItems="center">
            {/* Bot Icon */}
            {/* <Grid item>
              <Icon
                icon="fluent:bot-20-regular"
                height="35"
                width="35"
                color="#34495E"
                onClick={async () => {
                  await getKnowledges();
                  setChatbotCreateOpen(true);
                }}
              />
            </Grid> */}
            {/* Create New ot with Loading */}
            <Grid item>
              <LoadingButton
                fullWidth
                size="medium"
                loading={btnLoading}
                variant="contained"
                sx={{
                  textAlign: "center",
                  borderRadius: 5,
                  boxShadow: 10,
                  backgroundColor: "#1A5276",
                  "&:hover": {
                    background: "#4FB4C4",
                  },
                  px: 3.5,
                  py: 1,
                }}
                onClick={async () => {
                  await getKnowledges("create");
                  setChatbotCreateOpen(true);
                }}
              >
                <Icon
                  icon="fluent:bot-20-regular"
                  height="35"
                  width="35"
                  color="white"
                  onClick={async () => {
                    await getKnowledges();
                    setChatbotCreateOpen(true);
                  }}
                />
                {"  "}
                Create New Chatbot
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* Chatbot Creation Form */}
      {chatbotCreateOpen && (
        <Grid container sx={{ py: 2.5 }}>
          <Grid item md={4} xs={12}>
            <FormikProvider value={formik}>
              <Form autoComplete="on" onSubmit={handleSubmit}>
                <Grid container direction="column">
                  {/* Connnection Name */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder="Enter Chatbot Name..."
                      label="Chatbot Name"
                      {...getFieldProps("chatbotName")}
                      error={Boolean(touched.chatbotName && errors.chatbotName)}
                      helperText={touched.chatbotName && errors.chatbotName}
                      sx={{ mb: 1.5 }}
                    />
                  </Grid>

                  {/* Instructions */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows="4"
                      placeholder="Enter Some Instructions..."
                      label="Instructions"
                      {...getFieldProps("instructions")}
                      error={Boolean(
                        touched.instructions && errors.instructions
                      )}
                      helperText={touched.instructions && errors.instructions}
                      sx={{ mb: 1.5 }}
                    />
                  </Grid>

                  {/* Select Knowledge */}
                  <Grid item xs={12}>
                    <FormControl fullWidth sx={{ width: 500 }}>
                      <InputLabel id="create-knowledge">Knowledge</InputLabel>
                      <Select
                        labelId="create-knowledge"
                        id="create-knowledge"
                        multiple
                        multiline
                        value={selected}
                        onChange={handleKnowledgeChange}
                        input={<OutlinedInput label="Knowledge" />}
                        renderValue={(para) => {
                          const filArray = knowledge?.filter(
                            (item) => para.indexOf(item._id) > -1
                          );
                          const str = filArray
                            .reduce(
                              (prev, curr) => `${prev}, ${curr.save_name}`,
                              ""
                            )
                            .substring(2);
                          return str;
                        }}
                        MenuProps={MenuProps}
                      >
                        {knowledge?.map((para, index) => (
                          <MenuItem key={index} value={para._id}>
                            <Checkbox
                              checked={
                                !!selected.find((item) => item === para._id)
                              }
                            />
                            <ListItemText primary={para.save_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Save Chatbot */}
                  <Grid item xs={12} sx={{ py: 1.5 }}>
                    <Button
                      variant="outlined"
                      sx={{
                        fontFamily: "system-ui",
                        fontSize: "1.025rem",
                        color: "#34495E",
                        borderColor: "#34495E",
                      }}
                      disabled={
                        selected.length === 0 ||
                        !values.chatbotName ||
                        !values.instructions
                      }
                      onClick={async () => {
                        await createBot();
                        getChatbotList();
                        resetForm();
                        setSelected([]);
                        setChatbotCreateOpen(false);
                      }}
                    >
                      Save Chatbot
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </Grid>
        </Grid>
      )}

      {/* Table */}
      {!chatbotCreateOpen && (
        <Grid container sx={{ my: 5 }}>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              rows={pageState.data ? pageState.data : []}
              columns={columns}
              rowCount={pageState.total ? pageState.total : 5}
              pagination
              paginationMode="server"
              page={pageState.page - 1}
              rowsPerPageOptions={[5, 10, 25, 50]}
              pageSize={pageState.pageSize}
              onPageChange={(newPage) => {
                setPageState((old) => ({ ...old, page: newPage + 1 }));
              }}
              onPageSizeChange={(newPageSize) =>
                setPageState((old) => ({
                  ...old,
                  page: 1,
                  pageSize: newPageSize,
                }))
              }
            />
          </Grid>
        </Grid>
      )}

      {/* Edit Connection Modal */}
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          resetForm({
            values: {},
          });
          setSelected([]);
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            width: "40.5vw",
            p: 2.5,
          }}
        >
          <FormikProvider value={formik}>
            <Form autoComplete="on" onSubmit={handleSubmit}>
              <Grid container direction="column">
                {/* Top Bar , Close Btn */}
                <Grid item xs={12} sx={{ pb: 2.5 }}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ px: 0.75 }}
                  >
                    <Grid item>
                      <Typography
                        sx={{
                          fontSize: "1.15rem",
                          color: "#34495E",
                          fontWeight: "bold",
                        }}
                      >
                        Edit Chatbot
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Icon
                        icon="line-md:menu-to-close-transition"
                        color="#34495E"
                        height="25.5"
                        width="25.5"
                        onClick={() => {
                          setModalOpen(false);
                          resetForm({
                            values: {},
                          });
                          setSelected([]);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Connnection Name */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    onChange
                    placeholder="Enter Chatbot Name..."
                    label="Chatbot Name"
                    {...getFieldProps("chatbotName")}
                    error={Boolean(touched.chatbotName && errors.chatbotName)}
                    helperText={touched.chatbotName && errors.chatbotName}
                    sx={{ mb: 1.5 }}
                  />
                </Grid>

                {/* Instructions */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    onChange
                    rows="4"
                    placeholder="Enter Some Instructions..."
                    label="Instructions"
                    {...getFieldProps("instructions")}
                    error={Boolean(touched.instructions && errors.instructions)}
                    helperText={touched.instructions && errors.instructions}
                    sx={{ mb: 1.5 }}
                  />
                </Grid>

                {/* Select Knowledge  */}
                <Grid item xs={12}>
                  <FormControl fullWidth sx={{ width: "37.5vw" }}>
                    <InputLabel id="create-knowledge">Knowledge</InputLabel>
                    <Select
                      labelId="create-knowledge"
                      id="create-knowledge"
                      multiple
                      value={selected}
                      onChange={handleKnowledgeChange}
                      input={<OutlinedInput label="Knowledge" />}
                      renderValue={(para) => {
                        const filArray = knowledge?.filter(
                          (item) => para.indexOf(item._id) > -1
                        );
                        const str = filArray
                          .reduce(
                            (prev, curr) => `${prev}, ${curr.save_name}`,
                            ""
                          )
                          .substring(2);
                        return str;
                      }}
                      MenuProps={MenuProps}
                    >
                      {knowledge?.map((para, index) => (
                        <MenuItem key={index} value={para._id}>
                          <Checkbox
                            checked={
                              !!selected.find((item) => item === para._id)
                            }
                          />
                          <ListItemText primary={para.save_name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Save Chatbot */}
                <Grid item xs={12} alignSelf="center">
                  <Button
                    sx={{
                      mt: 1,
                      py: 1.75,
                      fontFamily: "system-ui",
                      fontSize: "1.025rem",
                      color: "#34495E",
                    }}
                    onClick={() => {
                      updateBot(values.botId);
                    }}
                    disabled={
                      !values.chatbotName ||
                      !values.instructions ||
                      selected.length === 0
                    }
                  >
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Modal>
    </MainCard>
  );
};

export default CreateChatbot;
