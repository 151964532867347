import { createSlice } from "@reduxjs/toolkit";
import { projets } from "../Components/MockDatas";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    storedProjName: null,
    storedProjDetails: [],
    storedAllProjDetails: projets,
    sidebarItems: [],
  },
  reducers: {
    storedProjNameHandler: (state, action) => {
      state.storedProjName = action.payload;
    },
    storedProjDetailsHandler: (state, action) => {
      state.storedProjDetails = action.payload;
    },
    storedAllProjDetailsHandler: (state, action) => {
      state.storedAllProjDetails = action.payload;
    },
    sidebarItemsHandler: (state, action) => {
      state.sidebarItems = action.payload;
    },
  },
});

export const projectSliceActions = projectSlice.actions;
export const projectSliceReducers = projectSlice.reducer;
