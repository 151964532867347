import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";

export const mockDatas = [
  {
    id: 1,
    project_title: "Chemistry",
    project_name: "Titration",
    project_description: "chemistry related examples and research articles",
  },
  {
    id: 2,
    project_title: "Physics",
    project_name: "Newtons Law",
    project_description: "Newtons Law related examples and research articles",
  },
  {
    id: 1,
    project_title: "Maths",
    project_name: "Parabola",
    project_description: "Parabola related examples and research articles",
  },
  {
    id: 1,
    project_title: "Biology",
    project_name: "Plant Structures",
    project_description:
      "Plant Structures related examples and research articles",
  },
];

export const projets = [
  {
    id: 1,
    name: "Admin",
    description: "Subhro Admin Project",
    sidebar_id: [
      { p_id: "knowledge_graph", name: "Knowledge Graph" },
      { p_id: "document-upload", name: "Document Upload" },
      { p_id: "configuration", name: "Configuration" },
      // transcript,
      // createChatbot,
      // connection,
      // salesforces,
      // salesforce_query_connections,
      // jira,
      // dropbox,
      { p_id: "transcript", name: "Transcript" },
      { p_id: "createChatbot", name: "Create Chatbot" },
      { p_id: "connection", name: "Connection" },
      { p_id: "salesforces", name: "Salesforces" },
      { p_id: "salesforce_query_connections", name: "Salesforce Schema" },
      { p_id: "jira", name: "Jira" },
      { p_id: "dropbox", name: "Dropbox" },
      { p_id: "user_access", name: "Access Control" },
    ],
  },
];

export const createData = (id, name, status) => {
  return {
    id,
    name,
    status,
  };
};

export const Rows = ({
  row: { id, name, status },
  checkStatus,
  handleCheck,
  isDisabled,
}) => {
  return (
    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell component="th">{name}</TableCell>
      <TableCell align="right">
        <Checkbox
          id={name}
          checked={checkStatus}
          onChange={handleCheck}
          disabled={isDisabled}
          inputProps={{ "aria-label": "controlled" }}
        />
      </TableCell>
    </TableRow>
  );
};
