import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Alert,
  Backdrop,
  Breadcrumbs,
  Button,
  Card,
  Grid,
  Modal,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import MainCard from "../../ui-component/cards/MainCard";
import CircularProgress from "@mui/material/CircularProgress";
import { Icon } from "@iconify/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box } from "@mui/system";
import ImageZoom from "./ImageZoom";

const config = require("../../Api/api.json");

const JiraIssuesShow = config.JiraIssuesShow;

let message;
let variant;

function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const JiraIssues = () => {
  const [urlParams, setUrlParams] = useSearchParams();

  const navigate = useNavigate();
  const accessToken = getCookie("token");
  const objId = urlParams.get("objId");

  const [pageState, setPageState] = React.useState({
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const [modalOpen, setModalOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [plantuml, setPlantuml] = React.useState();

  const columns = [
    {
      field: "slNo",
      headerName: "Serial No",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "summary",
      headerName: "Summary",
      width: 425,
      // renderCell: (e) => {
      //   return (
      //     <Button
      //       onClick={() => {}}
      //       sx={{
      //         color: "#34495E",
      //         fontSize: ".9rem",
      //         fontFamily: "system-ui",
      //         justifyContent: "flex-start",
      //         textTransform: "inherit",
      //         display: "block",
      //         width: 425,
      //         overflow: "hidden",
      //         whiteSpace: "nowrap",
      //         textOverflow: "ellipsis",
      //       }}
      //     >
      //       {e.row.summary}
      //     </Button>
      //   );
      // },
      // align: "center",
      headerAlign: "center",
    },
    {
      field: "issueLink",
      headerName: "Issue Link",
      width: 375,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => {
        return (
          <Button
            onClick={() => {
              window.open(e.row.issueLink, "_blank", "noreferrer");
            }}
            sx={{ color: "#34495E", textTransform: "lowercase" }}
          >
            {e.row.issueLink}
          </Button>
        );
      },
    },
    {
      field: "issueKey",
      headerName: "Issue Key",
      width: 175,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "knowledge",
      headerName: "Knowledge",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => {
        return (
          <Tooltip title="Knowledge Graph" followCursor>
            <span>
              <Button
                sx={{ color: "#34495E" }}
                onClick={() => {
                  setPlantuml(e.row.plantumlData);
                  setModalOpen(true);
                }}
              >
                <Icon icon="clarity:tree-line" width="25" height="25" />
              </Button>
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const handleCloseSB = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const getIssuesList = () => {
    setLoading(true);

    axios
      .post(
        JiraIssuesShow,
        {
          obj_id: objId,
          pageNo: pageState.page,
          page_size: pageState.pageSize,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      )
      .then((apiData) => {
        if (apiData.data.status === 200) {
          setPageState((prev) => ({
            ...prev,
            data: apiData?.data?.result?.response?.map((e, index) => ({
              id: index,
              slNo: (pageState.page - 1) * pageState.pageSize + (index + 1),
              summary: e["summary:"],
              issueLink: e["issue_link:"],
              issueKey: e["key:"],
              plantumlData: e.plantUml_url,
            })),
            total: apiData?.data?.result?.total_result_count,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          message = "Internal Server Error!";
          variant = "error";
          setError(true);
        }
      });
  };

  useEffect(() => {
    getIssuesList();
  }, [objId, pageState.page, pageState.pageSize]);

  return (
    <MainCard>
      {/* Snackbar */}
      <Snackbar open={error} autoHideDuration={5000} onClose={handleCloseSB}>
        <Alert
          variant="filled"
          onClose={handleCloseSB}
          severity={variant}
          sx={{ width: "100%", zIndex: 999 }}
        >
          {message}
        </Alert>
      </Snackbar>

      {/* Loadeer */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <Typography sx={{ fontFamily: "system-ui", fontSize: "1rem", ml: 1 }}>
          Loading...
        </Typography>
      </Backdrop>

      {/* Page Heading */}
      <Typography
        sx={{
          fontSize: "1.35rem",
          fontWeight: "bold",
          color: "#34495E",
          mb: 2.5,
        }}
      >
        Jira Issues
      </Typography>

      {/* Back Btn + Breadcrumbs */}
      <Grid container alignItems="center">
        <Icon
          icon="ic:round-arrow-back"
          height="30"
          width="30"
          color="#34495E"
          onClick={() => {
            window.history.back();
          }}
        />
        {/* <Breadcrumbs sx={{ px: 1 }}>
          <Button
            sx={{ fontFamily: "system-ui", color: "gray" }}
            onClick={() => {
              navigate("/jira");
            }}
          >
            Jira Conections
          </Button>

          <Button
            sx={{ fontFamily: "system-ui", color: "gray" }}
            onClick={() => {
              window.history.back();
            }}
          >
            Jira Projects
          </Button>

          <Button
            sx={{ fontFamily: "system-ui", color: "gray" }}
            onClick={() => {
              navigate("/jira/issues");
            }}
          >
            Jira Issues
          </Button>
        </Breadcrumbs> */}
      </Grid>

      {/* Table */}
      <Grid container sx={{ my: 5 }}>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            rows={pageState.data ? pageState.data : []}
            columns={columns}
            rowCount={pageState.total ? pageState.total : 5}
            pagination
            paginationMode="server"
            page={pageState.page - 1}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pageSize={pageState.pageSize}
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            onPageSizeChange={(newPageSize) =>
              setPageState((old) => ({
                ...old,
                page: 1,
                pageSize: newPageSize,
              }))
            }
          />
        </Grid>
      </Grid>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            width: "95vw",
            height: "95vh",
            p: 2.5,
          }}
        >
          <Grid container direction="column">
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography sx={{ fontSize: "1.15rem" }}>
                    Plant UML Diagram
                  </Typography>
                </Grid>
                <Grid item>
                  <Icon
                    icon="line-md:menu-to-close-alt-transition"
                    height="25"
                    width="25"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                    color="#34495E"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <ImageZoom src={plantuml} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </MainCard>
  );
};

export default JiraIssues;
