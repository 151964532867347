import { useState } from "react";
import { useNavigate } from "react-router";
import { parseISO } from "date-fns";
import {
  Alert,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MainCard from "../../ui-component/cards/MainCard";
import { Icon } from "@iconify/react";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

const config = require("../../Api/api.json");

const DocumentsList = config.DocumentsList;
const CreateDocument = config.CreateDocument;
const DeleteSingleList = config.DeleteSingleList;
const UploadFile = config.UploadFile;

function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

let message;
let variant;

const DocumentUplaod = () => {
  const accessToken = getCookie("token");

  const navigate = useNavigate();

  const [uplLink, setUplLink] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pageState, setPageState] = useState({
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const columns = [
    {
      field: "slNo",
      headerName: "Serial No",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "documnetName",
      headerName: "Document Name",
      width: 275,
      renderCell: (e) => {
        return (
          <Tooltip title="Go through Details..." followCursor>
            <span>
              <Button
                onClick={() => {
                  if (e.row.type === "jira") {
                    navigate(
                      "/jira/issues?" +
                        new URLSearchParams({
                          objId: e.row.objId,
                        }).toString()
                    );
                  } else {
                    navigate(
                      "/details?" +
                        new URLSearchParams({
                          type: e.row.type,
                          hashValue: e.row.hashValue,
                        }).toString()
                    );
                  }
                }}
                sx={{
                  color: "#34495E",
                  fontSize: ".9rem",
                  fontFamily: "system-ui",
                  justifyContent: "flex-start",
                  textTransform: "inherit",
                  display: "block",
                  width: 275,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {e.row.documnetName}
              </Button>
            </span>
          </Tooltip>
        );
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "added",
      headerName: "Added",
      type: "date",
      width: 125,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "lastModified",
      headerName: "Last Modified",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => {
        return (
          <>
            {e.row.type === "salesforce" && (
              <Icon icon="logos:salesforce" height="35" width="35" />
            )}
            {e.row.type === "jira" && (
              <Icon icon="devicon:jira" height="35" width="35" />
            )}
            {e.row.type === "url" && (
              <Tooltip title="URL Link" followCursor>
                <Icon icon="uil:link" height="35" width="35" color="#5499C7" />
              </Tooltip>
            )}
            {e.row.type === "docx" && (
              <Icon
                icon="bi:filetype-docx"
                height="35"
                width="35"
                color="#5499C7"
              />
            )}
          </>
        );
      },
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "state",
      headerName: "State",
      width: 125,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => {
        return (
          <Card
            sx={{
              px: 1,
              py: 0.75,
              color: e.row.state === "Completed" ? "#16A085" : "#DC7633",
              fontSize: "0.75rem",
              backgroundColor:
                e.row.state === "Completed"
                  ? "rgba(84, 214, 44, 0.16)"
                  : "rgba(255, 72, 66, 0.16)",
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
              fontFamily: "system-ui",
            }}
          >
            {e.row.state !== "Completed" && (
              <Icon
                icon="eos-icons:three-dots-loading"
                height="22.5"
                width="22.5"
              />
            )}
            {e.row.state}
          </Card>
        );
      },
    },
    {
      field: "remove",
      headerName: "Remove",
      width: 95,
      renderCell: (e) => {
        return (
          <Tooltip
            title={
              e.row.state === "Completed"
                ? "Delete This Document!"
                : "Wait Until Completion of the Process!"
            }
            followCursor
          >
            <span>
              <Button
                onClick={async () => {
                  // const filRows = rows.filter((item) => item.id !== e.row.id);
                  // setRows(filRows);
                  await deleteSingleList(e.row.hashValue);
                  getUploadedDocumentsList();
                }}
                disabled={e.row.state !== "Completed"}
              >
                <Icon icon="openmoji:delete" width="25" height="25" />
              </Button>
            </span>
          </Tooltip>
        );
      },
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "reload",
    //   headerName: "Reload",
    //   width: 95,
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: (e) => {
    //     return (
    //       <Tooltip title="Reload Table Data...">
    //         <Button
    //           onClick={() => {
    //             getUploadedDocumentsList();
    //           }}
    //         >
    //           <Icon
    //             icon="tabler:loader-3"
    //             height="25"
    //             width="25"
    //             color="#34495E"
    //           />
    //         </Button>
    //       </Tooltip>
    //     );
    //   },
    // },
  ];

  const handleCloseSB = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const getUploadedDocumentsList = () => {
    setLoading(true);

    axios
      .post(
        DocumentsList,
        {
          pageNo: pageState.page,
          page_size: pageState.pageSize,
          total_result_count: pageState.total,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      )
      .then((apiData) => {
        if (apiData.data.message) {
          // message = apiData.data.message;
          // variant = "warning";
          // setError(true);
          setLoading(false);
        }

        setPageState((prev) => ({
          ...prev,
          data: apiData?.data?.response?.map((e, index) => ({
            hashValue: e.hash_value,
            id: index,
            objId: e._id,
            slNo: (pageState.page - 1) * pageState.pageSize + (index + 1),
            documnetName: e.save_name,
            preview: "",
            added: parseISO(e.created_at),
            lastModified: parseISO(e.last_modified),
            modifiedBy: e.modifiedBy ? e.modifiedBy : "Not Modified",
            state: e.current_state,
            type: e.type,
            remove: "",
          })),
          total: apiData.data.total_result_count,
        }));

        setLoading(false);
      })
      .catch((error) => {
        if (error) {
          message = error.response?.data?.message
            ? error.response?.data?.message
            : "Internal Server Error!";
          variant = "error";
          setError(true);
          setLoading(false);
        }
      });
  };

  const createKnowledge = (uplLink) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      if (uplLink.length === 0) {
        message = "Sorry! No Link Provided...";
        variant = "warning";
        setError(true);
        setLoading(false);
      } else {
        axios
          .post(
            CreateDocument,
            {
              url: uplLink,
              type: "url",
              min_time: 10,
              max_time: 20,
              save_name: uplLink,
            },
            {
              headers: {
                Authorization: accessToken,
              },
            }
          )
          .then((apiData) => {
            if (apiData.data.message) {
              if (apiData.data.message === "Document Insertion Successful") {
                message = "Document Inserted Successfully...";
                variant = "success";
              } else {
                message = apiData.data.message;
                variant = "warning";
              }
              setError(true);
              setLoading(false);
            }
            setLoading(false);
            resolve();
          })
          .catch((error) => {
            if (error) {
              message = error.response?.data?.message
                ? error.response?.data?.message
                : "Internal Server Error!";
              variant = "error";
            }

            setError(true);
            setLoading(false);
          });
      }
    });
  };

  const deleteSingleList = (hashValue) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          DeleteSingleList,
          {
            hash_value: hashValue,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((apiData) => {
          if (apiData.data.message === "Item deleted successfully!") {
            message = "Item Successfully Deleted!";
            variant = "success";
          } else {
            message = apiData.data.message;
            variant = "warning";
          }
          setError(true);
          setLoading(false);
          resolve();
        })
        .catch((error) => {
          if (error.response) {
            message = error.response.data?.message
              ? error.response.data?.message
              : "Internal Server Error!";
            variant = "error";
            setError(true);
            setLoading(false);
          }
        });
    });
  };

  const uploadDocument = (payload, wholeData) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      axios
        .post(UploadFile, payload, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((apiData) => {
          setLoading(false);
          const uploads = [];
          for (let i = 0; i < apiData.data.result?.file_link?.length; i++) {
            const bodyFormData = new FormData();
            for (const [key, value] of Object.entries(
              apiData.data.result?.file_link[i].fields
            )) {
              bodyFormData.append(key, value);
            }
            bodyFormData.append("file", wholeData[i], "");

            uploads.push(
              axios.post(apiData.data.result?.file_link[i].url, bodyFormData)
            );
          }
          Promise.all(uploads).then((res) => {
            message = "Documents Uploaded Successfully";
            variant = "success";
            setError(true);
            resolve();
          });
        })
        .catch((error) => {
          if (error) {
            setLoading(false);
            message = "Internal Server Error";
            variant = "error";
            setError(true);
          }
        });
    });
  };

  useEffect(() => {
    getUploadedDocumentsList();
  }, [pageState.page, pageState.pageSize]);

  return (
    <MainCard>
      {/* Loadeer */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <Typography sx={{ fontFamily: "system-ui", fontSize: "1rem", ml: 1 }}>
          Loading...
        </Typography>
      </Backdrop>

      {/* Snackbar */}
      <Snackbar open={error} autoHideDuration={3500} onClose={handleCloseSB}>
        <Alert
          variant="filled"
          onClose={handleCloseSB}
          severity={variant}
          sx={{ width: "100%", zIndex: 999 }}
        >
          {message}
        </Alert>
      </Snackbar>

      {/* Page Heading */}
      <Typography
        sx={{
          fontSize: "1.35rem",
          fontWeight: "bold",
          mb: 2.5,
          color: "#34495E",
        }}
      >
        Upload Document
      </Typography>

      {/* Upload Link */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ my: 2.5, px: 2 }}
      >
        <Grid item xs={9}>
          <FormControl fullWidth>
            <InputLabel htmlFor="upload-link">Link</InputLabel>
            <OutlinedInput
              id="upload-link"
              placeholder="https://"
              label="Amount"
              value={uplLink}
              onChange={(e) => {
                setUplLink(e.target.value);
              }}
              onKeyPress={async (e) => {
                if (e.key == "Enter") {
                  await createKnowledge(uplLink);
                  getUploadedDocumentsList();
                }
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={1}>
          <Button
            type="submit"
            variant="contained"
            sx={{
              textAlign: "center",
              borderzRadius: 3,
              boxShadow: 10,
              borderRadius: "10px",
              backgroundColor: "#1A5276",
              "&:hover": {
                background: "#4FB4C4",
              },
              mx: 2,
              py: 1,
              px: 2.5,
            }}
            onClick={async () => {
              await createKnowledge(uplLink);
              getUploadedDocumentsList();
            }}
          >
            Fetch
          </Button>
        </Grid>
      </Grid>

      {/* OR Divider */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2.5 }}
      >
        <Grid item xs={5}>
          <Divider style={{ borderColor: "#633974" }} />
        </Grid>
        <Grid item xs={0.5}>
          <Typography
            sx={{
              fontSize: "1.15rem",
              fontFamily: "system-ui",
              fontWeight: "bold",
              color: "#34495E",
            }}
          >
            Or
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Divider style={{ borderColor: "#633974" }} />
        </Grid>
      </Grid>

      {/* Upload Document Btn  */}
      <Button
        component="label"
        // sx={{
        //   fontSize: "1.15rem",
        //   fontFamily: "system-ui",
        //   color: "#34495E",
        //   border: "1px solid #34495E",
        //   borderRadius: "15px",
        //   "&:hover": {
        //     borderColor: "#17202A",
        //   },
        //   px: 2,
        // }}
        variant="contained"
        sx={{
          textAlign: "center",
          borderzRadius: 3,
          boxShadow: 10,
          borderRadius: "15px",
          backgroundColor: "#1A5276",
          "&:hover": {
            background: "#4FB4C4",
          },
        }}
      >
        <Icon icon="line-md:upload-outline-loop" width="35" height="35" />
        Upload
        <input
          hidden
          multiple
          type="file"
          accept=".docx"
          onChange={async (e) => {
            const uploadedFiles = e.target.files;
            const updatedFiles = [];

            for (let i = 0; i < uploadedFiles.length; i++) {
              const file = uploadedFiles[i];
              const fileName = file.name;
              const fileType = file.type;

              updatedFiles.push({
                key: fileName,
                doc_file_type: fileType,
              });
            }
            await uploadDocument(updatedFiles, e.target.files);
            getUploadedDocumentsList();
          }}
        />
      </Button>

      {/* Table */}
      <Grid container sx={{ my: 5 }} direction="column">
        {/* Reload */}
        <Grid item alignSelf="flex-end">
          <Tooltip title="Click to Reload The Table...">
            <Button
              // sx={{ color: "#34495E" }}
              variant="contained"
              sx={{
                textAlign: "center",
                borderzRadius: 3,
                boxShadow: 10,
                backgroundColor: "#1A5276",
                "&:hover": {
                  background: "#4FB4C4",
                },
                my: 2,
              }}
              onClick={() => {
                getUploadedDocumentsList();
              }}
            >
              <Icon
                icon="pepicons-pop:reload"
                height="23.5"
                width="23.5"
                style={{ marginRight: "5px" }}
              />
            </Button>
          </Tooltip>
        </Grid>

        {/* Table */}
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            rows={pageState.data ? pageState.data : []}
            columns={columns}
            rowCount={pageState.total ? pageState.total : 5}
            pagination
            paginationMode="server"
            page={pageState.page - 1}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pageSize={pageState.pageSize}
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            onPageSizeChange={(newPageSize) =>
              setPageState((old) => ({
                ...old,
                page: 1,
                pageSize: newPageSize,
              }))
            }
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default DocumentUplaod;
