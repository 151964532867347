import React from "react";
import { Icon } from "@iconify/react";
import ConnectionFrame from "./ConnectionFrame";

const Jira = () => {
  return (
    <ConnectionFrame
      icon={
        <Icon
          icon="logos:jira"
          height="35"
          width="35"
          style={{ cursor: "default" }}
        />
      }
      connectionType="JIRA"
      connectionTitle="Atlassian"
    />
  );
};

export default Jira;
