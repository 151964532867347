import * as React from "react";
import { useState } from "react";
import {
  Alert,
  Breadcrumbs,
  Button,
  Grid,
  ImageList,
  ImageListItem,
  Modal,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MainCard from "../../ui-component/cards/MainCard";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { object } from "prop-types";

const config = require("../../Api/api.json");

const ThumbnailsList = config.ThumbnailsList;
const SingleDocumentDetails = config.SingleDocumentDetails;
const UpdateSingleDocument = config.UpdateSingleDocument;
const DeleteSingleThumbnailData = config.DeleteSingleThumbnailData;

function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

let message;
let variant;

const Details = () => {
  const accessToken = getCookie("token");

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const hashValue = searchParams.get("hashValue");
  const type = searchParams.get("type");

  const [data, setData] = useState([]);
  const [caseNo, setCaseNo] = useState();
  const [content, setContent] = useState("");
  const [selImg, setSelImg] = useState();
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [pageState, setPageState] = useState({
    data: [],
    total: 0,
    page: 1,
    pageSize: 15,
  });

  const columns = [
    {
      field: "slNo",
      headerName: "Serial No",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "caseNumber",
      headerName: "Case Number",
      width: 200,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "description",
      headerName: "Description",
      width: 350,
      headerAlign: "center",
      renderCell: (e) => {
        return (
          <span>
            <Tooltip title="Click to edit Description">
              <Button
                onClick={async () => {
                  await getSingleData(e.row.uuid);
                  setCaseNo(e.row.caseNumber);
                  setOpen(true);
                }}
                sx={{
                  color: "#34495E",
                  fontSize: ".9rem",
                  fontFamily: "system-ui",
                  justifyContent: "flex-start",
                  textTransform: "inherit",
                  display: "block",
                  width: 350,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {e.row.description}
              </Button>
            </Tooltip>
          </span>
        );
      },
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 350,
      headerAlign: "center",
    },
    {
      field: "caseComment",
      headerName: "Case Comment",
      type: "number",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "remove",
      headerName: "Remove",
      width: 95,
      renderCell: (e) => {
        return (
          <Tooltip title="Delete This Data...">
            <Button
              onClick={async () => {
                await deleteSingleThumbnailData(e.row.uuid, e.row.hashValue);
                getThumbnailsList();
              }}
            >
              <Icon
                icon="openmoji:delete"
                color="#34495E"
                width="25"
                height="25"
              />
            </Button>
          </Tooltip>
        );
      },
      align: "center",
      headerAlign: "center",
    },
  ];

  const handleCloseSB = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  const getSingleData = (uuid) => {
    setLoading(true);

    return new Promise((resolve, reject) => {
      axios
        .post(
          SingleDocumentDetails,
          {
            uuid: uuid,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((apiData) => {
          setContent(apiData.data.response[0]);
          setLoading(false);
          resolve();
        })
        .catch((error) => {
          if (error.response) {
            message = "Internal Server Error";
            variant = "error";
            setError(true);
            setLoading(false);
            reject();
          }
        });
    });
  };

  const getThumbnailsList = () => {
    setLoading(true);

    axios
      .post(
        ThumbnailsList,
        {
          hash_value: hashValue,
          type: type,
          pageNo: pageState.page,
          page_size: pageState.pageSize,
          total_result_count: pageState.total,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        }
      )
      .then(async (apiData) => {
        if (apiData.data.message) {
          message = apiData.data.message;
          variant = "warning";
          setError(true);
        } else {
          if (type === "case_data" || type === "salesforce") {
            setPageState((prev) => ({
              ...prev,
              data: apiData?.data?.response?.map((e, index) => ({
                id: index,
                slNo: (pageState.page - 1) * pageState.pageSize + (index + 1),
                caseNumber: e.CaseNumber,
                caseComment: e.CaseComment ? e.caseComment : "...",
                description: e.Description,
                subject: e.Subject,
                status: e.Status ? e.Status : "...",
                uuid: e.uuid,
                hashValue: e.hash_value,
              })),
              total: apiData.data.total_result_count,
            }));
          } else {
            setData(
              apiData.data.response.map((e, index) => ({
                name: index + 1,
                uuid: e.uuid,
                image: e.preview_link,
              }))
            );

            for (const element of apiData.data.response) {
              await getSingleData(element.uuid);
              setSelImg(0);
              break;
            }
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          message = "Internal Server Error";
          variant = "error";
          setError(true);
          setLoading(false);
        }
      });
  };

  const updateSingleData = (uuid, hashValue, updatedContent) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          UpdateSingleDocument,
          {
            update_list: [
              {
                updated_text: [updatedContent],
                uuid: uuid,
              },
            ],
            hash_value: hashValue,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((apiData) => {
          if (apiData.data.message === "Item Updated Sucessfully!") {
            message = "Item Updated Successfully...";
            variant = "success";
          } else {
            message = apiData.data.message;
            variant = "warning";
          }
          setError(true);
          setLoading(false);
          resolve();
        })
        .catch((error) => {
          if (error.response) {
            message = "Internal Server Error";
            variant = "error";
            setError(true);
            setLoading(false);
          }
        });
    });
  };

  const deleteSingleThumbnailData = (uuid, hashValue) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      axios
        .post(
          DeleteSingleThumbnailData,
          {
            uuid: uuid,
            hash_value: hashValue,
          },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((apiData) => {
          if (apiData.data.message === "Data deleted successfully!") {
            message = "Item Data Successfully Deleted!";
            variant = "success";
          } else {
            message = apiData.data.message;
            variant = "warning";
          }
          setError(true);
          setLoading(false);
          resolve();
        })
        .catch((error) => {
          if (error.response) {
            message = error.response.data?.message
              ? error.response.data?.message
              : "Internal Server Error!";
            variant = "error";
            setError(true);
            setLoading(false);
          }
        });
    });
  };

  useEffect(() => {
    getThumbnailsList();
  }, [hashValue, pageState.page, pageState.pageSize]);

  return (
    <MainCard>
      {/* Loadeer */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <Typography sx={{ fontFamily: "system-ui", fontSize: "1rem", ml: 1 }}>
          Loading...
        </Typography>
      </Backdrop>

      {/* Snackbar */}
      <Snackbar open={error} autoHideDuration={3500} onClose={handleCloseSB}>
        <Alert
          variant="filled"
          onClose={handleCloseSB}
          severity={variant}
          sx={{ width: "100%", zIndex: 999 }}
        >
          {message}
        </Alert>
      </Snackbar>

      {/* Back Btn + Breadcrumbs */}
      <Grid container alignItems="center">
        <Tooltip title="Back to Documents List..." followCursor>
          <Icon
            icon="ic:round-arrow-back"
            height="30"
            width="30"
            color="#34495E"
            onClick={() => {
              navigate("/document-upload");
            }}
          />
        </Tooltip>
        <Breadcrumbs sx={{ px: 1 }}>
          <Button
            sx={{ fontFamily: "system-ui", color: "gray" }}
            onClick={() => {
              navigate("/document-upload");
            }}
          >
            Document Uplaod
          </Button>
          <Typography sx={{ fontFamily: "system-ui", color: "gray" }}>
            Details
          </Typography>
        </Breadcrumbs>
      </Grid>

      {type === "case_data" || type === "salesforce" ? (
        <>
          {/* Case Data Table */}
          <Grid container sx={{ my: 5 }}>
            <Grid item xs={12}>
              {/* Case Data Table */}
              <DataGrid
                autoHeight
                rows={pageState.data ? pageState.data : []}
                columns={columns}
                rowCount={pageState.total ? pageState.total : 5}
                pagination
                paginationMode="server"
                page={pageState.page - 1}
                rowsPerPageOptions={[15, 25, 50, 100]}
                pageSize={pageState.pageSize}
                onPageChange={(newPage) => {
                  setPageState((old) => ({ ...old, page: newPage + 1 }));
                }}
                onPageSizeChange={(newPageSize) =>
                  setPageState((old) => ({
                    ...old,
                    page: 1,
                    pageSize: newPageSize,
                  }))
                }
              />

              {/* Deaiks */}
              <Modal
                open={open}
                onClose={() => {
                  setOpen(false);
                  setEdit(false);
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    width: "40vw",
                    p: 5,
                  }}
                >
                  <Grid container direction="column">
                    {/* Top Bar */}
                    <Grid container justifyContent="space-between">
                      {/* Clsoe Btn */}
                      <Grid item>
                        <Icon
                          icon="line-md:menu-to-close-alt-transition"
                          height="25"
                          width="25"
                          onClick={() => {
                            setOpen(false);
                            setEdit(false);
                          }}
                          color="#34495E"
                        />
                      </Grid>

                      {/* Edit & Save Btn */}
                      <Grid item>
                        {!edit ? (
                          <Tooltip title="Click to Edit..." followCursor>
                            <Icon
                              icon="fluent:select-object-skew-edit-20-regular"
                              height="30"
                              width="30"
                              color="#34495E"
                              onClick={() => {
                                setEdit(true);
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Save Changes">
                            <Icon
                              icon="quill:checkmark-double"
                              height="30"
                              width="30"
                              color="#34495E"
                              onClick={async () => {
                                setEdit(false);
                                await updateSingleData(
                                  content.uuid,
                                  content.hash_value,
                                  content.Description
                                );
                                getThumbnailsList();
                              }}
                            />
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>

                    <Grid>
                      <Typography sx={{ fontSize: "1.15rem" }}>
                        Case No: {caseNo} / Description
                      </Typography>
                    </Grid>

                    {/* Details & Edit Section */}
                    <Grid
                      item
                      sx={{
                        height: "50vh",
                        overflow: "auto",
                        textAlign: "justify",
                        pr: 2.5,
                        "&::-webkit-scrollbar": {
                          width: "0.325em",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#717d7e",
                          borderRadius: "8.5px",
                        },
                        py: 2.5,
                      }}
                    >
                      {!edit ? (
                        <Typography
                          sx={{
                            fontFamily: "system-ui",
                            fontSize: "1.05rem",
                            whiteSpace: "pre-line",
                            textAlign: "justify",
                          }}
                        >
                          {content.Description}
                        </Typography>
                      ) : (
                        <TextField
                          value={content.Description}
                          fullWidth
                          variant="outlined"
                          onChange={(e) => {
                            setContent({
                              ...content,
                              Description: e.target.value,
                            });
                          }}
                          multiline
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </Grid>
          </Grid>
        </>
      ) : (
        <div>
          {/* Thumbnail with Details */}
          <Grid
            container
            sx={{ height: "70vh" }}
            justifyContent="space-between"
          >
            {/* Left Side Image Gallery */}
            {data[0]?.hasOwnProperty("image") && data[0]?.image !== undefined && (
              <Grid item xs={2.25}>
                <ImageList
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: "0.125em",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#633974",
                    },
                    px: 2.5,
                    height: "65vh",
                    overflow: "auto",
                  }}
                  cols={1}
                  gap={15}
                >
                  {data?.map((item, index) => (
                    <ImageListItem key={index}>
                      {/* Individual Image */}
                      <img
                        src={`${item.image}?w=100&h=100&fit=crop&auto=format`}
                        srcSet={`${item.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt="..."
                        style={{
                          objectFit: "contain",
                          cursor: "pointer",
                          border:
                            selImg === index
                              ? "2.25px solid #34495E"
                              : ".5px solid gray",
                          boxShadow:
                            "0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        }}
                        loading="lazy"
                        onClickCapture={() => {
                          getSingleData(item.uuid);
                          setSelImg(index);
                        }}
                      />

                      {/* Image Name */}
                      <Typography
                        sx={{
                          fontFamily: "system-ui",
                          textAlign: "center",
                          p: 0.5,
                        }}
                      >
                        {item.name}
                      </Typography>
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
            )}

            {/* Right Side Details Section */}
            <Grid
              item
              xs={
                data[0]?.hasOwnProperty("image") && data[0]?.image !== undefined
                  ? 9.5
                  : 12
              }
            >
              <Grid container direction="column">
                {/* Edit & Save Btn */}
                <Grid item alignSelf="flex-end">
                  <Tooltip title="Delete This Item..." followCursor>
                    <Icon
                      icon="fluent:delete-20-regular"
                      height="30"
                      width="30"
                      color="#34495E"
                      style={{ marginRight: "10px" }}
                      onClick={async () => {
                        await deleteSingleThumbnailData(
                          content.uuid,
                          content.hash_value
                        );
                        getThumbnailsList();
                      }}
                    />
                  </Tooltip>
                  {!edit ? (
                    <Tooltip title="Click to Edit..." followCursor>
                      <Icon
                        icon="fluent:select-object-skew-edit-20-regular"
                        height="30"
                        width="30"
                        color="#34495E"
                        onClick={() => {
                          setEdit(true);
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Save Changes">
                      <Icon
                        icon="quill:checkmark-double"
                        height="30"
                        width="30"
                        color="#34495E"
                        onClick={async () => {
                          setEdit(false);
                          await updateSingleData(
                            content.uuid,
                            content.hash_value,
                            content.text
                          );
                          getThumbnailsList();
                        }}
                      />
                    </Tooltip>
                  )}
                </Grid>

                {/* Details & Edit Section */}
                <Grid
                  item
                  sx={{
                    height: "65vh",
                    overflowY: "auto",
                    textAlign: "justify",
                    pr: 2.5,
                    "&::-webkit-scrollbar": {
                      width: "0.125em",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#34495E",
                    },
                  }}
                >
                  {content?.url_path && (
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item md={1.75} xs={12}>
                        <Typography
                          sx={{
                            fontFamily: "system-ui",
                            fontSize: "1.05rem",
                          }}
                        >
                          Extracted from :
                        </Typography>
                      </Grid>

                      <Grid item md={10} xs={12}>
                        <Typography
                          sx={{
                            color: "#566573",
                            fontSize: "1rem",
                            cursor: "pointer",
                            fontFamily: "system-ui",
                            fontWeight: "bold",
                            textTransform: "lowercase",
                            textAlign: "justify",
                          }}
                          onClick={() => {
                            window.open(
                              content.url_path,
                              "_blank",
                              "noreferrer"
                            );
                          }}
                        >
                          {content.url_path}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {!edit ? (
                    <Typography
                      sx={{
                        fontFamily: "system-ui",
                        fontSize: "1.05rem",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {data.length > 0 ? data[selImg]?.id : ""}
                      {"\n"}
                      {content.text}
                    </Typography>
                  ) : (
                    <TextField
                      value={content.text}
                      fullWidth
                      variant="standard"
                      onChange={(e) => {
                        setContent({ ...content, text: e.target.value });
                      }}
                      multiline
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </MainCard>
  );
};

export default Details;
