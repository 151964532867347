import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { LightTooltip } from "../../Components/Tooltip";
import B4b_LOGO_FINAL from "../../assets/images/B4b_LOGO_FINAL.png";
import { projectSliceActions } from "../../store/project-slice";

const getRandomColor = (id) => {
  const colors = [
    "F1948A",
    "73C6B6",
    "85C1E9",
    "F7DC6F",
    "AC59A6",
    "2C937F",
    "E59866",
    "0B6C87",
    "808B96",
    "DE3163",
    "CCCCFF",
    "FF7F50",
    "2C3E50",
  ];
  const colorIndex = id % colors.length;
  return colors[colorIndex];
};

const SelectProject = () => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [newProjName, setNewProjName] = useState("");
  const [newProjDesc, setNewProjDesc] = useState("");
  const { storedAllProjDetails: editedProjects } = useSelector(
    ({ project }) => project
  );
  const dispatch = useDispatch();

  const addNewProfile = () => {
    let newProjId = null;
    if (editedProjects.length > 0) {
      newProjId = editedProjects.length + 1;
    } else {
      newProjId = 1;
    }
    const newProjectInfo = {
      id: newProjId,
      name: newProjName,
      description: newProjDesc,
      sidebar_id: [
        { p_id: "knowledge_graph", name: "Knowledge Graph" },
        { p_id: "document-upload", name: "Document Upload" },
        { p_id: "configuration", name: "Configuration" },
        // transcript,
        // createChatbot,
        // connection,
        // salesforces,
        // salesforce_query_connections,
        // jira,
        // dropbox,
      ],
    };
    const newProjs = editedProjects;
    const upEditedProjects = [...newProjs, newProjectInfo];
    dispatch(projectSliceActions.storedAllProjDetailsHandler(upEditedProjects));
    console.log("upEditedProjects", newProjs, upEditedProjects);
    projectDialogClose();
  };

  const projectDialogFormReset = () => {
    setNewProjName("");
    setNewProjDesc("");
  };

  const projectDialogClose = () => {
    setIsDialogVisible(false);
    projectDialogFormReset();
  };

  const NewProjectDialog = (
    <Dialog open={isDialogVisible} onClose={projectDialogClose}>
      {/* Title */}
      <DialogTitle sx={{ textAlign: "center", fontSize: "1rem", p: 3 }}>
        Add New Project Details
      </DialogTitle>

      <DialogContent
        sx={{
          width: { xs: "100%", md: "50vh", lg: "50vh" },
        }}
      >
        <Box sx={{ minWidth: 300, p: 1.5 }}>
          <Grid container>
            {/* Project Name */}
            <Grid item xs={12} sx={{ mb: 1.25 }}>
              <TextField
                fullWidth
                type="text"
                autoFocus
                variant="outlined"
                id="newProjectName"
                label="Project Name"
                placeholder="Enter your Project Name"
                value={newProjName}
                onChange={(e) => setNewProjName(e.target.value)}
              />
            </Grid>

            {/* Project Description */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                autoFocus
                rows="4"
                type="text"
                variant="outlined"
                label="Project Description"
                placeholder="Enter your Project Description"
                value={newProjDesc}
                onChange={(e) => setNewProjDesc(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      {/* Dialog Btns */}
      <DialogActions>
        {/* Close */}
        <Button onClick={projectDialogClose} color="error">
          Close
        </Button>

        {/* Save */}
        <LoadingButton
          autoFocus
          type="submit"
          // loading={loading}
          onClick={addNewProfile}
          disabled={newProjName === "" && newProjDesc === ""}
        >
          Save Project
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
  return (
    <>
      <Card>
        <Grid container sx={{ my: 12 }} alignItems="center">
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <img
              src={B4b_LOGO_FINAL}
              height="100"
              width="100"
              alt="B4b Technologies Pvt Ltd"
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.35rem",
                my: 0.5,
                color: "#34495E",
              }}
            >
              B4B Knowledge Bot Portal
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontWeight: "light",
                fontSize: "1.15rem",
                mt: 0.5,
                color: "#34495E",
              }}
            >
              Our Knowledge Bot is here to provide you with quick and accurate
              answers to all your questions. Our intelligent bot is trained to
              assist you.
            </Typography>
          </Grid>

          <Grid item xs={12} align="center">
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Icon icon="solar:home-add-broken" />}
              sx={{
                textAlign: "center",
                borderRadius: 5,
                boxShadow: 10,
                bgcolor: "rgba(26, 82, 118, 0.75)",
                m: 2,
                py: 1,
                px: 2,
                "&:hover": {
                  bgcolor: "rgba(26, 82, 118, 0.95)",
                },
              }}
              onClick={() => {
                setIsDialogVisible(true);
              }}
            >
              New Project
            </Button>
          </Grid>
          {editedProjects.length > 0 && (
            <Grid
              sx={{
                width: editedProjects.length > 1 ? "50%" : "15%",
                overflowX: "auto",
                whiteSpace: "nowrap",
                mx: "auto",
                display: "flex",
                bgcolor: "#dee7e9",
              }}
            >
              <ProfileCard editedProjects={editedProjects} />
            </Grid>
          )}
        </Grid>
      </Card>

      {NewProjectDialog}
    </>
  );
};

const ProfileCard = ({ editedProjects }) => {
  const [editId, setEditId] = useState(null);
  const [projectColor, setProjectColor] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handleEditSave = (pId) => {
  //   const updatedProjets = editedProjects.map((proj) => {
  //     if (proj.id === pId) {
  //       return {
  //         ...proj,
  //         name: editName,
  //       };
  //     }
  //     return proj;
  //   });
  //   seteditedProjects(updatedProjets);
  //   setEditId(null);
  // };

  // const handleDelete = (id) => {
  //   const updatedProjets = editedProjects.filter((proj) => proj.id !== id);
  //   seteditedProjects(updatedProjets);
  // };

  // const handleNameChange = (e) => {
  //   setEditName(e.target.value);
  // };

  // const handleNameKeyDown = (e, paraId) => {
  //   if (e.key === "Enter") {
  //     handleEditSave(paraId);
  //   }
  // };

  // const Dropdown = ({ id }) => {
  //   const [anchorEl, setAnchorEl] = useState(null);

  //   const handleClick = (e) => {
  //     setAnchorEl(e.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  //   const handleEdit = () => {
  //     setEditId(id);
  //     const project = editedProjects.find((proj) => proj.id === id);
  //     setEditName(project.name);
  //     handleClose();
  //   };

  //   const handleConfirmDelete = () => {
  //     handleDelete(id);
  //     handleClose();
  //   };

  //   return (
  //     <>
  //       <IconButton onClick={handleClick} aria-label="more options">
  //         <Icon icon="ic:round-more-vert" height="20" />
  //       </IconButton>
  //       <Menu
  //         anchorEl={anchorEl}
  //         open={Boolean(anchorEl)}
  //         onClose={handleClose}
  //         onClick={(e) => e.stopPropagation()}
  //       >
  //         <MenuItem onClick={handleEdit}>Edit</MenuItem>
  //         <MenuItem onClick={handleConfirmDelete}>Delete</MenuItem>
  //       </Menu>
  //     </>
  //   );
  // };

  const handleSelectedProject = (allProjects, selectedProject) => {
    console.log("project selected", { selectedProject });
    setEditId(selectedProject.id);
    dispatch(projectSliceActions.storedAllProjDetailsHandler(allProjects));
    setProjectColor("#d7d1ff");
  };

  const handleShowDashboard = (selectedProject) => {
    const selecetdProjectName = selectedProject.name;
    console.log("handle dashboard clicked", {
      selectedProject,
      selecetdProjectName,
    });
    dispatch(projectSliceActions.storedProjNameHandler(selecetdProjectName));
    dispatch(projectSliceActions.storedProjDetailsHandler([selectedProject]));
    navigate("/knowledge_graph", { replace: true });
  };

  return (
    <>
      {editedProjects.map((para, index) => {
        const initials = para.name
          .split(" ")
          .map((word) => word[0])
          .join("")
          .toUpperCase();

        const imageUrl = `https://dummyimage.com/75x75/${getRandomColor(
          para.id
        )}/fff&text=${initials}`;
        return (
          <Card
            key={index}
            sx={{
              boxShadow: 15,
              borderRadius: 2.5,
              px: 2,
              mx: 2,
              py: 1.5,
              my: 1.5,
              width: 200,
              bgcolor:
                projectColor !== "" && editId === para.id && projectColor,

              flex: "0 0 auto",
            }}
            onClick={() => handleSelectedProject(editedProjects, para)}
          >
            <Grid container direction="column" justifyContent="center">
              <Grid item sx={{ mb: 2 }}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    {/* {editId === para.id ? (
                      <TextField
                        variant="standard"
                        value={editName}
                        // onChange={handleNameChange}
                        // onBlur={() => handleEditSave(para.id)}
                        // onKeyDown={(e) => handleNameKeyDown(e, para.id)}
                        autoFocus
                      />
                    ) : ( */}
                    <LightTooltip followCursor title={para.name}>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          maxWidth: 100,
                          overflowWrap: "break-word",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color:
                            projectColor !== "" &&
                            editId === para.id &&
                            "#65a7ff",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {para.name}
                      </Typography>
                    </LightTooltip>
                    {/* )} */}
                  </Grid>
                  {/* {editId === null && (
                    <Grid item alignSelf="flex-end">
                      <Dropdown id={para.id} />
                    </Grid>
                  )} */}
                </Grid>
              </Grid>
              <Grid item alignSelf="center" sx={{ mb: 1 }}>
                <img
                  src={imageUrl}
                  alt="Name Initials"
                  style={{ borderRadius: "50%" }}
                />
              </Grid>
              <Grid item alignSelf="center">
                <LightTooltip followCursor title={para.description}>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      color:
                        projectColor !== "" && editId === para.id && "#34495e",
                      maxWidth: 100,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {para.description}
                  </Typography>
                </LightTooltip>
              </Grid>
              {editId === para.id && (
                <Grid item sx={{ mt: 0.5, textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleShowDashboard(para)}
                    sx={{
                      textAlign: "center",
                      borderRadius: 5,
                      boxShadow: 10,
                      bgcolor: "#388cc8",
                      px: 2,
                      "&:hover": {
                        bgcolor: "#2d71a2",
                      },
                    }}
                  >
                    Confirm
                  </Button>
                </Grid>
              )}
            </Grid>
          </Card>
        );
      })}
    </>
  );
};

export default SelectProject;
