import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconUserPlus } from "@tabler/icons";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import MainCard from "../../ui-component/cards/MainCard";
import { LightTooltip } from "../../Components/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
let newArr = [];
let newUserArr = [];

const AccessControl = () => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [editUserId, setEditUserId] = useState("");
  const [editUserName, setEditUserName] = useState("");
  const [editUserEmail, setEditUserEmail] = useState("");
  const [userIds, setUserIds] = useState([]);
  const [rows, setRows] = useState([]);
  const [type, setType] = useState("addUser");
  const [flag, setFlag] = useState(false);
  const [flagVal, setFlagVal] = useState(false);
  const [userRole, setUserRole] = useState([]);
  const [userItems, setUserItems] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const theme = useTheme();
  const { sidebarItems, storedProjDetails } = useSelector(
    ({ project }) => project
  );
  useEffect(() => {
    console.log("access control rendered", sidebarItems, storedProjDetails);
    // const userRoleLists = storedProjDetails[0].sidebar_id;
    const userRoleLists = [
      { p_id: "knowledge_graph", name: "Knowledge Graph" },
      { p_id: "document-upload", name: "Document Upload" },
      { p_id: "configuration", name: "Configuration" },
      // transcript,
      // createChatbot,
      // connection,
      // salesforces,
      // salesforce_query_connections,
      // jira,
      // dropbox,
      { p_id: "transcript", name: "Transcript" },
      { p_id: "createChatbot", name: "Create Chatbot" },
      { p_id: "connection", name: "Connection" },
      { p_id: "salesforces", name: "Salesforces" },
      { p_id: "salesforce_query_connections", name: "Salesforce Schema" },
      { p_id: "jira", name: "Atlassian" },
      { p_id: "dropbox", name: "Dropbox" },
      { p_id: "user_access", name: "Access Control" },
    ];
    setUserLists(userRoleLists);
    console.log("user role items rendered", userRoleLists);
  }, []);
  const handleClick = (pId, userRoleList) => {
    console.log("menu item clicked", {
      userRoleList,
      pId,
    });

    const userIdArr = userRoleList.map((val) => val.p_id);
    console.log("user ids===>", { pId, userIdArr, userIds });
    if (userIds.includes(pId)) {
      newArr = userIds.filter((val) => val !== pId);
      setUserIds(newArr);
      newUserArr = userItems.filter((val) => val.p_id !== pId);
      console.log("new user arr after getting same element", {
        newUserArr,
        userItems,
        pId,
      });
      setUserItems(newUserArr);
    }
    if (!userIds.includes(pId)) {
      newArr = userIds.push(pId);
      setUserIds(userIds);
      const upObj = userRoleList.filter((val) => val.p_id === pId);
      console.log("new user arr after the element being not existed", {
        upObj,
        userItems,
        pId,
      });
      setUserItems((val) => [...val, upObj[0]]);
    }

    console.log("user ids finally===>", {
      newArr,
      newUserArr,
      userItems,
      userIds,
    });
    // setuserDetails(_cDetails);
  };
  const handleEditUserRole = (value) => {
    console.log("handle edit user role clicked", value);
    const { row } = value;
    setEditUserId(row.user_id);
    setEditUserName(row.user_name);
    setEditUserEmail(row.user_email);
    setType("editUser");
    handleOpenEdit();
  };
  const handleSubmitUserEdit = () => {
    const bodyData = {
      user_id: editUserId,
      user_name: editUserName,
      user_email: editUserEmail,
      user_role: userRole,
    };
    console.log("saving updated changes", bodyData);
    const upRows = rows;
    const newAllRows = upRows.map((val) => {
      if (val.user_id === editUserId) {
        return {
          ...val,
          user_id: editUserId,
          user_name: editUserName,
          user_email: editUserEmail,
          user_role: userRole,
        };
      }
      return val;
    });
    console.log("newAllRows", newAllRows);
    setRows(newAllRows);
    handleCloseEdit();
    setUserRole([]);
    setFlag(false);
  };
  const columns = [
    {
      field: "user_id",
      headerName: "User Id",
      width: 150,
      editable: false,
      renderCell: ({ row: { user_id: uId } }) => {
        if (uId) {
          return (
            <LightTooltip title={uId} followCursor>
              <Typography component="p">{uId}</Typography>
            </LightTooltip>
          );
        }
        return <Typography component="p">{"N/A"}</Typography>;
      },
      renderHeader: (params) => <strong>{params.colDef.headerName}</strong>,
    },
    {
      field: "user_name",
      headerName: "User name",
      width: 250,
      editable: true,
      renderCell: ({ row: { user_name: userName } }) => {
        if (userName) {
          return (
            <LightTooltip title={userName} followCursor>
              <Typography component="p">{userName}</Typography>
            </LightTooltip>
          );
        }
        return <Typography component="p">{"N/A"}</Typography>;
      },
      renderHeader: (params) => <strong>{params.colDef.headerName}</strong>,
    },
    {
      field: "user_email",
      headerName: "User Email",
      width: 250,
      editable: true,
      renderCell: ({ row: { user_email: userEmail } }) => {
        if (userEmail) {
          return (
            <LightTooltip title={userEmail} followCursor>
              <Typography component="p">{userEmail}</Typography>
            </LightTooltip>
          );
        }
        return <Typography component="p">{"N/A"}</Typography>;
      },
      renderHeader: (params) => <strong>{params.colDef.headerName}</strong>,
    },
    {
      field: "user_role",
      headerName: "User Role",
      width: 300,
      editable: true,
      renderHeader: (params) => <strong>{params.colDef.headerName}</strong>,
    },
    {
      field: "action",
      headerName: "Edit",
      width: 80,
      renderCell: (value) => {
        return (
          <Tooltip title="Edit Field">
            <Icon
              icon="line-md:edit"
              height="25"
              width="25"
              onClick={() => handleEditUserRole(value)}
            />
          </Tooltip>
        );
      },
    },
  ];
  const handleAddUser = () => {
    console.log("add user clicked");
    setType("addUser");
    handleOpen();
  };
  const handleReset = () => {
    setUserName("");
    setUserEmail("");
  };
  const handleSubmitUser = () => {
    const bodyData = {
      user_id: rows.length === 0 ? 1 : rows.length + 1,
      user_name: userName,
      user_email: userEmail,
      user_role: "Unassigned",
    };
    setRows((prev) => [...prev, bodyData]);
    console.log("new user to be added", bodyData, rows);
    handleClose();
    handleReset();
  };
  const handleAddUserRole = () => {
    console.log("add user role to be added");
    setFlag(!flag);
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeMultiLine = (event) => {
    setFlagVal(true);
    const {
      target: { value },
    } = event;
    console.log("value from multiline input", event.target.value);
    setUserRole(typeof value === "string" ? value.split(",") : value);
  };

  const getStyles = (label, uRole, theme) => {
    return {
      fontWeight:
        uRole.indexOf(label) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };
  return (
    <MainCard>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {/* Page Heading */}
          <Typography
            sx={{
              fontSize: "1.35rem",
              fontWeight: "bold",
              color: "#34495E",
              my: 2,
              textTransform: "capitalize",
            }}
          >
            User Access Control
          </Typography>
        </Grid>

        {/*  Logo */}
        <Grid item sx={{ py: 2.5 }}>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#1A5276",
              "&:hover": {
                bgcolor: "#4FB4C4",
              },
            }}
            onClick={handleAddUser}
            startIcon={<IconUserPlus />}
          >
            Add User
          </Button>
        </Grid>
        {rows.length > 0 && (
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              getRowId={(row) => row.user_id}
              pageSizeOptions={[5, 10, 15, 20]}
              disableRowSelectionOnClick
            />
          </Grid>
        )}
      </Grid>

      {type === "editUser" ? (
        <Modal open={openEdit} onClose={handleCloseEdit}>
          <Box sx={style}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                color: "#34495E",
                fontSize: "1.35rem",
                fontWeight: "bold",
                cursor: "pointer",
                mb: 2,
              }}
            >
              Edit User Informations
            </Typography>
            <Stack direction="column" spacing={2} alignItems="center">
              <TextField
                fullWidth
                sx={{ py: 1 }}
                label="User Name"
                placeholder="Enter the User Name"
                autoFocus
                value={editUserId}
                disabled
                // onChange={(e) => setEditUserName(e.target.value)}
              />
              <TextField
                fullWidth
                sx={{ py: 1 }}
                label="User Name"
                placeholder="Enter the User Name"
                autoFocus
                value={editUserName}
                onChange={(e) => setEditUserName(e.target.value)}
              />
              <TextField
                fullWidth
                autoFocus
                label="User Email"
                placeholder="Enter the User Email Address"
                value={editUserEmail}
                onChange={(e) => setEditUserEmail(e.target.value)}
              />
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#1A5276",
                  "&:hover": {
                    bgcolor: "#4FB4C4",
                  },
                }}
                onClick={handleAddUserRole}
              >
                {!flag ? "Add Role" : "Undo"}
              </Button>
              {flag && (
                <>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-chip-label" color="info">
                      User Roles
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      displayEmpty
                      fullWidth
                      multiple
                      color="info"
                      value={userRole}
                      onChange={handleChangeMultiLine}
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                      }
                      renderValue={(selected) => {
                        // console.log("selected from renderValue", selected);
                        if (selected.length === 0) {
                          return <div>User Roles</div>;
                        }
                        if (selected.length > 0) {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value, i) => (
                                <Chip key={i} label={value} color="info" />
                              ))}
                            </Box>
                          );
                        }
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {userLists?.map(({ p_id: pId, name: label }) => (
                        <MenuItem
                          key={pId}
                          value={label}
                          style={getStyles(label, userRole, theme)}
                          onClick={() => {
                            handleClick(pId, userLists);
                          }}
                        >
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              <Grid container>
                <Grid item xs={12} align="end">
                  <LightTooltip
                    title={editUserName && editUserEmail && "edit user"}
                  >
                    <Button
                      disabled={editUserName === "" || editUserEmail === ""}
                      onClick={handleSubmitUserEdit}
                    >
                      Save Changes
                    </Button>
                  </LightTooltip>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Modal>
      ) : (
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                color: "#34495E",
                fontSize: "1.35rem",
                fontWeight: "bold",
                cursor: "pointer",
                mb: 2,
              }}
            >
              Add User Informations
            </Typography>
            <Stack direction="column" spacing={2} alignItems="center">
              <TextField
                fullWidth
                sx={{ py: 1 }}
                label="User Name"
                placeholder="Enter the User Name"
                autoFocus
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <TextField
                fullWidth
                autoFocus
                label="User Email"
                placeholder="Enter the User Email Address"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <Grid container>
                <Grid item xs={12} align="end">
                  <LightTooltip title={userName && userEmail && "create user"}>
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: "#1A5276",
                        "&:hover": {
                          bgcolor: "#4FB4C4",
                        },
                      }}
                      type="submit"
                      disabled={userName === "" || userEmail === ""}
                      onClick={handleSubmitUser}
                    >
                      Add
                    </Button>
                  </LightTooltip>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Modal>
      )}
    </MainCard>
  );
};

export default AccessControl;
